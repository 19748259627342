import {
  AbilityBuilder,
  Ability,
  AbilityClass,
  ExtractSubjectType,
  SubjectRawRule,
  MongoQuery,
} from '@casl/ability';

export type Actions = 'view' | 'use';

/* Contains all modules determined by the front-end application */
export type AppModules = ApiModules | 'conversations' | 'academy';

/* Contains all modules determined by the API */
export type ApiModules =
  | 'advisory_conversation'
  | 'advisory_reports'
  | 'campaigns'
  | 'company_scan'
  | 'contacts'
  | 'contracts'
  | 'claims'
  | 'conversation_history'
  | 'digital_sales_conversation'
  | 'digital_signatures'
  | 'leads'
  | 'offers'
  | 'proflow'
  | 'portfolio'
  | 'toolkit'
  | 'risk_analysis'
  | 'louise_qa';

export type AppAbility = Ability<[Actions, ApiModules]>;
export const appAbility = Ability as AbilityClass<AppAbility>;

type Status = 'HIDDEN' | 'DISABLED' | 'ACTIVE';

export type Modules = {
  [key in ApiModules]?: {
    status: Status;
    member_of: ApiModules | null;
  };
};

export type ModuleGroups = {
  [key in ApiModules]: {
    status: Status;
  };
};

const getModuleName = (module: string) => {
  if (module === 'advice_reports') return 'advisory_reports';
  return module;
};

export default function defineRulesFor(
  modules: Modules,
): SubjectRawRule<
  Actions,
  ExtractSubjectType<ApiModules>,
  MongoQuery<unknown>
>[] {
  const { can, cannot, rules } = new AbilityBuilder(appAbility);

  // Gather the parent groups with their highest level
  // 2 = view and use
  // 1 = view but not use
  // 0 = hidden
  const memberOfMapping = {};

  Object.entries({ ...modules }).forEach(
    ([moduleName, { status, member_of }]) => {
      const module = getModuleName(moduleName);

      switch (status) {
        case 'ACTIVE':
          can(['view', 'use'], module as ApiModules);

          if (member_of) memberOfMapping[member_of] = 2;
          break;
        case 'DISABLED':
          can(['view'], module as ApiModules);
          cannot(['use'], module as ApiModules);

          if (member_of)
            memberOfMapping[member_of] = Math.max(
              memberOfMapping[member_of],
              1,
            );
          break;
        case 'HIDDEN':
          cannot(['view', 'use'], module as ApiModules);

          if (member_of)
            memberOfMapping[member_of] = Math.max(
              memberOfMapping[member_of],
              0,
            );
          break;
        default:
          break;
      }
    },
  );

  Object.entries(memberOfMapping).forEach(([module, value]) => {
    if (value === 2) can(['view', 'use'], module as ApiModules);
    else if (value === 1) {
      can(['view'], module as ApiModules);
      cannot(['use'], module as ApiModules);
    } else cannot(['view', 'use'], module as ApiModules);
  });

  return rules;
}

export function buildAbilityFor(modules?: Modules): AppAbility {
  if (!modules) return new appAbility();
  return new appAbility(defineRulesFor(modules));
}
