import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersSupply: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.188 6.713a2 2 0 011.624 0l18.75 8.333a2 2 0 011.188 1.828v27.083a2 2 0 11-4 0V18.174L25 10.729 8.25 18.174v25.783a2 2 0 11-4 0V16.874a2 2 0 011.188-1.828l18.75-8.333zM17.946 22.32a4.084 4.084 0 012.887-1.196H25a4.084 4.084 0 014.083 4.083v.084h6.334a2 2 0 012 2v16.666a2 2 0 01-2 2H14.583a2 2 0 01-2-2v-12.5a2 2 0 012-2h2.167v-4.25c0-1.083.43-2.121 1.196-2.887zm-1.363 11.137v8.5h8.5v-8.5h-8.5zm8.5-4H20.75v-4.25a.083.083 0 01.083-.083H25a.083.083 0 01.083.083v4.25zm4 12.5h4.334V29.291h-4.334v12.666z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersSupply;
