import React from 'react';

// Components
import BrokerInfo from './BrokerInfo';

// Redux
import { useBreakpointValue, Box } from '@wegroup/design-system';
import { useQuery, UseQueryResult } from 'react-query';
import styled from 'styled-components';
import { searchAddressREST } from '../../../../../../../common/src/legacy/services/apiRouterService';
import LoadingSpinner from '../../../../../../../common/src/components/LoadingSpinner';
import StaticMap from '../../../../../../../common/src/components/static-map/StaticMap';
import { mq } from '../../../../../../../common/src/utils/styledComponentsUtils';
import useAuth from '../../../../../../../app/src/features/authentication/hooks/useAuth';

const useBrokerInfo = (): UseQueryResult<[number, number]> => {
  const { distribution } = useAuth();

  return useQuery(
    ['distribution', 'latlong', distribution?.address],
    async () => {
      if (!distribution) throw new Error();
      const [resp, status] = await searchAddressREST(
        `${distribution.address.street} ${distribution.address.housenr} ${distribution.address.city}`,
        'nl',
        distribution.address.country_code || '',
      );

      if (status > 201) {
        return;
      }

      const address = resp.items[0].address;

      return [address.lat, address.lng];
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!distribution,
    },
  );
};

const BrokerInfoContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { distribution } = useAuth();
  const isLaptop = !!useBreakpointValue({ base: false, laptop: true });

  const { data: latLong, isLoading } = useBrokerInfo();

  if (!latLong || isLoading || !distribution) {
    return (
      <Box mx="32" my="16">
        <LoadingSpinner />
      </Box>
    );
  }

  return (
    <Wrapper data-test-id="ProdFac_BrokerInfoContainer">
      <BrokerInfo distribution={distribution} />
      <MapWrapper data-test-id="ProdFac_BrokerInfoContainer_MapWrapper">
        <StaticMap
          viewPort={{
            latitude: latLong[0],
            longitude: latLong[1],
            zoom: isLaptop ? 16 : 15,
          }}
        />
      </MapWrapper>
    </Wrapper>
  );
};

const MapWrapper = styled.div`
  width: 100%;
  height: 15rem;
  .mapboxgl-map {
    border-radius: 6px;
  }
  margin-bottom: 1.6rem;

  ${mq.tablet} {
    margin-bottom: 0;
    width: 28rem;
    height: 28rem;
  }

  ${mq.laptop} {
    width: 46rem;
    height: 46rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  padding: 1.6rem;

  ${mq.tablet} {
    flex-direction: row;

    & > *:first-child {
      margin-right: 1.6rem;
    }
  }

  ${mq.laptop} {
    align-items: flex-start;
  }
`;

export default BrokerInfoContainer;
