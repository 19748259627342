import React from 'react';

import {
  ButtonProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Text,
  Button,
  ButtonGroup,
  IconButton,
  Spinner,
  Tooltip,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

export interface StatusOption {
  label: string;
  description: string;
  color: string;
  value: string;
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  options: StatusOption[];
  isLoading?: boolean;
  withIconButton?: boolean;
}

const StatusChangeButton: React.FC<Props & Omit<ButtonProps, 'onChange'>> = ({
  value,
  onChange,
  options,
  isLoading,
  withIconButton = true,
  ...props
}) => {
  const { t } = useTranslation();
  const selectedOption =
    options.find(({ value: optionVal }) => optionVal === value) || options[0];

  const getNextStatus = (): StatusOption => {
    const currentIndex = options.indexOf(selectedOption);
    if (options[currentIndex + 1]) return options[currentIndex + 1];
    return options[0];
  };

  return (
    <Menu>
      <ButtonGroup isAttached isDisabled={isLoading}>
        <MenuButton as={Button} variant="white" size="sm" {...props}>
          <Text color={selectedOption.color}>
            &#x25cf; {t(selectedOption.label)}
          </Text>
        </MenuButton>
        {withIconButton && (
          <Tooltip
            label={`${t('PARTIES_DETAIL_STATUS_NEXT-LABEL')}: ${t(
              getNextStatus().label,
            )}`}
          >
            <IconButton
              size="sm"
              variant="white"
              ml="-1px"
              aria-label="next-status"
              onClick={() => onChange(getNextStatus().value)}
              icon={
                isLoading ? (
                  <Spinner size="sm" color="gray.300" />
                ) : (
                  <Icon
                    name="GeneralActionsChevronRightRounded"
                    color="gray.500"
                    boxSize="5"
                  />
                )
              }
              {...props}
            />
          </Tooltip>
        )}
      </ButtonGroup>
      <MenuList zIndex="2">
        {options.map(({ value: optionValue, color, label }) => (
          <MenuItem key={optionValue} onClick={() => onChange(optionValue)}>
            <Text color={color}>&#x25cf; {t(label)}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default StatusChangeButton;
