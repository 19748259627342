import { Text, Skeleton, VStack } from '@wegroup/design-system';
import React, { ReactElement } from 'react';

interface Props {
  label?: string | ReactElement;
  isMediumFontWeight?: boolean;
}

const LabelValueWithSkeleton: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  isMediumFontWeight,
  children,
}) => {
  if (label == null)
    return (
      <VStack align="start" spacing="1" minH="12">
        <Skeleton height="4" w="10" />
        <Skeleton height="4" w="20" />
      </VStack>
    );

  return (
    <VStack align="start" spacing="1" minH="12">
      <Text textStyle="smallBody" color="text.gray">
        {label}
      </Text>
      {children != null ? (
        <Text
          textStyle={isMediumFontWeight ? 'normalBodyMedium' : 'normalBody'}
        >
          {children}
        </Text>
      ) : (
        <Skeleton height="4" w="20" />
      )}
    </VStack>
  );
};

export default LabelValueWithSkeleton;
