import React, { useEffect, ReactElement } from 'react';
import {
  BoxProps,
  Text,
  Table,
  Flex,
  VStack,
  Pagination,
  TableProps,
} from '@wegroup/design-system';
import {
  useTable,
  Column,
  useSortBy,
  usePagination,
  TableState,
  SortingRule,
  Cell,
  useExpanded,
} from 'react-table';
import ReactTableContainer from './ReactTableContainer';
import ReactTableHead from './ReactTableHead';
import ReactTableBody from './ReactTableBody';
import ReactTableFooter from './ReactTableFooter';
import { useBoolean } from '@chakra-ui/hooks';
import TableBodySkeletonLoading from './TableBodySkeletonLoading';
import TableFiller from './TableFiller';

export interface CustomColumnProps {
  hoverElement?: (cell: Cell) => ReactElement;
  isSticky?: boolean;
  /** It's important that the data is sorted by the header that has its row spanned */
  isRowSpanned?: boolean;
  /** If given the component will only rerender if the values of the dependencies change */
  dependencies?: string[];
}

export interface RowOptions {
  url?: string;
  hoverElement?: ReactElement;
}

export interface TableData {
  // Terribly sorry for marking this as any, but Michiel and I have already
  // wasted too much time on it. In a perfect world, this should be converted
  // to a generic, but we weren't able to make this work.
  // Based on the react-table docs (https://react-table.tanstack.com/docs/api/useTable)
  // you can also see that they define the `data` prop as any, so there's that.
  // eslint-disable-next-line
  [col: string]: any;
}

export interface ReactTableProps {
  data: TableData[];
  // Using any to prevent any unnecessary errors
  // as "TableData" is basically the same as "any" but gives unnecessary errors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Column<any>[];
  minWidth?: string | number;
  isHeaderSticky?: boolean;
  pagination?: boolean;
  initialState?: Partial<TableState<TableData>>;
  isLoading?: boolean;
  onSort?: (sortArray: SortingRule<TableData>[]) => void;
  isFooterVisible?: boolean;
  emptyText?: string | ReactElement;
  amountOfSkeletonRows?: number;
  onRowClick?: (index: number) => void;
  variant?: TableProps['variant'] & 'borderless';
  spannedCellRenderer?: (value: unknown) => ReactElement;
  /** Provide <colgroup> to set a fixed width to the columns */
  colGroup?: ReactElement;
}

const ReactTable: React.FC<
  React.PropsWithChildren<ReactTableProps & BoxProps>
> = ({
  data,
  columns,
  minWidth = 'unset',
  pagination = false,
  initialState,
  isLoading = false,
  onSort,
  isFooterVisible,
  emptyText,
  isHeaderSticky,
  amountOfSkeletonRows,
  onRowClick,
  variant,
  colGroup,
  spannedCellRenderer = (value) => <>{value}</>,
  ...otherProps
}) => {
  /**
   * Currently only 1 sticky column is supported
   */
  const [isScrollable, setIsScrollable] = useBoolean();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    footerGroups,
    // Pagination
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    onSort && onSort(sortBy);
  }, [sortBy]);

  return (
    <VStack alignItems="stretch" spacing="6">
      <ReactTableContainer
        isScrollable={isScrollable}
        setIsScrollable={setIsScrollable}
        isEmpty={data?.length === 0 && !emptyText}
        {...otherProps}
        // Had to do it like this because of the container
        {...(variant === 'borderless' && {
          borderRadius: 'none',
          borderLeft: 'none',
          borderRight: 'none',
        })}
      >
        <Table
          variant={variant}
          minW={minWidth}
          boxShadow="unset"
          border="unset"
          {...getTableProps()}
        >
          {colGroup}
          <ReactTableHead
            headerGroups={headerGroups}
            isHeaderSticky={isHeaderSticky}
            isScrollable={isScrollable && !isLoading}
          />
          {isLoading ? (
            <TableBodySkeletonLoading
              amountOfColumns={headerGroups[0].headers.length}
              amountOfRows={amountOfSkeletonRows}
              {...getTableBodyProps()}
            />
          ) : (
            <ReactTableBody
              rows={pagination ? page : rows}
              prepareRow={prepareRow}
              isScrollable={isScrollable && !isLoading}
              onRowClick={onRowClick}
              spannedCellRenderer={spannedCellRenderer}
              {...getTableBodyProps()}
            />
          )}
          {isFooterVisible && <ReactTableFooter footerGroups={footerGroups} />}
        </Table>
        {!isLoading && emptyText && data?.length === 0 && (
          <TableFiller>
            <Text>{emptyText}</Text>
          </TableFiller>
        )}
      </ReactTableContainer>
      {pagination && pageOptions.length > 1 && (
        <Flex justifyContent="flex-end">
          <Pagination
            onPrev={previousPage}
            onNext={nextPage}
            onPageClick={(currentPage) => gotoPage(currentPage - 1)}
            currentPage={pageIndex + 1}
            pages={pageOptions.length}
          />
        </Flex>
      )}
    </VStack>
  );
};

export default ReactTable;
