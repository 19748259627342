const aska = {
  primary: {
    main: '#860038',
    50: '#FDF2F7',
    100: '#F9D6E1',
    150: '#F6B7D0',
    200: '#F493BF',
    300: '#E5608B',
    400: '#8F214F',
    500: '#860038',
    600: '#7A0032',
    700: '#6B002D',
    750: '#600020',
    800: '#490A26',
    850: '#490A26',
    900: '#34071B',
  },
  secondary: {
    main: '#8F214F',
    50: '#FFEEF4',
    100: '#FFCCDF',
    150: '#FFADC9',
    200: '#FF8EB3',
    300: '#E96085',
    400: '#D4316A',
    500: '#8F214F',
    600: '#7A1C45',
    700: '#66173A',
    750: '#5C0026',
    800: '#5C0026',
    850: '#5C0026',
    900: '#3E0018',
  },
};

export default aska;
