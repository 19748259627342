import React, { PropsWithChildren, useEffect } from 'react';
import i18n, {
  setCountry,
  setI18nLangWithCountryCode,
} from '../../../../../i18n';
import useQueryParams from '../../../../common/src/hooks/useQueryParams';
import {
  isSocialLoginUrl,
  isLouiseQAUrl,
} from '../../features/authentication/constants/SocialAuth';
import useAuth from '../../features/authentication/hooks/useAuth';

const LanguageProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, distribution, campaign } = useAuth();
  const params = useQueryParams();

  const setLanguageForCampaign = () => {
    let tobeSetLanguage = campaign?.language as string;

    const langParam = params.get('lang') || params.get('language');
    if (langParam) {
      tobeSetLanguage = langParam;
    }

    const NLcountries = ['NL', 'CW', 'BQ', 'CW', 'SX', 'CU'];
    if (tobeSetLanguage) {
      if (
        NLcountries.includes(
          distribution?.address.country_code || user?.country_code || '',
        ) &&
        tobeSetLanguage.toLowerCase() === 'nl'
      ) {
        setCountry('NL');
        i18n.changeLanguage('nl-NL');
      } else i18n.changeLanguage(tobeSetLanguage);
    }
  };

  const setLanguageForApp = () => {
    if (user?.language)
      setI18nLangWithCountryCode(user.language?.toLowerCase());
  };

  useEffect(() => {
    if (isSocialLoginUrl && !isLouiseQAUrl && campaign)
      setLanguageForCampaign();
    else setLanguageForApp();
  }, [distribution, campaign, user]);

  return <>{children}</>;
};

export default LanguageProvider;
