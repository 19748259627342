// ─── Get Recommendations By Recommendation Id ──────────────────────────────────────────────────

import { useQuery, UseQueryResult } from 'react-query';
import { useLocale } from '../../../../../../../i18n';
import { request } from '../../../../../../common/src/legacy/services/httpSessionService';
import { Recommendations } from '../../../overview/types/Recommendations';

export const getConversationRecommendationsQKey = (
  conversationId: string,
  recommendationId?: string,
): [string, string, string?] => [
  'conversations',
  conversationId,
  recommendationId,
];

export const getRecommendationsByRecommendationId = async (
  userLang: string,
  userCountry: string,
  recommendationId?: string,
): Promise<[Recommendations, number]> => {
  const path = `/v1/api/insurance-recommendations/${recommendationId}?lang=${userLang}&country=${userCountry}`;
  const [resp, status] = await request({ path });

  return [resp, status];
};

const useConversationRecommendationsQFn = async (
  userLang: string,
  userCountry: string,
  recommendationId?: string,
) => {
  if (!recommendationId) throw new Error('Recommendation id is undefined');

  const [resp, status] = await getRecommendationsByRecommendationId(
    userLang,
    userCountry,
    recommendationId,
  );

  if (status >= 400)
    throw new Error(
      'Something went wrong server-side or recommendation was not found',
    );

  return resp;
};

export const useConversationRecommendations = (
  conversationId: string,
  recommendationId?: string,
): UseQueryResult<Recommendations, unknown> => {
  const recommendationsQKey = getConversationRecommendationsQKey(
    conversationId,
    recommendationId,
  );
  const { userLang, userCountry } = useLocale();

  return useQuery<Recommendations>(
    recommendationsQKey,
    () =>
      useConversationRecommendationsQFn(
        userLang,
        userCountry,
        recommendationId,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: false,
      enabled: !!recommendationId,
    },
  );
};
