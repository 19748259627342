import React from 'react';
import { Redirect, useLocation } from 'react-router';

import { FlowType } from '../../../../../campaigns/src/types/campaignTypes';
import {
  companyCheckupFlowIds,
  companyScanFlowIds,
  customerCheckupFlowIds,
  flowIdAskaTravelOffer,
  flowIdCfVerzekeringen,
  flowIdCyclingVlaanderenOfferFlowId,
  flowIdPiaBoatOfferFlow,
  flowIdSalesConversation,
  flowIdVillasureOfferFlow,
  riskAnalysisFlowIds,
} from '../../../../../flows/src/constants/flowIds';
import { isConversationUrl } from '../../authentication/constants/SocialAuth';
import useAuth from '../../authentication/hooks/useAuth';

const CampaignRedirector: React.FC = () => {
  const { campaign } = useAuth();
  const location = useLocation();
  const isBoatFlow = campaign?.template?.flow_id === flowIdPiaBoatOfferFlow;
  const isVillasureFlow =
    campaign?.template?.flow_id === flowIdVillasureOfferFlow;
  const { search: hashRouteSearch } = location;

  if (isConversationUrl)
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/company-scan',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );

  if (!campaign) return null;

  if (campaign?.template?.flow_id === flowIdAskaTravelOffer) {
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/aska-travel/offers',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  }
  if (campaign?.template?.flow_id === flowIdCfVerzekeringen) {
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/cf-verzekeringen',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  }
  if (companyCheckupFlowIds.includes(campaign?.template?.flow_id || '')) {
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/company-checkup',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  }
  if (customerCheckupFlowIds.includes(campaign?.template?.flow_id || '')) {
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/customer-checkup',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  }

  if (isBoatFlow)
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/boat/offers',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  if (isVillasureFlow)
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/villasure/offers',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );

  if (campaign.insurances[0]?.insurance_company === 'bikmo') {
    if (campaign?.template?.flow_id === flowIdCyclingVlaanderenOfferFlowId) {
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/sales-conversation/cycling-vlaanderen/offers',
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
    }
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/bikmo/offers',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  }

  if (riskAnalysisFlowIds.includes(campaign?.template?.flow_id || ''))
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/risk-analysis',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  if (companyScanFlowIds.includes(campaign?.template?.flow_id || ''))
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/company-scan',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );

  switch (campaign.template?.flow_type) {
    case FlowType.COMPANY_SCAN:
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/company-scan',
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
    case FlowType.ADVISORY_CONVERSATION: {
      if (campaign?.template?.flow_id === flowIdSalesConversation) {
        return (
          <Redirect
            from="/"
            to={{
              pathname: '/product/session',
              search: hashRouteSearch || window.location.search,
            }}
            exact
          />
        );
      }
      return (
        <Redirect
          from="/"
          to={{
            pathname: `/sales-conversation/default/${campaign?.template?.flow_id}`,
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
    }
    default:
      return (
        <Redirect
          from="/"
          to={{
            pathname: `/sales-conversation/default/${campaign?.template?.flow_id}`,
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
  }
};

export default CampaignRedirector;
