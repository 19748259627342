import React, { useEffect } from 'react';
import { ChakraProvider, getTheme } from '@wegroup/design-system';
import { ThemeName } from '../../../../design-system/src/theme/themes';
import { camelCase } from 'lodash';
import { MantineProvider } from '@mantine/core';
import useAuth from '../../features/authentication/hooks/useAuth';
import { getThemeObject } from '../../../../common/src/legacy/services/themingService';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { ThemeProvider as ExternalThemeProvider } from 'wg-fe-ui';
import useAppTheme from '../../hooks/useAppTheme';
import Fonts from '../../components/Fonts';
import { isSocialLoginUrl } from '../../features/authentication/constants/SocialAuth';
import MantineSelectStyling from '../../components/MantineSelectStyling';

export const ThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { distribution, campaign } = useAuth();

  const { appTheme, setAppTheme } = useAppTheme();

  useEffect(() => {
    if (isSocialLoginUrl && campaign?.theme?.name) {
      setAppTheme(campaign.theme.name);
    } else if (distribution?.theme?.name) {
      setAppTheme(distribution?.theme?.name);
    }
  }, [distribution, campaign]);

  const chakraThemeObject = getTheme(camelCase(appTheme) as ThemeName);

  const themeObject = {
    ...getThemeObject(appTheme),
    ...chakraThemeObject.colors,
  };

  return (
    <MantineProvider theme={{ fontFamily: 'Circular' }}>
      <ChakraProvider theme={chakraThemeObject}>
        <Fonts />
        <MantineSelectStyling />
        <ExternalThemeProvider theme={themeObject} themeName={appTheme}>
          <StyledComponentsThemeProvider theme={themeObject}>
            {children}
          </StyledComponentsThemeProvider>
        </ExternalThemeProvider>
      </ChakraProvider>
    </MantineProvider>
  );
};
