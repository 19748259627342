const Drawer = {
  baseStyle: {
    body: {
      p: 6,
    },
  },
  sizes: {
    '2xl': {
      dialog: {
        maxWidth: '6xl',
      },
    },
  },
};

export default Drawer;
