import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { v4 } from 'uuid';
import useAuth from '../features/authentication/hooks/useAuth';

const usePosthogInit = () => {
  const {
    distribution,
    user,
    ssoData: { info },
    campaign,
  } = useAuth();
  const { location } = useHistory();
  const posthog = usePostHog();

  useEffect(() => {
    /** PostHog does not record pageviews correctly on SPA - That's why we use this useEffect */
    if (posthog.has_opted_in_capturing()) {
      posthog.capture('$pageview');
    }
  }, [location]);

  const filteredBids = [
    // E2E staging bid
    'a4df0a39-dec1-4b97-a8b6-8624461fabb5',
    '5eb3fb28-7432-4c2e-ac66-0fc5be7e8fec',
    // E2E prod bid
    'dd3ce910-04a4-4c68-a569-8cbb8677a6f9',
  ];
  const filteredOrigin = ['random'];

  const optOutByUser =
    filteredBids.includes(info?.bid || '') ||
    filteredOrigin.includes(info?.origin || '') ||
    filteredBids.includes(campaign?.created_by || '');

  const optIn = () => {
    if (!optOutByUser) {
      posthog?.opt_in_capturing();
      posthog?._start_queue_if_opted_in();
      posthog?.setPersonProperties({
        email: info?.email || distribution.email,
        username: info?.username || distribution.name,
      });
      sessionStorage.setItem('posthog_session_id', posthog?.get_session_id());
      sessionStorage.setItem(
        'posthog_session_url',
        posthog?.get_session_replay_url(),
      );
    } else {
      posthog?.opt_out_capturing();
      /** To prevent non working recordings, remove recording url */
      sessionStorage.removeItem('posthog_session_url');
    }
  };

  /**
   * Once app loading is done we identify the user in posthog
   */
  useEffect(() => {
    if (info) {
      posthog?.identify(info.uid, {
        email: info.email,
        username: info.username,
        user_type: user?.type,
        user_plan: user?.plan,
        country: distribution.address?.country_code?.toUpperCase() || 'BE',
        admin_distro_id:
          distribution?.admin_distribution || distribution?.id || '',
      });
      posthog?.group('company', info.did);
      optIn();
    } else if (distribution) {
      // identifying under a unique id
      posthog?.identify(v4(), {
        username: distribution.name,
        email: distribution.email,
      });
      posthog?.group('company', distribution.id);
      optIn();
    }
  }, [
    posthog,
    user,
    info?.email,
    info?.uid,
    info?.username,
    distribution?.id,
    distribution?.name,
    distribution?.email,
  ]);
};

export default usePosthogInit;
