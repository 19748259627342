import { matchPath, useLocation } from 'react-router';

interface Params {
  // sessionId?: string;
  page?: string;
  riskObject?: string;
}

const useOffersPageParams = (): Params => {
  const location = useLocation();
  const params =
    matchPath(location.pathname, {
      path: `/sales-conversation/offers/:page/:riskObject?`,
      exact: true,
    })?.params || {};

  return params;
};

export default useOffersPageParams;
