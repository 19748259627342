const saman = {
  primary: {
    main: '#C8B700',
    50: '#FBF8E1',
    100: '#EAE39A',
    150: '#E0D868',
    200: '#D6CD36',
    300: '#CCC304',
    400: '#CCC304',
    500: '#C8B700',
    600: '#A99B00',
    700: '#988600',
    750: '#807000',
    800: '#A99B00',
    850: '#504C00',
    900: '#383800',
  },
  secondary: {
    main: '#4A4744',
    50: '#E3E2E1',
    100: '#C7C5C3',
    150: '#B0AEAC',
    200: '#9B9997',
    300: '#878481',
    400: '#726F6C',
    500: '#4A4744',
    600: '#3F3D3B',
    700: '#2E2D2A',
    750: '#2E2D2A',
    800: '#343331',
    850: '#282828',
    900: '#2E2D2A',
  },
};

export default saman;
