import React from 'react';
import { useBoolean, SimpleGrid } from '@wegroup/design-system';
import AnnexInputAddButton from './AnnexInputAddButton';
import AnnexInputCard from './AnnexInputCard';

export interface Annex {
  type: string;
  height?: number;
  surface: number;
  volume?: number;
}

interface Props {
  value: Annex[];
  onChange: (annexes: Annex[]) => void;
  columns?: number;
}

const AnnexesInput: React.FC<Props> = ({ value, onChange, columns = 3 }) => {
  const [isAdding, setIsAdding] = useBoolean();

  const handleEdit = (annex: Annex, index: number) => {
    value[index] = annex;
    onChange(value);
  };
  const handleAdd = (annex: Annex) => {
    onChange([...value, annex]);
    setIsAdding.off();
  };

  const handleRemove = (index: number) => {
    const annexes = [...value];
    annexes.splice(index, 1);
    onChange(annexes);
  };

  return (
    <SimpleGrid gap="4" columns={columns}>
      {value?.map((annex, i) => (
        <AnnexInputCard
          key={`${annex.type}-${i}`}
          annex={annex}
          onSubmit={(values) => handleEdit(values, i)}
          onRemove={() => handleRemove(i)}
        />
      ))}
      {isAdding && (
        <AnnexInputCard onSubmit={handleAdd} onRemove={setIsAdding.off} />
      )}
      {!isAdding && <AnnexInputAddButton onClick={setIsAdding.on} />}
    </SimpleGrid>
  );
};

export default AnnexesInput;
