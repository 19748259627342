import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersBox: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.02 5.49a2 2 0 011.96 0l8.306 4.672.06.033 8.301 4.67a2 2 0 011.02 1.743v18.75a2 2 0 01-1.02 1.743l-16.666 9.375a2 2 0 01-1.962 0L7.354 37.101a2 2 0 01-1.02-1.743v-18.75a2 2 0 011.02-1.743L24.02 5.49zm5.234 6.43L25 9.529l-12.587 7.08 4.254 2.393 12.587-7.08zm-8.508 9.376l12.587-7.08 4.254 2.392L25 23.688l-4.254-2.392zm-5.091 1.725l.06.034L23 27.152v14.16l-12.667-7.125v-14.16l5.322 2.993zM27 41.313l12.667-7.125v-14.16L27 27.153v14.16z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersBox;
