import React, { ReactElement } from 'react';
import { CustomColumnProps, RowOptions, TableData } from '.';
import { Tr } from '@wegroup/design-system';
import { ColumnInstance, Row } from 'react-table';
import ReactTableBodyCell from './ReactTableBodyCell';
import ExpandableRowCell from './ExpandableRowCell';
import ReactTableSpannedRow from './ReactTableSpannedRow';

interface Props {
  previousRow?: Row;
  row: Row;
  handleVisitURL: (url: string) => void;
  spannedCellRenderer: (value: unknown) => ReactElement;
  isScrollable: boolean;
  isLastSubRow: boolean;
  rowSpanMap: Record<string, number>;
  onClick?: () => void;
}

const ReactTableBodyRow: React.FC<Props> = ({
  previousRow,
  row,
  handleVisitURL,
  spannedCellRenderer,
  isScrollable,
  isLastSubRow,
  onClick,
  rowSpanMap,
}) => {
  const { url } = ((row.original as TableData).options as RowOptions) || {};
  const spannedRow = (row.original as TableData).spannedRow;

  const isClickable = url || onClick;
  const handleRowClick = () => {
    if (url) {
      handleVisitURL(url);
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <>
      <Tr
        {...row.getRowProps()}
        cursor={isClickable ? 'pointer' : 'inherit'}
        onClick={handleRowClick}
        _hover={
          isClickable
            ? { 'td, #REACT_TABLE_HOVER_ELEMENT': { bg: 'gray.ultraLight' } }
            : {}
        }
      >
        {row.cells.map((cell, j) => {
          const column = cell.column as ColumnInstance & CustomColumnProps;
          const isStickyColumn = !!column.isSticky && isScrollable;
          const dependencies = column.dependencies?.map(
            (val) => cell.row.original[val],
          );
          const isFirstCell = j === 0;
          const isRowSpannedColumn = !!column.isRowSpanned;

          const rowSpanKeys = Object.keys(rowSpanMap);

          if (isRowSpannedColumn && previousRow?.cells[j].value === cell.value)
            return null;

          return (
            <ReactTableBodyCell
              isSticky={isStickyColumn}
              dependencies={dependencies || undefined}
              {...cell.getCellProps()}
              key={`TableBodyCell_${j}`}
              bg={
                !!row.depth || (row.canExpand && row.isExpanded)
                  ? 'gray.ultraLight !important'
                  : undefined
              }
              borderBottom={
                !!row.depth && !isLastSubRow && isFirstCell
                  ? 'none !important'
                  : undefined
              }
              {...(isRowSpannedColumn && {
                borderRight: '1px',
                borderColor: 'gray.50',
                rowSpan: rowSpanMap[cell.value],
                // This is needed to avoid the border bottom being rendered on the last row spanned column
                // We check this by checking if this is the last value in the rowSpanMap
                borderBottom:
                  rowSpanKeys[rowSpanKeys.length - 1] === cell.value
                    ? 'none !important'
                    : undefined,
              })}
            >
              {isFirstCell && (row.depth || row.canExpand) ? (
                <ExpandableRowCell row={row} isLast={isLastSubRow}>
                  {cell.render('Cell')}
                </ExpandableRowCell>
              ) : (
                cell.render('Cell')
              )}
            </ReactTableBodyCell>
          );
        })}
      </Tr>
      {spannedRow && (
        <Tr
          {...row.getRowProps()}
          key={`${row.getRowProps().key}-spannedrow`}
          cursor={isClickable ? 'pointer' : 'inherit'}
          onClick={handleRowClick}
          _hover={
            isClickable
              ? { 'td, #REACT_TABLE_HOVER_ELEMENT': { bg: 'gray.ultraLight' } }
              : {}
          }
        >
          <ReactTableSpannedRow spanCount={row.cells.length}>
            {spannedCellRenderer(spannedRow)}
          </ReactTableSpannedRow>
        </Tr>
      )}
    </>
  );
};

export default ReactTableBodyRow;
