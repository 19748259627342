import React, { PropsWithChildren } from 'react';
import { PostHogProvider as PostHogProviderSDKComponent } from 'posthog-js/react';
import useAuth from '../../features/authentication/hooks/useAuth';
import { isSocialLoginUrl } from '../../features/authentication/constants/SocialAuth';
import { captureException } from '@sentry/react';

const PostHogProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    ssoData: { info: userInfo },
  } = useAuth();

  if (!userInfo && !isSocialLoginUrl) return <>{children}</>;

  const hostOptions =
    process.env.REACT_APP_ENVIRONMENT !== 'DEVELOPMENT'
      ? {
          ui_host: 'https://eu.posthog.com',
          api_host: window.location.origin + '/ingest',
        }
      : { api_host: 'https://eu.posthog.com' };

  return (
    <PostHogProviderSDKComponent
      apiKey={process.env.REACT_APP_POSTHOG_TOKEN!}
      options={{
        ...hostOptions,
        opt_out_capturing_by_default: true,
        autocapture: false,
        persistence: 'localStorage+cookie',
        capture_pageleave: false,
        session_recording: {
          maskInputOptions: {
            password: true,
          },
        },
        on_request_error: (error) => {
          captureException('Posthog error', { extra: { error } });
        },
      }}
    >
      {children}
    </PostHogProviderSDKComponent>
  );
};

export default PostHogProvider;
