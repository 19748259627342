import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import {
  Stack,
  Grid,
  Box,
  Image,
  Center,
  Spinner,
  Text,
  Button,
  Flex,
  useTheme,
  Spacer,
  VStack,
} from '@wegroup/design-system';
import cogoToast from 'cogo-toast';

export interface UploadZoneProps {
  onFilesAccepted: ((files: File[]) => void) | ((file: File) => void);
  accept?: string | string[];
  multiple?: boolean;
  description?: string;
  buttonText?: string;
  isDisabled?: boolean;
  isLoading?: boolean;

  /**
   * Shows a preview box in the component of the provided img url
   * If you want to preview an image `File`, use the `previewFile` prop instead
   */
  previewImgSrc?: string;
  previewBg?: string;
  isSmall?: boolean;
}

const UploadZone: React.FC<React.PropsWithChildren<UploadZoneProps>> = ({
  onFilesAccepted,
  accept,
  multiple = false,
  description,
  buttonText,
  isDisabled: isDisabledProp,
  isLoading,
  isSmall,
  previewImgSrc,
  previewBg = 'white',
}) => {
  const { t } = useTranslation();
  const { colors, space } = useTheme();

  const isMultiple = (
    files: ((files: File[]) => void) | ((file: File) => void),
  ): files is (files: File[]) => void => {
    return multiple;
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) cogoToast.warn(t('UPLOAD-MAX-FILE-SIZE'));
      if (isMultiple(onFilesAccepted)) {
        onFilesAccepted(acceptedFiles);
      } else {
        onFilesAccepted(acceptedFiles[0]);
      }
    },
    [onFilesAccepted],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
    accept,
    maxSize: 5000000,
  });

  const isDisabled = isDisabledProp || isLoading;

  return (
    <VStack align="stretch">
      <Grid
        templateColumns="1fr"
        templateRows="1fr"
        alignItems="center"
        rowGap="3"
      >
        {isLoading && (
          <Spinner
            gridColumn="1"
            gridRow="1"
            zIndex="1"
            color="primary.500"
            mx="auto"
            h="10"
            w="10"
          />
        )}
        <Flex
          gridColumn="1"
          gridRow="1"
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          p="4"
          {...getRootProps()}
          border="1px dashed"
          borderColor="gray.100"
          flexDirection="row"
          borderRadius="md"
          filter={isDisabled ? 'grayscale(1)' : ''}
          opacity={isLoading ? '0.6' : '1'}
          justifyContent="stretch"
          alignItems={isSmall ? 'stretch' : 'center'}
        >
          {previewImgSrc ? (
            <Box>
              <Center
                p="2"
                w="24"
                h="24"
                boxShadow="level2"
                bg={previewBg}
                mr="5"
              >
                <Image
                  src={previewImgSrc}
                  w="100%"
                  h="100%"
                  objectFit="contain"
                />
              </Center>
            </Box>
          ) : (
            <Box />
          )}
          <input {...getInputProps({ disabled: isDisabled })} />
          <Stack
            direction={isSmall ? 'row' : 'column'}
            spacing="3"
            w="100%"
            align="center"
          >
            <Upload color={colors.gray[300]} size={space[6]} />
            <Box textAlign="center">
              <Text textStyle="smallBody" color="gray.500">
                {description || t('Drag and drop files here')}
              </Text>
              <Text textStyle="extraSmallBody" color="gray.500">
                {'(< 5 MB)'}
              </Text>
            </Box>
            {isSmall && <Spacer />}
            <Button variant="secondary" size="sm" isDisabled={isDisabled}>
              {buttonText || t('Browse')}
            </Button>
          </Stack>
        </Flex>
      </Grid>
    </VStack>
  );
};

export default UploadZone;
