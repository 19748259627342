import React, { useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { WgOAuth } from './features/authentication/classes/WgAuthState';
import {
  isSocialLoginUrl,
  isStorybookUrl,
} from './features/authentication/constants/SocialAuth';
import MainRouter from './features/routing/MainRouter';
import MainProvider from './providers/global-providers/MainProvider';
import { queryClient } from './providers/queryClient';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { v4 } from 'uuid';

const App: React.FC = () => {
  const [isFetchingAuthData, setIsFetchingAuthData] = useState<boolean>(true);

  /**
   * Checks if data can be send to countries outside EU
   */
  const checkForeignDataExchange = async () => {
    // if (isForeignDataTrackingDisabled) {
    //   Sentry.close();
    // }
  };

  /**
   * Verify the presence of a device ID in local storage,
   * Essential for distinguishing multiple users sharing the same account.
   */
  const checkDeviceId = () => {
    const deviceId = localStorage.getItem('wg-device-id');
    if (!deviceId) {
      localStorage.setItem('wg-device-id', v4());
    }
  };

  // Top level await is not supported so have to manually trigger
  // the OAuth object initialization.
  const initAuth = async () => {
    if (!isSocialLoginUrl && !isStorybookUrl) {
      await WgOAuth!.init();
      await checkForeignDataExchange();
      checkDeviceId();
    }
    setIsFetchingAuthData(false);
  };

  useEffect(() => {
    if (!WgOAuth?.authorizationServer) initAuth();
  }, []);

  if (isFetchingAuthData) return null;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.INVISIBLE_CAPTCHA_KEY_V3!}
    >
      <QueryClientProvider client={queryClient}>
        <MainProvider>
          <MainRouter />
        </MainProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
