import {
  Stack,
  FormHelperText,
  Icon,
  Link,
  VStack,
  FormErrorMessage,
  Text,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  label: string;
  error?: string;
  onClick: () => void;
  isManualAllowed?: boolean;
}

const AddressSearchFooter: React.FC<Props> = ({
  label,
  error,
  onClick,
  isManualAllowed,
}) => {
  const { t } = useTranslation();

  return (
    <VStack align="stretch" sx={{ '@media print': { display: 'none' } }}>
      <FormErrorMessage>
        {error || t('DESIGN-SYSTEM_ADDRESS-INPUT_DEFAULT-ERROR')}
      </FormErrorMessage>
      <Stack
        direction={{ base: 'column', tabletS: 'row' }}
        mt="2"
        spacing="6"
        justify="space-between"
        align={{ base: 'flex-start', tabletS: 'center' }}
      >
        <FormHelperText display="flex" mt="0" alignItems="center">
          <Icon name="GeneralStatusInfo" />
          <Text ml="1" textStyle="extraSmallBody">
            {label}
          </Text>
        </FormHelperText>
        {isManualAllowed && (
          <Link onClick={onClick} flexShrink={0}>
            {t('DESIGN-SYSTEM_ADDRESS-INPUT_BUTTON_MANUAL-ADDRESS-ENTRY')}
          </Link>
        )}
      </Stack>
    </VStack>
  );
};

export default AddressSearchFooter;
