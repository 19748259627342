import { Alert, AlertIcon, Box, SlideFade, Text } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isShown?: boolean;
}

const AskaPendingBanner: React.FC<Props> = ({ isShown }) => {
  const { t } = useTranslation();

  return (
    <SlideFade in={isShown}>
      <Alert p="3" status="loading" borderColor="blue.500" mb="6">
        <AlertIcon />
        <Box>
          <Text textStyle="smallBodyMedium" color="black" mb="1">
            {t('ASKA_FINALIZE_PENDING_BANNER_TITLE')}
          </Text>
          <Text textStyle="smallBody">
            {t('ASKA_FINALIZE_PENDING_BANNER_DESCRIPTION')}
          </Text>
        </Box>
      </Alert>
    </SlideFade>
  );
};

export default AskaPendingBanner;
