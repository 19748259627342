import { useActiveFeatureFlags } from 'posthog-js/react';
import { useMemo } from 'react';
import posthog from 'posthog-js';

const useFeatureFlags = (): Record<string, boolean> => {
  const activePosthogFlags = useActiveFeatureFlags();

  const posthogFeatureFlags = useMemo(
    () =>
      Object.assign(
        {},
        ...posthog.featureFlags.getFlags().map((key) => ({
          [key]: posthog.featureFlags.getFeatureFlag(key),
        })),
      ),
    [activePosthogFlags],
  );

  return posthogFeatureFlags || {};
};

export default useFeatureFlags;
