import React from 'react';
import {
  Icon,
  Box,
  Grid,
  HStack,
  VStack,
  Link,
  Tooltip,
  Text,
} from '@wegroup/design-system';
import { MinimalOffer } from '../../../../../types/Offer';
import InsuranceCompanyLogo from '../../../../common/src/components/InsuranceCompanyLogo';
import { customMediaQuery } from '../../../../common/src/utils/styledComponentsUtils';
import LabelValueWithSkeleton from '../../components/offers/LabelValueWithSkeleton';
import { useTranslation } from 'react-i18next';
import GrayTextBox from '../../../../company-scan/components/GrayTextBox';
import OfferPaymentPartyGroup from './OfferPaymentPartyGroup';
import { format, parseISO } from 'date-fns';

interface Document {
  title: string;
  href?: string;
}
interface Props {
  offer: MinimalOffer;
  insuranceName?: string;
}

const OfferPaymentListItemLeft: React.FC<Props> = ({
  offer,
  insuranceName,
}) => {
  const { t } = useTranslation();

  const documents = [
    {
      title: t('TERMS'),
      href: offer.insurance?.terms || '',
    },
    ...offer.documents.other.map(({ filename, link }) => ({
      title: filename,
      href: link,
    })),
  ];
  return (
    <VStack p="4" flexGrow="1" spacing="8" justify="space-between">
      <HStack w="100%" spacing={{ base: 0, tablet: '6' }} align="stretch">
        <Box
          maxW="24"
          maxH="20"
          display={{
            base: 'none',
            tablet: 'block',
          }}
        >
          <InsuranceCompanyLogo
            insuranceCompany={offer.insurance.company_key}
          />
        </Box>
        <Grid
          gap="2"
          w="100%"
          sx={{
            [customMediaQuery(0)]: { gridTemplateColumns: 'minmax(0, 1fr)' },
            [customMediaQuery(420)]: {
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr));',
            },
          }}
        >
          <LabelValueWithSkeleton
            label={offer.insurance.company}
            isMediumFontWeight
          >
            {insuranceName || t(offer.insurance.name)}
          </LabelValueWithSkeleton>
          <LabelValueWithSkeleton
            label={t('Desired contract start date')}
            isMediumFontWeight
          >
            {format(parseISO(offer.start_date), 'dd/MM/yyyy')}
          </LabelValueWithSkeleton>
        </Grid>
      </HStack>
      <Grid
        templateColumns={{
          base: '1fr',
        }}
        gap="3"
        w="100%"
      >
        {!!offer.risk_objects?.party_groups?.[0] && (
          <OfferPaymentPartyGroup
            group={offer.risk_objects?.party_groups?.[0]}
          />
        )}
        <GrayTextBox title={t('DOCUMENTS')} mt="2">
          {documents.map((document) => (
            <OfferSingleDocument key={document.title} {...document} />
          ))}
        </GrayTextBox>
      </Grid>
    </VStack>
  );
};

const OfferSingleDocument: React.FC<Document> = ({ title, href }) => {
  const { t } = useTranslation();

  return (
    <HStack color="gray.500">
      <Icon name="GeneralLabelsDocument" />
      {href ? (
        <Link variant="gray" href={href} isExternal>
          {title}
        </Link>
      ) : (
        <Tooltip label={t('This document is currently unavailable')}>
          <Text color="gray.500" cursor="pointer" textStyle="smallBody">
            {title}
          </Text>
        </Tooltip>
      )}
    </HStack>
  );
};

export default OfferPaymentListItemLeft;
