import { useMediaQuery, useTheme } from '@wegroup/design-system';
import { Global } from '@emotion/react';

const MantineSelectStyling = () => {
  const [isLargerThan1240] = useMediaQuery('(min-width: 1240px)');
  const { colors } = useTheme();

  const baseStylesChakra = {
    '*': {
      '--mantine-scale': isLargerThan1240 ? 1.6 : 1.8,
      '--mantine-font-size-xs': '12px',
      '--mantine-font-size-sm': '14px',
      '--mantine-font-size-md': '16px',
      '--mantine-font-size-lg': '18px',
      '--mantine-font-size-xl': '20px',
      '--mantine-primary-color-light': colors.primary[50],
      '--mantine-primary-color-light-hover': colors.primary[100],
      '--mantine-primary-color-light-color': colors.primary[500],
      '--input-bd-focus': `${colors.primary[500]} !important`,
    },
    '.mantine-Select-option[data-checked]': {
      color: colors.primary['500'],
      backgroundColor: colors.primary['50'],
    },

    '.mantine-Select-group': {
      '.mantine-Select-option': {
        borderBottom: 'none !important',
      },
    },
  };

  return <Global styles={baseStylesChakra} />;
};

export default MantineSelectStyling;
