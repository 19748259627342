import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface AskaFinalizeStore {
  hasAcceptedAcknowledgments: boolean;
  setAcceptedAcknowledgments: (accepted: boolean) => void;
}

const useAskaFinalizeStore = create<AskaFinalizeStore>()(
  persist(
    (set) => ({
      hasAcceptedAcknowledgments: false,
      setAcceptedAcknowledgments: (accepted: boolean) => {
        set({ hasAcceptedAcknowledgments: accepted });
      },
    }),
    {
      name: 'aska-finalize-store', // Name of the item in the storage
      storage: createJSONStorage(() => sessionStorage), // Use sessionStorage
    },
  ),
);

export default useAskaFinalizeStore;
