import { useQuery, UseQueryResult } from 'react-query';
import { ErrorResponse } from '../../../../../../../types/API';
import { Address } from '../../../../../../../types/Common';
import { wgApi } from '../../../../../../app/src/features/api/wgApi';

interface Response {
  items: Address[];
}

interface Params {
  partyId: string;
  enabled?: boolean;
}

export const getPartyAddresses = async (
  partyId: string,
): Promise<Address[]> => {
  const { data, isError } = await wgApi.get<Response>(
    `/v1/api/parties/${partyId}/addresses`,
  );

  if (isError) return Promise.reject(JSON.stringify(data));
  return data.items;
};

export const usePartyAddresses = ({
  enabled,
  partyId,
}: Params): UseQueryResult<Address[], ErrorResponse> => {
  return useQuery(
    ['party', 'addresses', partyId],
    () => getPartyAddresses(partyId),
    {
      enabled: !!enabled,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
};
