import { useLocale } from '../../../../i18n';
import useAuth from '../../../app/src/features/authentication/hooks/useAuth';
import { caribbeanCountries } from '../constants/caribbeanCountries';

const useIsCaribbean = () => {
  const { distribution } = useAuth();
  const distributionCountry = distribution.address.country_code || '';
  const { userCountry } = useLocale();
  return (
    caribbeanCountries.includes(distributionCountry) ||
    caribbeanCountries.includes(userCountry)
  );
};

export default useIsCaribbean;
