import { stringify } from 'query-string';
import {
  request,
  RequestProps,
} from '../../../common/src/legacy/services/httpSessionService';
import { LouiseSessionObject } from '../../../../types/ProductFactory';
import {
  LouiseQuote,
  Product,
  ProductErrorResponse,
  ProductsQualityRespWithPagination,
} from '../../../../types/Quotes';
import { REST_HOST } from '../../../../env.config';

/**
 * Retrieves all the possible products that can be retrieved for an insurance type
 * @param sessionId Flows session id
 * @param riskObject Insurance type like for example 'car'
 * @returns All possible products that are eligible for this session
 */
export const getAllProductsBySessionId = async (
  sessionId: string,
  riskObjects: string[],
): Promise<[Product, string]> => {
  const insurance_types = stringify({ insurance_type: riskObjects });
  const path = `v1/api/quotes/v1/api/products?session_id=${sessionId}&${insurance_types}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
};

export interface TopProductOptions {
  top?: number;
  sort?: 'DESCENDING' | 'ASCENDING';
  page?: number;
}

/**
 *
 * @param sessionId Flows session id
 * @param riskObject Insurance type like for example 'car'
 * @param top The amount of ofers that should be returned
 * @returns
 */
export const getTopProductsBySessionId = async (
  sessionId: string,
  riskObject: string,
  { top = 9999, sort = 'DESCENDING', page = 1 }: TopProductOptions = {},
): Promise<
  [ProductsQualityRespWithPagination | ProductErrorResponse, string]
> => {
  const path = `v1/api/quotes/v1/api/products?session_id=${sessionId}&insurance_type=${riskObject}&pagelen=${top}&sort=${sort}&page=${page}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
};

/**
 * Gets a product by id
 * @param id The id of the product to get
 * @returns The product as a LouiseQuote type
 */
export const getProductById = async (
  id: string,
): Promise<[LouiseQuote, string]> => {
  const path = `v1/api/quotes/v1/api/products/${id}`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
};

/**
 * Gets the flow session by session id
 * @param sessionId The id of the the session
 * @returns The session object for the session id
 */
export const getFlowSessionById = async (
  sessionId: string,
): Promise<[LouiseSessionObject, number]> => {
  const path = `v1/api/conversations/${sessionId}`;
  const [resp, status] = await request({
    path,
    method: 'GET',
    origin: REST_HOST,
  });
  return [resp, status];
};

interface FlowCompletion {
  recommendation_id?: string;
  status: string;
  remarks: {
    customer_is_insured: boolean;
  };
}

/**
 * Marks the flow as complete
 * @param sessionId The id of the the session
 */
export const markFlowAsComplete = async ({
  conversationId,
  recommendationId,
}: {
  conversationId: string;
  recommendationId?: string;
}): Promise<[FlowCompletion, number]> => {
  const path = `v1/api/conversations/${conversationId}/complete`;

  const requestObj: RequestProps = {
    path,
    method: 'POST',
    origin: REST_HOST,
  };

  if (recommendationId) {
    requestObj.payload = {
      recommendation_id: recommendationId,
    };
  }

  const [resp, status] = await request(requestObj);
  return [resp, status];
};
