import { CellProps, Renderer } from 'react-table';
import { TableData } from '@wegroup/design-system';

export interface ConversationSession {
  id: string;
  title?: string;
  description: string | null;
  prospect: ConversationParty;
  origin: string | null;
  status: ConversationStatus;
  created_at: string;
  modified_at: string;
  self: string;
  flow_id: string;
  assigned_to?: string;
  assigned_to_name?: string;
  recommendation?: {
    id: string;
    self: string;
  };
}

export type CellRenderer = Renderer<CellProps<ConversationSession & TableData>>;

export interface ConversationParty {
  id: string;
  name?: string | null;
  display_name: string | null;
  type: keyof typeof ConversationPartyType;
  self: string | null;
}

// ─── ENUMS ──────────────────────────────────────────────────────────────────────
//

export enum ConversationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ABANDONED = 'ABANDONED',
}

export enum ConversationPartyType {
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
}

export enum StatusColor {
  IN_PROGRESS = 'info.main',
  PENDING = 'warning.dark',
  COMPLETED = 'success.main',
  ABANDONED = 'danger.main',
}
