import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationPersonalization: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.711 28.797a5.718 5.718 0 00-6.833 5.607v5.718h5.718a5.718 5.718 0 001.115-11.325zm-4.686-3.012a9.33 9.33 0 113.57 17.949H8.073a1.806 1.806 0 01-1.806-1.806v-7.523a9.33 9.33 0 015.76-8.62z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M43.717 7.827a1.806 1.806 0 01-1.544 2.034 28.29 28.29 0 00-22.631 18.034 1.806 1.806 0 11-3.379-1.276 31.9 31.9 0 0125.52-20.336 1.806 1.806 0 012.034 1.544z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.173 6.283a1.806 1.806 0 011.544 2.034 31.9 31.9 0 01-20.336 25.52 1.806 1.806 0 01-1.276-3.379 28.289 28.289 0 0018.034-22.63 1.806 1.806 0 012.034-1.545z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.727 18.6a1.806 1.806 0 012.396-.882 18.733 18.733 0 019.16 9.159 1.806 1.806 0 01-3.28 1.513 15.122 15.122 0 00-7.393-7.393 1.806 1.806 0 01-.883-2.396z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationPersonalization;
