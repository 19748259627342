import React from 'react';
import {
  Text,
  Tag,
  HStack,
  Icon,
  Link,
  Wrap,
  Box,
  VStack,
  ListItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  UnorderedList,
  Center,
  Grid,
} from '@wegroup/design-system';
import {
  InsuranceTermsBotChunk,
  InsuranceTermsBotResource,
  InsuranceTermsBotResourceSection,
} from '../../api/postInsuranceTermsBotChat';
import { useTranslation } from 'react-i18next';
import useTracking from '../../../../../../common/src/hooks/tracking/useTracking';
import { truncate, uniqBy } from 'lodash';
import { formatDate } from '../../../../../../common/src/utils/dateUtils';
import i18n from '../../../../../../../i18n';

interface Props {
  resources: InsuranceTermsBotResource[];
}

const LouiseChatResources: React.FC<Props> = ({ resources }) => {
  const { t } = useTranslation();
  const filteredResources = uniqBy(resources, (resource) => resource.ref);

  return (
    <VStack align="flex-start">
      <Text
        // Using fontsize instead of textstyle because of a bug with the textstyle for CSS print
        fontSize="sm"
        flexShrink={0}
        sx={{
          '@media print': {
            // This is needed to fix an issue with font sizing for CSS print
            fontSize: '12px !important',
          },
        }}
      >
        {t('TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_READ-MORE-AT')}:
      </Text>
      <Wrap
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        {[...filteredResources].map(({ ref, ...resource }) => (
          <ResourceTag
            {...resource}
            refNr={ref}
            key={
              resource.link + resource.section.reference + resource.section.page
            }
          />
        ))}
      </Wrap>
      <UnorderedList
        pl="4"
        spacing="1"
        display="none"
        sx={{
          '@media print': {
            display: 'block',
          },
        }}
      >
        {[...filteredResources].map(({ ref, ...resource }) => (
          <PrintResourceTag
            {...resource}
            refNr={ref}
            key={
              resource.link + resource.section.reference + resource.section.page
            }
          />
        ))}
      </UnorderedList>
    </VStack>
  );
};

const getDocumentTitle = (
  name: string,
  section: InsuranceTermsBotResourceSection,
) => {
  return `${section.title || name} ${
    section.page ? `(${i18n.t('PAGE')} ${section.page})` : ''
  }`;
};

const ResourceTag: React.FC<
  Omit<InsuranceTermsBotResource, 'ref'> & { refNr: number }
> = ({ refNr, section, link, icon_key, name, created_at }) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();

  const documentTitle = getDocumentTitle(name, section);

  return (
    <Tag
      shrink={0}
      textStyle="extraSmallBody"
      size="sm"
      as={Link}
      href={section.page ? `${link}#page=${section.page}` : link}
      isExternal
      onClick={() => trackEvent('Open document from Louise GPT answer')}
    >
      <HStack>
        <Icon name={icon_key} boxSize={4} />
        <Text as="span">
          {refNr != null ? `[${refNr}] ` : ''}
          {truncate(documentTitle, {
            length: 128,
            separator: ' ',
          })}
        </Text>
        <Popover trigger="hover" placement="auto">
          <PopoverTrigger>
            {/* Through negative margin we try to negate the viewbox which is too large */}
            <Box mt="-1" mb="-1" mr="-1">
              <Icon name="GeneralStatusInfo" />
            </Box>
          </PopoverTrigger>
          <PopoverContent
            bg="gray.700"
            color="white"
            textStyle="smallBody"
            lineHeight="5"
          >
            <PopoverArrow bg="gray.700" color="white" />
            <PopoverBody>
              <VStack align="flex-start" spacing="3">
                <Text textStyle="smallBodyMedium">{documentTitle}</Text>

                {section.chunks?.length && (
                  <VStack align="stretch" spacing="1">
                    <Text textStyle="smallBodyMedium">
                      {t('TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_RESOURCE-TO')}
                    </Text>
                    <VStack pl="2" align="stretch">
                      {section.chunks?.map((chunk, index) => (
                        <LouiseChatResourceChunk key={index} {...chunk} />
                      ))}
                    </VStack>
                  </VStack>
                )}

                <Text fontSize="xs" fontStyle="italic">
                  (
                  {t('TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_RESOURCE-INFO', {
                    date: formatDate(created_at),
                  })}
                  )
                </Text>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </HStack>
    </Tag>
  );
};

const LouiseChatResourceChunk: React.FC<InsuranceTermsBotChunk> = ({
  score,
  text,
}) => {
  const getColor = () => {
    if (score >= 85) {
      return 'success.main';
    }
    if (score >= 70) {
      return 'warning.main';
    }
    return '#FF8000';
  };

  return (
    <Grid templateColumns="auto 1fr" gridGap="2">
      <Center
        w="2"
        h="2"
        mt="1.5"
        borderRadius="full"
        flexShrink={0}
        bg={getColor()}
      />
      <Text>{text}</Text>
    </Grid>
  );
};

const PrintResourceTag: React.FC<
  Omit<InsuranceTermsBotResource, 'ref'> & { refNr: number }
> = ({ name, link, section, refNr }) => {
  const documentTitle = getDocumentTitle(name, section);
  return (
    <ListItem fontSize="xs" lineHeight="1.2">
      <Text>
        {`[${refNr}] `}
        {documentTitle}:
      </Text>{' '}
      <Link href={link} isExternal>
        {link}
      </Link>
    </ListItem>
  );
};

export default LouiseChatResources;
