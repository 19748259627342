import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersChart: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M33.223 11.158a2 2 0 011.525.583l8.333 8.333a2 2 0 01-2.829 2.829L33.5 16.15l-6.937 8.671a2 2 0 01-2.456.54l-6.755-3.377-7.354 11.03a2 2 0 01-3.328-2.218l8.334-12.5a2 2 0 012.558-.68l6.887 3.444 7.324-9.154a2 2 0 011.45-.748zm-26.89 29.08a2 2 0 012-2h33.334a2 2 0 010 4H8.333a2 2 0 01-2-2z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersChart;
