import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersList: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.667 12.5c0-1.15.932-2.083 2.083-2.083h22.917a2.083 2.083 0 010 4.166H18.75a2.083 2.083 0 01-2.083-2.083zM16.667 25c0-1.15.932-2.083 2.083-2.083h22.917a2.083 2.083 0 010 4.166H18.75A2.083 2.083 0 0116.667 25zM16.667 37.5c0-1.15.932-2.083 2.083-2.083h22.917a2.083 2.083 0 010 4.166H18.75a2.083 2.083 0 01-2.083-2.083zM10.417 10.417c1.15 0 2.083.932 2.083 2.083v.02a2.083 2.083 0 01-4.167 0v-.02c0-1.15.933-2.083 2.084-2.083zM10.417 22.917c1.15 0 2.083.932 2.083 2.083v.02a2.083 2.083 0 01-4.167 0V25c0-1.15.933-2.083 2.084-2.083zM10.417 35.417c1.15 0 2.083.932 2.083 2.083v.02a2.083 2.083 0 01-4.167 0v-.02c0-1.15.933-2.083 2.084-2.083z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersList;
