const parts = ['container', 'label', 'closeButton'];

const baseStyle = {
  container: {
    px: '2',
    py: '1',
    bgColor: 'primary.50',
    color: 'primary.500',
    boxShadow: 'level1',
    borderRadius: 'full',
  },
  closeButton: {
    textStyle: 'extraSmallBodyMedium',
    color: 'primary.500',
    opacity: '1',
    mr: '0',
  },
};

const variants = {
  primary: {
    container: {
      color: 'primary.500',
      bg: 'primary.50',
    },
  },
  secondary: {
    container: {
      color: 'white',
      bg: 'primary.500',
    },
  },
  error: {
    container: {
      color: 'danger.main',
      bg: 'red.50',
    },
  },
  success: {
    container: {
      color: 'success.main',
      bg: 'green.50',
    },
  },
  info: {
    container: {
      color: 'info.main',
      bg: 'info.light',
    },
  },
  warning: {
    container: {
      color: 'warning.dark',
      bg: 'warning.light',
    },
  },
  grayBordered: {
    container: {
      color: 'gray.700',
      bg: 'gray.ultraLight',
      fontWeight: '400',
      border: '1px',
      boxShadow: 'none',
      borderColor: 'gray.50',
      borderRadius: 'lg',
    },
  },
  gray: {
    container: {
      color: 'gray.300',
      bg: 'gray.strokeAndBg',
    },
  },
  inverted: {
    container: {
      color: 'primary.500',
      bg: 'white',
      boxShadow: 'level1',
    },
  },
};

const sizes = {
  sm: {
    container: {
      borderRadius: 'full',
    },
  },
  md: {
    container: {
      borderRadius: 'full',
    },
  },
  lg: {
    container: {
      borderRadius: 'full',
    },
  },
};

const defaultProps = {
  variant: 'default',
};

const Tag = {
  parts,
  baseStyle,
  variants,
  defaultProps,
  sizes,
};

export default Tag;
