import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import { usePostHog } from 'posthog-js/react';
import useFeatureFlags from '../../../../app/src/hooks/useFeatureFlags';
import useUserpilot from './useUserpilot';

type LogrocketMetadata = Record<
  string,
  string | number | boolean | boolean[] | string[] | number[] | undefined
>;

interface TrackingHook {
  trackEvent: (eventName: string, metadata?: LogrocketMetadata) => void;
  trackPageView: (pathname: string) => void;
}

const useTracking = (): TrackingHook => {
  const { isForeignDataTrackingDisabled } = useFeatureFlags();
  const { trackEvent: trackUserpilotEvent, update } = useUserpilot();
  const posthog = usePostHog();

  const trackEvent = (eventName: string, metadata?: LogrocketMetadata) => {
    if (!isForeignDataTrackingDisabled) {
      if (process.env.NODE_ENV === 'development') {
        console.info('Tracking event:', eventName);
      }

      try {
        mixpanel.track(eventName);
        trackUserpilotEvent(eventName);
        LogRocket.track(eventName, metadata);
        posthog.capture(eventName, metadata);
      } catch {
        // TO DO: Send this info to Sentry
        console.error('Could not send tracking info');
      }
    }
  };

  const trackPageView = (pathname: string) => {
    if (!isForeignDataTrackingDisabled) {
      update({
        last_viewed_page: pathname,
      });

      if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
        window.gtag('event', 'page_view', {
          page_title: document.title,
          page_location: window.location.href,
          page_path: pathname,
          send_to: 'G-0CN8KWVPCJ',
        });
      }
    }
  };

  return { trackEvent, trackPageView };
};

export default useTracking;
