import {
  isCampaignsUrl,
  isConversationUrl,
  isFinalizeUrl,
  isLouiseQAUrl,
  isSocialLoginUrl,
} from '../../constants/SocialAuth';
import { wgApi } from '../../../api/wgApi';
import { useCurrentCampaign } from './getCampaign';
import { useQuery, UseQueryResult } from 'react-query';
import { Distribution } from '../../types/Distribution';
import { getJwtToken } from '../../utils/jwt';
import useIsAuthorized from '../../hooks/useIsAuthorized';
import useBrokerId from '../../../../../../common/src/hooks/query-params/useBrokerId';
import { useBroker } from './getBroker';
import { ErrorResponse } from '../../../../../../../types/API';
import { queryClient } from '../../../../providers/queryClient';

const getDistribution = async (distributionId: string) => {
  const { data, isError } = await wgApi.get<{ distribution: Distribution }>(
    `v1/api/broker/v1/api/distributions/${distributionId}`,
  );
  if (isError) {
    const error = data as ErrorResponse;
    throw new Error(error.msg || 'RESOURCE_NOT_FOUND');
  }
  return data.distribution;
};

export const getDistributionQKey = (): string[] => ['user', 'distribution'];

export const getDistributionDataFromQStore = (): Distribution => {
  // Distribution will always be defined, otherwise the application won't load
  return queryClient.getQueryData<Distribution>(getDistributionQKey())!;
};

/**
 * Distribution id can be retrieved through multiple methods:
 * - The JWT token
 * - The campaign (Social login only)
 * - The party id if it is included in the url params (Social login only)
 * @returns
 */
export const useDistributionId = (): string => {
  if (isCampaignsUrl) {
    const { data: currentCampaign } = useCurrentCampaign();
    return currentCampaign?.distribution_id || '';
  } else if (
    isConversationUrl ||
    isLouiseQAUrl ||
    (isFinalizeUrl && isSocialLoginUrl)
  ) {
    const brokerId = useBrokerId();
    const { data: broker } = useBroker(brokerId);
    return broker?.distribution_id || '';
  }

  const jwtData = getJwtToken();

  return jwtData?.ext?.did || '';
};

export const useDistribution = (
  onError?: (error: Error) => void,
): UseQueryResult<Distribution> => {
  const isAuthorized = useIsAuthorized();
  const distributionId = useDistributionId();
  return useQuery(
    getDistributionQKey(),
    () => getDistribution(distributionId!),
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isSocialLoginUrl
        ? !!distributionId
        : !!distributionId && isAuthorized,
      onError,
    },
  );
};
