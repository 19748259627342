import React, { ReactElement } from 'react';
import { useRadioGroupContext, useRadio } from '@wegroup/design-system';
import Bullet from '../Bullet';
import IllustratedButton from '../IllustratedButton';

export interface IllustratedRadioButtonProps {
  children?: React.ReactNode;
  bullet?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  value?: string;
  description?: string;
  Illustration?: ReactElement;
}

const IllustratedRadioButton = React.forwardRef<
  HTMLInputElement,
  IllustratedRadioButtonProps
>(
  (
    {
      children,
      bullet = true,
      icon,
      value,
      disabled,
      description,
      Illustration,
      ...rest
    },
    ref,
  ) => {
    const { value: groupValue, onChange } = useRadioGroupContext();
    const isChecked = groupValue === value;
    const { getInputProps, getLabelProps } = useRadio({
      ...rest,
      isChecked: isChecked,
      onChange: onChange,
      isDisabled: disabled,
      value: value,
    });

    return (
      <IllustratedButton
        disabled={disabled}
        description={description}
        isChecked={isChecked}
        Illustration={Illustration}
        inputBox={<Bullet {...getLabelProps()} />}
        input={<input {...getInputProps({}, ref)} />}
        dataTestId={`DESIGN-SYSTEM_ILLUSTRATED-RADIO-BUTTON_${value}`}
        {...getLabelProps()}
      >
        {children}
      </IllustratedButton>
    );
  },
);

export default IllustratedRadioButton;
