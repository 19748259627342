import { Skeleton, Text, VStack } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toEuro } from '../../../../../utils/stringUtils';
import useOffersPageParams from '../../../hooks/useOffersPageParams';

interface Props {
  paymentInterval?: string;
  price?: number;
  policyCosts?: number;
  isLoading?: boolean;
  currency?: string;
  risk?: string;
}

const OfferPremium: React.FC<Props> = ({
  paymentInterval,
  price,
  policyCosts,
  currency,
  isLoading,
  risk,
}) => {
  const params = useOffersPageParams();
  const riskObject = params.riskObject || risk;

  const getIntervalTextByRisk = () => {
    switch (riskObject) {
      case 'travel':
        return t('SALES-CONVERSATION_OFFERS_PRICE_INCLUSIVE-TAX_TRAVEL');
      default:
        return t('SALES-CONVERSATION_OFFERS_PRICE_INCLUSIVE-TAX');
    }
  };
  const { t } = useTranslation();

  if (isLoading)
    return (
      <VStack
        justify="flex-start"
        align={{ base: 'flex-end', tablet: 'flex-start' }}
      >
        <Text color="text.gray">
          {t('ADVISORY-REPORTS_CURRENT-INSURANCE_OVERVIEW_PREMIUM')}
        </Text>
        <Skeleton h="10" w="40" />
      </VStack>
    );

  if (price == null || paymentInterval == null) return null;

  return (
    <VStack
      justify="flex-start"
      align={{ base: 'flex-end', tablet: 'flex-start' }}
    >
      {paymentInterval.toUpperCase() !== 'ONCE' && (
        <Text color="text.gray">
          {t('SALES-CONVERSATION_OFFERS_PRICE_INTERVAL_PREMIUM-PER', {
            interval: t(paymentInterval.toUpperCase()).toLowerCase(),
          })}{' '}
        </Text>
      )}
      <Text textStyle="h1Headline">{toEuro(price, currency)}</Text>
      <Text textStyle="extraSmallBody" color="gray.400">
        {getIntervalTextByRisk()}
      </Text>
      {policyCosts && (
        <Text
          textStyle="extraSmallBody"
          color="gray.400"
          dangerouslySetInnerHTML={{
            __html: t('SALES-CONVERSATION_OFFERS_PREMIUM_POLICY-COSTS', {
              cost: toEuro(policyCosts, currency),
            }).toLowerCase(),
          }}
        />
      )}
    </VStack>
  );
};

export default OfferPremium;
