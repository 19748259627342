/* eslint-disable @typescript-eslint/no-explicit-any */
import { wgApi } from '../../../../app/src/features/api/wgApi';

export interface RequestProps {
  path: string;
  method?: string;
  payload?: any;
  customHeaders?: Record<string, unknown>;
  isBlob?: boolean;
  explicitNull?: boolean;
  origin?: string;
}

/**
 * Please do not use this function anymore. Use the API class for this.
 * For the WeGroup API, use the `wgApi` instance.
 * @deprecated
 */
export async function request({
  path,
  method = 'GET',
  payload,
  customHeaders,
  isBlob = false,
  explicitNull,
  origin,
}: RequestProps): Promise<any> {
  let resp;

  if (method === 'GET') {
    resp = await wgApi.get(path, { headers: customHeaders as HeadersInit });
  } else if (method === 'POST') {
    resp = await wgApi.post(path, payload, {
      headers: customHeaders as HeadersInit,
    });
  } else if (method === 'PUT') {
    resp = await wgApi.put(path, payload, {
      headers: customHeaders as HeadersInit,
    });
  } else if (method === 'PATCH') {
    resp = await wgApi.patch(path, payload, {
      headers: customHeaders as HeadersInit,
      explicitNull,
    });
  } else if (method === 'DELETE') {
    resp = await wgApi.delete(path, { headers: customHeaders as HeadersInit });
  }

  return [resp?.data, resp?.status];
}
