const Badge = {
  baseStyle: {
    borderRadius: 'full',
    px: '2',
    py: '0.5',
  },
  variants: {
    primary: {
      color: 'white',
      bg: 'primary.500',
    },
    secondary: {
      color: 'primary.500',
      bg: 'primary.50',
    },
    error: {
      color: 'danger.main',
      bg: 'danger.light',
    },
    warning: {
      color: 'warning.dark',
      bg: 'warning.light',
    },
    success: {
      color: 'success.dark',
      bg: 'success.light',
    },
    gray: {
      color: 'gray.300',
      bg: 'gray.strokeAndBg',
    },
    inverted: {
      color: 'primary.500',
      bg: 'white',
      boxShadow: 'level1',
    },
  },
  sizes: {
    xs: {
      px: '1',
      py: '0.5',
    },
    sm: {
      px: '1',
      py: '0.5',
      fontSize: '10px',
    },
    md: {
      px: '2',
      py: '0.5',
    },
    lg: {
      px: '2',
      py: '0.5',
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default Badge;
