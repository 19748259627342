import React, { PropsWithChildren } from 'react';
import CaptchaComponentV3 from './CaptchaComponentV3';

interface Props {
  isAuthorized: boolean;
  isAccessTokenExpired: boolean;
  email: string;
}

const ReAuthenticateComponentV3: React.FC<PropsWithChildren & Props> = ({
  children,
  ...props
}) => {
  return (
    <>
      <CaptchaComponentV3 {...props} />
      {children}
    </>
  );
};

export default ReAuthenticateComponentV3;
