const cf = {
  primary: {
    main: '#00a870',
    50: '#E6F8F2',
    100: '#CCEDE3',
    150: '#80E3C1',
    200: '#6ebd8f',
    300: '#26C38D',
    400: '#26C38D',
    500: '#00a870',
    600: '#008251',
    700: '#007045',
    750: '#00633B',
    800: '#004F30',
    850: '#004530',
    900: '#003625',
  },
  secondary: {
    main: '#ed694b',
    50: '#FFEDE9',
    100: '#FFC7BB',
    150: '#FFA192',
    200: '#FF7A68',
    300: '#FFEDE9',
    400: '#F45538',
    500: '#ED694B',
    600: 'rgb(220,91,65)',
    700: '#C1453A',
    750: '#A2372F',
    800: '#ED694B',
    850: '#751F19',
    900: '#531510',
  },
};

export default cf;
