import React from 'react';
import { Image } from '@wegroup/design-system';
import useAuth from '../../../app/src/features/authentication/hooks/useAuth';
import { useAvailableInsuranceCompanies } from '../../../sales-conversation/src/features/offers/api/getAvailableInsuranceCompanies';
import { handleResponseLanguage } from '../../../../i18n';
import useIsNewRoute from '../../../sales-conversation/src/features/offers/hooks/useIsNewRoute';
import { isSocialLoginUrl } from '../../../app/src/features/authentication/constants/SocialAuth';

interface Props {
  insuranceCompany?: string;
}

const InsuranceCompanyLogo: React.FC<React.PropsWithChildren<Props>> = ({
  insuranceCompany,
}) => {
  const { distribution } = useAuth();
  const { data, isError, isFetched } = useAvailableInsuranceCompanies();
  const isNewRoute = useIsNewRoute();

  if ((isSocialLoginUrl || !insuranceCompany) && !isNewRoute)
    return (
      <Image
        h="100%"
        w="100%"
        objectFit="contain"
        src={distribution.logos.logo}
        alt={`${distribution.name} logo`}
      />
    );

  const foundCompany = data?.items.find(
    ({ insurance_company }) =>
      insurance_company.key === insuranceCompany?.toLowerCase(),
  )?.insurance_company;

  if (!isFetched || isError || !foundCompany) {
    return null;
  }

  return (
    <Image
      h="100%"
      w="100%"
      objectFit="contain"
      src={foundCompany?.logo}
      alt={`${handleResponseLanguage(foundCompany?.names)} logo`}
    />
  );
};

export default InsuranceCompanyLogo;
