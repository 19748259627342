import { Slice } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';
import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';
import useUpload from '../../../../../../common/src/hooks/useUpload';

const useHandlePaste = () => {
  const { t } = useTranslation();
  const { uploadFile } = useUpload();

  const handleImageUpload = (file: File): Promise<string> => {
    if (file.size <= 2000000) {
      return new Promise((resolve, reject) => {
        uploadFile(file)
          .then(({ public_url }) => {
            resolve(public_url);
          })
          .catch(() => reject(new Error('Upload failed')));
      });
    }
    cogoToast.warn(t('UPLOAD-MAX-FILE-SIZE'));
    return new Promise((_, reject) => reject(new Error('File to big')));
  };

  const handlePaste = (
    view: EditorView,
    event: ClipboardEvent,
    slice: Slice,
  ) => {
    const item = event.clipboardData?.items[0];

    if (item?.type.indexOf('image') !== 0) {
      return false;
    }

    const file = item.getAsFile();
    if (file) {
      handleImageUpload(file).then((url) => {
        const node = new Image();
        node.src = url;
        view.pasteHTML(node.outerHTML);
        return true;
      });
    } else {
      return false;
    }
  };

  return handlePaste;
};

export default useHandlePaste;
