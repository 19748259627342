import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { markFlowAsComplete } from '../../../../../../sales-conversation/src/services/louiseApiService';
import { getUseConversationQKey } from './getConversationById';
import { ConversationDetail } from '../../../overview/types/DetailTypes';
import { useConversationRecommendations } from './getConversationRecommendations';

interface Payload {
  conversationId: string;
  recommendationId?: string;
}

export const completeConversation = async ({
  conversationId,
  recommendationId,
}: Payload) => {
  const [resp, status] = await markFlowAsComplete({
    conversationId,
    recommendationId,
  });

  if (status >= 400) throw new Error();

  return resp;
};

export const useCompleteConversation = (
  conversationId: string,
  withToast = true,
) => {
  const { t } = useTranslation();
  const conversationQKey = getUseConversationQKey(conversationId);
  const queryClient = useQueryClient();
  const { data: recommendations } =
    useConversationRecommendations(conversationId);

  return useMutation(
    (recommendationId?: string) =>
      completeConversation({
        conversationId,
        recommendationId: recommendationId || recommendations?.id,
      }),
    {
      onMutate: () => {
        const conversationData =
          queryClient.getQueryData<ConversationDetail>(conversationQKey);

        if (!conversationData) return;

        queryClient.setQueryData(conversationQKey, {
          ...conversationData,
          status: 'COMPLETED',
        });

        return conversationData;
      },
      onError: (_, __, context) => {
        cogoToast.error(t('Something went wrong'));
        queryClient.setQueryData(conversationQKey, context);
      },
      onSuccess: () => {
        if (withToast)
          cogoToast.success(
            t('CONVERSATIONS_API_COMPLETE-CONVERSATION_SUCCESS'),
          );
        queryClient.refetchQueries(conversationQKey);
      },
    },
  );
};
