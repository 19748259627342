const NumberInput = {
  variants: {
    outline: {
      field: {
        borderColor: 'gray.100',
        _focus: {
          borderColor: 'primary.500',
          boxShadow: 'none',
        },
        _error: {
          borderColor: 'danger.main',
          boxShadow: 'none',
        },
      },
    },
  },
};

export default NumberInput;
