import { useLocation } from 'react-router';

const useIsNewRoute = (): boolean => {
  const { pathname } = useLocation();
  const isNewRoute =
    pathname.includes('/sales-conversation') ||
    pathname.includes('/aska-travel');
  return isNewRoute;
};

export default useIsNewRoute;
