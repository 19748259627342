import React from 'react';

interface Props {
  color?: string;
}

const Plant3: React.FC<React.PropsWithChildren<Props>> = ({
  color = '#FF8000',
  ...rest
}) => {
  return (
    <svg
      width="133"
      height="172"
      viewBox="0 0 133 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <line
        x1="1.58503"
        y1="171.502"
        x2="39.585"
        y2="63.5021"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="9.11774"
        y1="150.787"
        x2="53.1177"
        y2="118.787"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="20.917"
        y1="116.962"
        x2="89.917"
        y2="44.9621"
        stroke={color}
        strokeWidth="3"
      />
      <circle cx="47.5" cy="39.5" r="24.5" stroke={color} strokeWidth="4" />
      <circle cx="106.5" cy="26.5" r="24.5" stroke={color} strokeWidth="4" />
      <circle cx="72.5" cy="104.5" r="24.5" stroke={color} strokeWidth="4" />
    </svg>
  );
};

export default Plant3;
