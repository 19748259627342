import { useQuery, UseQueryResult } from 'react-query';
import useFeatureFlags from '../../../app/src/hooks/useFeatureFlags';

import {
  retrieveAvailableAffiliations,
  searchAddressREST,
} from '../legacy/services/apiRouterService';
import { request } from '../legacy/services/httpSessionService';
import { Affiliations } from '../../../../types/Broker';
import useAuth from '../../../app/src/features/authentication/hooks/useAuth';
import { isSocialLoginUrl } from '../../../app/src/features/authentication/constants/SocialAuth';

/** @deprecated */
export const useAffiliations = (): UseQueryResult<Affiliations, unknown> =>
  useQuery<Affiliations>(
    ['affiliations'],
    async () => {
      const [resp, status] = await retrieveAvailableAffiliations();
      if (status !== 200) throw new Error();
      return resp;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
      enabled: !isSocialLoginUrl,
    },
  );

export const getAllAvailableCarriers = async (): Promise<
  [Affiliations, number]
> => {
  const path = `v1/api/broker/v1/api/affiliations`;
  const [resp, status] = await request({ path, method: 'GET' });
  return [resp, status];
};

export const getAllAvailableCarriersQFn = async (
  isPiaEnabled?: boolean,
): Promise<Affiliations> => {
  const [resp, status] = await getAllAvailableCarriers();
  if (status !== 200) throw new Error();

  // HARDCODE Pia
  if (isPiaEnabled) {
    return {
      ...resp,
      items: [...resp.items],
    };
  }
  return resp;
};

export const useCarriers = (): UseQueryResult<Affiliations, unknown> => {
  const { isPiaEnabled } = useFeatureFlags();
  return useQuery<Affiliations>(
    ['v1/api/broker/v1/api/affiliations'],
    async () => await getAllAvailableCarriersQFn(isPiaEnabled),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
      enabled: !isSocialLoginUrl,
    },
  );
};

export const useAddressBrokerInfo = (): UseQueryResult<[number, number]> => {
  const { distribution } = useAuth();

  return useQuery(
    ['distribution', 'latlong', distribution.address],
    async () => {
      const [resp, status] = await searchAddressREST(
        `${distribution.address.street} ${distribution.address.housenr} ${distribution.address.city}`,
        'nl',
        distribution.address.country_code || '',
      );

      if (status > 201) {
        const weGroupOfficeLatLong = [51.02989, 3.73747];
        return weGroupOfficeLatLong;
      }

      const address = resp.items[0].address;

      return [address.lat, address.lng];
    },
  );
};
