import React, { PropsWithChildren, useEffect, useState } from 'react';
import { buildAbilityFor } from '../../features/permissions/ability';
import { AbilityContext } from '../../features/permissions/Can';
import useAuth from '../../features/authentication/hooks/useAuth';

export const PermissionsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { user } = useAuth();

  const [ability, setAbility] = useState(
    buildAbilityFor(user!.modules_v2 || {}),
  );

  useEffect(() => {
    setAbility(buildAbilityFor(user!.modules_v2 || {}));
  }, [user]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
