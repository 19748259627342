import {
  FormControl,
  FormLabel,
  SearchSelect,
  FormErrorMessage,
} from '@wegroup/design-system';
import { uniqBy } from 'lodash';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Street, useStreetSearch } from '../../../api';
import useDebouncedSearchSelectQuery from '../../../hooks/useDebouncedQuery';
import useNothingFoundText from '../../../hooks/useNothingFoundText';

export interface StreetSearchProps {
  name?: string;
  value?: string;
  countryCode?: string;
  cityName?: string;
  onChange: (street?: string | null) => void;
  onBlur?: () => void;
  error?: FieldError;
  isDisabled?: boolean;
  isPrefilled?: boolean;
}

const StreetSearch: React.FC<StreetSearchProps> = ({
  name,
  value,
  countryCode,
  cityName,
  onChange,
  onBlur,
  error,
  isDisabled,
  isPrefilled,
}) => {
  const { t } = useTranslation();

  const { query, handleSearchChange } = useDebouncedSearchSelectQuery();

  const { data: streetsList, isLoading } = useStreetSearch({
    query: query.toLowerCase(),
    qCity: cityName,
    countryCode,
  });

  const nothingFoundText = useNothingFoundText({
    isLoading,
    query,
  });

  const getStreetOptions = (streetsList?: Street[]) => {
    if (streetsList) {
      return (
        uniqBy(
          streetsList?.map((street) => ({
            value: street.name,
            label: street.name,
          })),
          'label',
        ) || []
      );
    }

    if (value)
      return [
        {
          value: value,
          label: value,
        },
      ];

    return [];
  };

  const handleChange = (street: string | null) => {
    onChange(street);
  };

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel>{t('STREET')}</FormLabel>
      <SearchSelect
        name={name}
        data={getStreetOptions(streetsList)}
        isLoading={isLoading}
        value={value || null}
        onChange={handleChange}
        onBlur={onBlur}
        onSearchChange={handleSearchChange}
        nothingFound={nothingFoundText}
        isDisabled={isDisabled}
        prefilled={value != null && isPrefilled}
        clearable
        isDataFilterDisabled
      />
      {error?.message && (
        <FormErrorMessage>{t(error.message)}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default StreetSearch;
