import React from 'react';
import {
  OverflowCollapse,
  Tag,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  TagLabel,
  TagCloseButton,
  Box,
  Portal,
} from '@wegroup/design-system';
import { useBoolean } from '@chakra-ui/hooks';

interface Option {
  label: string;
  value: string;
  icon?: React.FC<React.PropsWithChildren<{ color: string }>>;
}
interface Props {
  onClick: (option: Option) => void;
  onClosePopover?: () => void;
  selectedOptions: Option[];
  isDisabled?: boolean;
  setRestAmount?: (rest: number) => void;
  suffix?: React.ReactElement;
}

const SearchInputTagList: React.FC<React.PropsWithChildren<Props>> = ({
  onClick,
  selectedOptions,
  onClosePopover,
  isDisabled,
  suffix,
  setRestAmount,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useBoolean();

  const handleOnTagClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPopoverOpen.on();
    onClosePopover?.();
  };

  const handleOnTagRemove = (
    e: React.MouseEvent<HTMLButtonElement>,
    option: Option,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(option);
    onClosePopover?.();
  };

  const renderItem = (item: Option) => {
    return (
      <Tag key={`tag_${item.value}`} cursor="pointer" mr="2">
        <TagLabel whiteSpace="nowrap" display="block">
          {item.label}
        </TagLabel>
        {!isDisabled && (
          <TagCloseButton onMouseDown={(e) => handleOnTagRemove(e, item)} />
        )}
      </Tag>
    );
  };

  const renderRest = (items: Option[]) => {
    setRestAmount?.(items.length);
    if (items.length === 0) {
      setIsPopoverOpen.off();
    }

    return (
      <Box flexShrink={0} sx={{ '>div': { zIndex: 20 } }}>
        <Popover isOpen={isPopoverOpen} onClose={setIsPopoverOpen.off}>
          <PopoverTrigger>
            <Tag
              cursor="pointer"
              onClick={handleOnTagClick}
              _hover={{ bg: 'primary.100' }}
              transition="0.2s"
              h="7"
              flexShrink={0}
              mr="2"
            >
              <TagLabel>+ {items.length} ...</TagLabel>
            </Tag>
          </PopoverTrigger>
          <Portal appendToParentPortal={false}>
            <Box
              sx={{
                '.chakra-popover__popper': {
                  zIndex: 'popover',
                },
              }}
            >
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  {items.map((option) => (
                    <Tag
                      key={`hidden_tag_${option.value}`}
                      onClick={() => onClick(option)}
                      cursor="pointer"
                      flexShrink={0}
                      mb="2"
                      mr="2"
                    >
                      <TagLabel>{option.label}</TagLabel>

                      <TagCloseButton
                        onMouseDown={(e) => handleOnTagRemove(e, option)}
                      />
                    </Tag>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Box>
          </Portal>
        </Popover>
      </Box>
    );
  };

  return (
    <OverflowCollapse
      data={selectedOptions.sort((a, b) => a.label.length - b.label.length)}
      renderItem={renderItem}
      renderRest={renderRest}
      suffix={suffix}
      maxCount="responsive"
    />
  );
};

export default SearchInputTagList;
