import {
  QueryObserverResult,
  UseMutateAsyncFunction,
  useMutation,
} from 'react-query';
import { parse, ParsedQuery } from 'query-string';
import { useState } from 'react';
import { useDistribution } from '../api/get/getDistribution';
import { useUser } from '../api/get/getUser';
import { useUserData } from '../api/get/getUserData';
import { useUserInfo } from '../api/get/getUserInfo';
import { isCampaignsUrl, isSocialLoginUrl } from '../constants/SocialAuth';
import {
  changeDistributionByBrokerId,
  login,
  logout,
  refreshToken,
} from '../classes/WgAuthState';
import useIsAccessTokenExpired from './useIsAccessTokenExpired';
import useIsAuthorized from './useIsAuthorized';
import { User } from '../types/User';
import { ChangeBrokerProps, Distribution } from '../types/Distribution';
import { UserData } from '../types/UserData';
import { UserInfo } from '../types/UserInfo';
import { getJwtToken } from '../utils/jwt';
import { Campaign } from '../../../../../../types/Campaigns';
import { useCurrentCampaign } from '../api/get/getCampaign';
import {
  isAuthenticatingViaLoginToken,
  isReturningFromSSO,
  isReturningFromSSOError,
} from '../utils/sso';
import { JwtData } from '../types/OAuth';
import { useBroker } from '../api/get/getBroker';
import useBrokerId from '../../../../../common/src/hooks/query-params/useBrokerId';

export type UserRole = 'PUBLIC' | 'SOCIAL' | 'BROKER';

export interface UseAuth {
  jwtData?: JwtData;
  /** User data */
  user?: User;
  /** Refetch user date */
  refetchUser: () => Promise<QueryObserverResult<User, unknown>>;

  /** Distribution data */
  distribution: Distribution;
  refetchDistribution: () => Promise<
    QueryObserverResult<Distribution, unknown>
  >;

  /** Campaign data */
  campaign?: Campaign;
  refetchCampaign: () => Promise<QueryObserverResult<Campaign, unknown>>;

  /** Other user data */
  ssoData: {
    data?: UserData;
    refetchUserData: () => Promise<QueryObserverResult<UserData, unknown>>;
    info?: UserInfo;
    refetchUserInfo: () => Promise<QueryObserverResult<UserInfo, unknown>>;
  };

  /** Function to logion the user */
  login: UseMutateAsyncFunction<unknown, unknown, string | undefined>;
  /** Function to logout the user */
  logout: UseMutateAsyncFunction;
  /** Refreshes the user token */
  refreshToken: UseMutateAsyncFunction;
  /** Is access token expired */
  isAccessTokenExpired: boolean;
  /** Is logged in */
  isAuthorized: boolean;
  /** Is logging in */
  isAuthorizing: boolean;
  /** Is all social user related data fetched  */
  isAllSocialUserDataFetched: boolean;
  /** Is all data related to a `converstions.wegroup.be` fetched */
  isAllConversationsDataFetched: boolean;
  /** Is all user related data fetched  */
  isAllBrokerDataFetched: boolean;
  /** User role */
  role: UserRole;
  /** Indicates if it returned from SSO with an error */
  isReturningFromSSOError: boolean;
  /** Indicates wether the user is returning from SSO */
  isReturningFromSSO: boolean;
  /** Indicates whether the url has a login token */
  isAuthenticatingViaLoginToken: boolean;
  /** Changes the current broker for the user */
  changeBroker: UseMutateAsyncFunction<unknown, unknown, ChangeBrokerProps>;
  /** Indicates whether the user is changing the broker */
  isChangingBroker: boolean;
  /** Url params from SSO server */
  authParams: ParsedQuery<string>;
  /** Indicates if any user data call might have failed */
  isError?: boolean;
}

/**
 * Do not render the application while `isAllUserDataFetched` is false
 */

export const useAuth = (): UseAuth => {
  const params = parse(window.location.search);
  const brokerId = useBrokerId();

  const isAccessTokenExpired = useIsAccessTokenExpired();
  const isAuthorized = useIsAuthorized();

  const [isAuthorizing, setIsAuthorizing] = useState<boolean>(false);
  const [isChangingBroker, setIsChangingBroker] = useState<boolean>(false);

  const onError = (error: Error) => {
    if (error.message === 'RESOURCE_NOT_FOUND') logout();
  };
  const userQuery = useUser(onError);
  const userInfoQuery = useUserInfo();
  const userDataQuery = useUserData();
  const distributionQuery = useDistribution(onError);
  const campaignQuery = useCurrentCampaign();
  const brokerQuery = useBroker(
    campaignQuery.data?.assigned_to ||
      campaignQuery.data?.created_by ||
      brokerId,
  );

  const clearData = () => {
    userQuery.remove();
    userInfoQuery.remove();
    userDataQuery.remove();
    distributionQuery.remove();
  };

  const loginMutation = useMutation({
    mutationFn: (redirectUri?: string) => login(redirectUri),
    onMutate: () => {
      setIsAuthorizing(true);
    },
    onError: () => {
      setIsAuthorizing(false);
    },
  });

  const refreshTokenMutation = useMutation({
    mutationFn: refreshToken,
  });

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSettled: clearData,
  });

  const changeBroker = useMutation({
    mutationFn: (props: ChangeBrokerProps) =>
      changeDistributionByBrokerId(props),
    onMutate: () => {
      setIsChangingBroker(true);
    },
    onError: () => {
      setIsChangingBroker(false);
    },
  });

  const getUserRole = (): UserRole => {
    const { data } = userQuery;

    if (data) {
      return 'BROKER';
    } else if (isAuthorized && isSocialLoginUrl) return 'SOCIAL';

    return 'PUBLIC';
  };

  const getIsReturningFromSSO = () => {
    return isReturningFromSSO();
  };

  const getIsReturningFromSSOError = () => {
    return isReturningFromSSOError();
  };

  const getIsAuthenticatingViaLoginToken = () => {
    return isAuthenticatingViaLoginToken();
  };

  return {
    jwtData: getJwtToken(),
    user: isSocialLoginUrl ? brokerQuery.data : userQuery.data,
    refetchUser: userQuery.refetch,
    distribution: distributionQuery.data!,
    refetchDistribution: distributionQuery.refetch,
    campaign: campaignQuery.data,
    refetchCampaign: campaignQuery.refetch,
    ssoData: {
      data: userDataQuery.data,
      refetchUserData: userDataQuery.refetch,
      info: userInfoQuery.data,
      refetchUserInfo: userInfoQuery.refetch,
    },
    login: loginMutation.mutateAsync,
    logout: logoutMutation.mutateAsync,
    refreshToken: refreshTokenMutation.mutateAsync,
    isAuthorizing,
    isAuthorized,
    isAccessTokenExpired,
    isAllSocialUserDataFetched: Boolean(
      distributionQuery.data &&
        distributionQuery.isSuccess &&
        (isCampaignsUrl ? campaignQuery.data && campaignQuery.isSuccess : true),
    ),
    isAllConversationsDataFetched: Boolean(
      distributionQuery.data &&
        distributionQuery.isSuccess &&
        brokerQuery.data &&
        brokerQuery.isSuccess,
    ),
    isAllBrokerDataFetched: Boolean(
      userQuery.data &&
        userInfoQuery.data &&
        userDataQuery.data &&
        distributionQuery.data &&
        userQuery.isSuccess &&
        userInfoQuery.isSuccess &&
        userDataQuery.isSuccess &&
        distributionQuery.isSuccess,
    ),
    role: getUserRole(),
    isReturningFromSSOError: getIsReturningFromSSOError(),
    isReturningFromSSO: getIsReturningFromSSO(),
    isAuthenticatingViaLoginToken: getIsAuthenticatingViaLoginToken(),
    changeBroker: changeBroker.mutateAsync,
    isChangingBroker: isChangingBroker,
    authParams: params,
    isError:
      userQuery.isError ||
      userInfoQuery.isError ||
      userDataQuery.isError ||
      distributionQuery.isError,
  };
};

export default useAuth;
