import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskAnalysisInsuranceCross: React.FC = (props) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.819 33.147l-2.837 2.85a34.998 34.998 0 007.46 6.756 2.72 2.72 0 003.037 0 35.003 35.003 0 007.42-6.706.916.916 0 01-.036-.033l-2.808-2.808a30.998 30.998 0 01-6.094 5.733 30.998 30.998 0 01-6.142-5.792zm15.538-4.94l2.944 2.943.157-.277a35 35 0 004.46-15.436c.058-1.164-.634-2.222-1.693-2.71l-.906-.416a.996.996 0 01-.255.438l-2.625 2.639 1.433.66a31 31 0 01-3.515 12.159zM12.754 31.38a35 35 0 01-4.75-15.942c-.058-1.165.633-2.223 1.692-2.71l1.063-.49c.044.162.13.315.258.443l2.63 2.63-1.598.737a31 31 0 003.642 12.396c-.04.029-.077.061-.112.097l-2.825 2.839zm6.018-18.429l-3.015-3.015 7.949-3.66a3 3 0 012.51 0l8.066 3.714-3.005 3.02-6.316-2.909-6.189 2.85z"
        clipRule="evenodd"
      ></path>
      <rect
        width="3.132"
        height="31.098"
        x="12.544"
        y="12.632"
        fill="currentColor"
        rx="1.566"
        transform="rotate(-45 12.544 12.632)"
      ></rect>
      <rect
        width="3.132"
        height="31.706"
        x="15.215"
        y="35.138"
        fill="currentColor"
        rx="1.566"
        transform="rotate(-135 15.215 35.138)"
      ></rect>
    </Icon>
  );
};

export default GeneralRiskAnalysisInsuranceCross;
