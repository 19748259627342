import React, { useEffect } from 'react';
import { Box, HStack, Icon, Image, Text, VStack } from '@wegroup/design-system';
import ErrorPage from '../../../../common/src/components/ErrorPage';
import LoadingScreen from '../../../../common/src/components/LoadingScreen';
import LoadingSpinner from '../../../../common/src/components/LoadingSpinner';
import { useCompleteConversation } from '../../../../conversations/src/features/detail/shared/api/completeConversation';
import { useConversation } from '../../../../conversations/src/features/detail/shared/api/getConversationById';
import useSessionId from '../../../../flows/src/hooks/useSessionId';
import FinalizeTextBlock from '../../features/finalize/FinalizeTextBlock';
import { useTranslation } from 'react-i18next';
import { ConversationStatus } from '../../../../conversations/src/features/overview/types/OverviewTypes';
import OfferPaymentList from '../../features/finalize/OfferPaymentList';
import NewFlowerContainer from './NewFlowerContainer';
import useQueryParams from '../../../../common/src/hooks/useQueryParams';
import i18n, { useLocale } from '../../../../../i18n';
import AskaRejectedBanner from './AskaRejectedBanner';
import AskaPendingBanner from './AskaPendingBanner';
import AskaFailedBanner from './AskaFailedBanner';
import AskaCanceledBanner from './AskaCanceledBanner';

const AskaFinalize: React.FC = () => {
  const { t } = useTranslation();
  const { userLang } = useLocale();
  const queryParams = useQueryParams();
  const status = queryParams.get('status');
  const sessionId = useSessionId();
  const { mutate, data, isLoading, isIdle, isError } = useCompleteConversation(
    sessionId,
    false,
  );
  const { data: conversation, isLoading: isLoadingConversation } =
    useConversation(sessionId);

  useEffect(() => {
    if (conversation?.language) {
      i18n.changeLanguage(conversation?.language);
    }
  }, [conversation?.language]);

  useEffect(() => {
    if (
      isIdle &&
      conversation &&
      conversation.status !== ConversationStatus.COMPLETED
    )
      mutate(undefined);
  }, [conversation]);

  if (isError) return <ErrorPage />;

  if (
    isLoading ||
    (!data && conversation?.status !== ConversationStatus.COMPLETED)
  )
    return <LoadingScreen />;

  if (!conversation || isLoading || isLoadingConversation)
    return <LoadingSpinner />;

  return (
    <NewFlowerContainer
      p={{ base: '4', tablet: '10' }}
      pb={{ base: '16 !important', tabletS: '24 !important' }}
      withIllustrations={false}
    >
      <VStack align="stretch" spacing="6">
        <VStack align="stretch" spacing="10">
          <div>
            <FinalizeTextBlock title={t('FLOWS_ASKA_FINALIZE_TITLE')} />
            {status ? (
              status === 'pending' ? (
                <AskaPendingBanner isShown={true} />
              ) : status === 'rejected' ? (
                <AskaRejectedBanner isShown={true} />
              ) : status === 'cancelled' ? (
                <AskaCanceledBanner isShown={true} />
              ) : status === 'failed' ? (
                <AskaFailedBanner isShown={true} />
              ) : null
            ) : null}
          </div>
          {isError && (
            <HStack>
              <Icon name="GeneralStatusWarningTriangle" color="danger.main" />
              <Text color="danger.main">{t('Something went wrong')}</Text>
            </HStack>
          )}
        </VStack>
        <VStack spacing="14" align="flex-start">
          <OfferPaymentList
            offerRevisions={conversation.offer_revisions || []}
            shouldUpdate={!!status}
          />
        </VStack>
      </VStack>
      <Box
        w="100%"
        maxW="lg"
        ml="auto"
        position="absolute"
        right="0"
        bottom="0"
      >
        <Image
          alt="sentoo-banner"
          src={`https://files.wegroup.be/payment/sentoo-badge-big-grey-${userLang}.png`}
          fallbackSrc="https://files.wegroup.be/payment/sentoo-badge-big-grey-en.png"
        />
      </Box>
    </NewFlowerContainer>
  );
};

export default AskaFinalize;
