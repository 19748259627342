import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Icon,
  Checkbox,
  VStack,
  Text,
} from '@wegroup/design-system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GrayTextBox from '../../../../company-scan/components/GrayTextBox';
import useAskaFinalizeStore from '../../store/zustand/askaFinalizeStore';

interface Props {
  isOpen: boolean;
  isDefaultChecked?: boolean;
  /** Link to the terms */
  termsUrl?: string;
  onClose: () => void;
  onAgree: () => void;
}

const AskaAcknowledgementModal: React.FC<Props> = ({
  isOpen,
  isDefaultChecked = false,
  onClose,
  onAgree,
  termsUrl,
}) => {
  const { t } = useTranslation();
  const [acknowledgments, setAcknowledgments] = useState({
    1: isDefaultChecked,
    2: isDefaultChecked,
  });
  const hasAcceptedAll = Object.values(acknowledgments).every((val) => val);
  const setAcceptedAcknowledgments = useAskaFinalizeStore(
    (state) => state.setAcceptedAcknowledgments,
  );
  const handleDecline = () => {
    setAcceptedAcknowledgments(false);
    onClose();
  };

  const handleContinue = () => {
    setAcceptedAcknowledgments(true);
    onAgree();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('TOOLKIT_INSURANCE-TERMS-BOT_ACKNOWLEDGEMENT-MODAL_TITLE')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{
            p: {
              marginBottom: '2',
            },
            ul: {
              listStyleType: 'circle',
              paddingLeft: '4',
            },
            li: {
              marginBottom: '0.5',
            },
          }}
        >
          <GrayTextBox title={t('ASKA_ACKNOWLEDGEMENT_TITLE')} pt="10">
            <VStack spacing="3" align="stretch">
              <HStack spacing="3" align="start">
                <Checkbox
                  pt="0.5"
                  id="acknowledgment_1"
                  isChecked={acknowledgments[1]}
                  onChange={() =>
                    setAcknowledgments((prev) => ({ ...prev, 1: !prev[1] }))
                  }
                />
                <Text
                  as="label"
                  htmlFor="acknowledgment_1"
                  dangerouslySetInnerHTML={{
                    __html: t('ASKA_ACKNOWLEDGEMENT_DESCRIPTION_1'),
                  }}
                />
              </HStack>
              <HStack spacing="3" align="start">
                <Checkbox
                  pt="0.5"
                  id="acknowledgment_2"
                  isChecked={acknowledgments[2]}
                  onChange={() =>
                    setAcknowledgments((prev) => ({ ...prev, 2: !prev[2] }))
                  }
                />
                <Text
                  as="label"
                  htmlFor="acknowledgment_2"
                  dangerouslySetInnerHTML={{
                    __html: t('ASKA_ACKNOWLEDGEMENT_DESCRIPTION_2', {
                      href: termsUrl,
                    }),
                  }}
                />
              </HStack>
            </VStack>
          </GrayTextBox>
        </ModalBody>
        <ModalFooter as={HStack} spacing={3}>
          <Button variant="grayedGhost" onClick={handleDecline}>
            {t('CANCEL')}
          </Button>
          <Button
            onClick={handleContinue}
            rightIcon={<Icon name="GeneralActionsArrowRight" />}
            isDisabled={!hasAcceptedAll}
          >
            {t('CONTINUE')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AskaAcknowledgementModal;
