import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Card } from '@wegroup/design-system';
import Plant1 from '../../assets/Plant1';
import Plant2 from '../../assets/Plant2';
import Plant3 from '../../assets/Plant3';
import Plant4 from '../../assets/Plant4';
import {
  GENERAL_PAGE_PX,
  GENERAL_PAGE_PY,
} from '../../../../common/src/components/GeneralPageLayout';

interface Props {
  withIllustrations?: boolean;
}

const NewFlowerContainer: React.FC<PropsWithChildren<BoxProps & Props>> = ({
  children,
  withIllustrations = true,
  ...props
}) => {
  return (
    <Box
      h="100%"
      w="100vw"
      overflowX="hidden"
      overflowY="auto"
      px={{ ...GENERAL_PAGE_PX, base: 0, tablet: GENERAL_PAGE_PX.base }}
      py={{ ...GENERAL_PAGE_PY, base: 0, tablet: GENERAL_PAGE_PY.base }}
      pt={{ tablet: '5vh', laptopL: '10vh' }}
    >
      <Box margin="0 auto" position="relative" maxW="6xl">
        <Card bg="white" w="100%" {...props} zIndex="2">
          {children}
        </Card>
        {withIllustrations && <Illustrations />}
      </Box>
    </Box>
  );
};

const Illustrations: React.FC = () => {
  const defaultPlantProps: BoxProps = {
    position: 'absolute',
    bottom: 0,
    transform: 'auto',
  };

  return (
    <Box display={{ base: 'none', md: 'block' }}>
      <Box
        left="0"
        translateX="-90%"
        translateY="-40%"
        color="secondary.600"
        {...defaultPlantProps}
      >
        <Plant1 color="currentColor" />
      </Box>
      <Box
        left="0"
        translateX="-90%"
        translateY="-40%"
        color="secondary.700"
        {...defaultPlantProps}
      >
        <Plant2 color="currentColor" />
      </Box>
      <Box
        right="0"
        translateX="90%"
        translateY="-60%"
        color="primary.500"
        {...defaultPlantProps}
      >
        <Plant3 color="currentColor" />
      </Box>
      <Box
        right="0"
        translateX="90%"
        translateY="-40%"
        color="secondary.700"
        {...defaultPlantProps}
      >
        <Plant4 color="currentColor" />
      </Box>
    </Box>
  );
};

export default NewFlowerContainer;
