import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import useFeatureFlags from '../../../../app/src/hooks/useFeatureFlags';
import { request } from '../../legacy/services/httpSessionService';
import { useGlobalSelector } from '../../utils/globalVariables';
import useAuth from '../../../../app/src/features/authentication/hooks/useAuth';
import { getManifestFromQCache } from '../../../../app/src/features/new-version/api/getManifest';
import useTracking from './useTracking';

interface HubspotChat {
  isHsApiReady: boolean;
}

interface HubspotOnConversationStarted {
  conversation: {
    conversationId: string;
  };
}

const filteredBids = [
  // E2E staging bid
  'a4df0a39-dec1-4b97-a8b6-8624461fabb5',
  // E2E prod bid
  'dd3ce910-04a4-4c68-a569-8cbb8677a6f9',
];

const useInitHubspotChat = (): HubspotChat => {
  const [isHsApiReady, setIsHsApiReady] = useState(false);
  const { isSupportEnabled } = useFeatureFlags();
  const { trackEvent } = useTracking();

  const { isAuthorized } = useAuth();

  const { data: hsToken } = useHubspotToken();
  const { user } = useAuth();

  const sessionRecordingUrl = useGlobalSelector(
    (state) => state.generalData.sessionRecordingUrl,
  );
  const sessionRecordingUrlRef = useRef(sessionRecordingUrl);
  const manifestVersion = getManifestFromQCache()?.version;
  const manifestVersionRef = useRef(manifestVersion);

  /**
   * Will send out an event to the lambda to add a comment to the conversation that contains:
   * - The logrocket url
   * - The current page
   */
  const handleConversationStarted = async (
    payload: HubspotOnConversationStarted,
  ) => {
    try {
      trackEvent('User started Hubspot Conversation', {
        isSupportEnabled,
        hsToken,
        user: JSON.stringify(user),
        isAuthorized,
        isHsApiReady: !isHsApiReady,
        filteredBids: user?.id ? !filteredBids.includes(user.id) : '',
        HubSpotConversations: JSON.stringify(window.HubSpotConversations),
      });

      await fetch('https://logrockeths-1-c6366858.deta.app/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          conversation_id: payload.conversation.conversationId,
          app_version: manifestVersionRef.current || '',
          logrocket_url: sessionRecordingUrlRef.current || '',
          posthog_url: sessionStorage.getItem('posthog_session_url') || '',
          href: window.location.href,
        }),
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    sessionRecordingUrlRef.current = sessionRecordingUrl;
  }, [sessionRecordingUrl]);

  useEffect(() => {
    manifestVersionRef.current = manifestVersion;
  }, [manifestVersion]);

  useEffect(() => {
    /**
     * Lots of different checks currently, I don't really know a way to improve this.
     * Some check will be removed with the routing rework but right now these should remain in place
     */
    if (
      isSupportEnabled &&
      hsToken &&
      user &&
      isAuthorized &&
      !isHsApiReady &&
      !filteredBids.includes(user.id) &&
      window.HubSpotConversations
    ) {
      // Add event listener.
      window.hsConversationsOnReady = [
        () => {
          setIsHsApiReady(true);
          trackEvent('Hubspot Conversations was initialized');
        },
      ];

      window.hsConversationsSettings = {
        identificationEmail: user?.email,
        identificationToken: hsToken,
      };

      window.HubSpotConversations.on(
        'conversationStarted',
        handleConversationStarted,
      );

      window.HubSpotConversations?.widget.load();
    }

    return () => {
      window.hsConversationsOnReady = [];
    };
  }, [
    hsToken,
    user,
    isAuthorized,
    isHsApiReady,
    isSupportEnabled,
    window.HubSpotConversations,
  ]);

  return {
    isHsApiReady,
  };
};

export default useInitHubspotChat;

interface HubspotTokenResponse {
  token: string;
}

const getHubspotToken = async (): Promise<[HubspotTokenResponse, number]> => {
  const path = `v1/api/hubspot-visitor-identification-token`;
  const [resp, status] = await request({ path });

  return [resp, status];
};

const useHubspotToken = () => {
  const { isAuthorized } = useAuth();
  const { isSupportEnabled } = useFeatureFlags();

  return useQuery(
    ['hubspot-token'],
    async () => {
      const [resp, status] = await getHubspotToken();

      if (status >= 400) throw new Error();

      return resp.token;
    },
    {
      enabled: isAuthorized && isSupportEnabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};
