import React from 'react';
import { VStack, Text, UnorderedList, ListItem } from '@wegroup/design-system';
import { Funeral } from '../../../../../types/SMT';
import { BasicRiskObjectData } from '../../../../parties/src/features/risk-objects/api/getAllRiskObjectByParty';
import { Party } from '../../../../parties/src/types';
import { getPartyName } from '../../../../parties/src/utils/partyUtils';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

interface Props {
  group: Funeral & BasicRiskObjectData;
}

const OfferPaymentPartyGroup: React.FC<Props> = ({ group }) => {
  const { t } = useTranslation();

  return (
    <VStack align="stretch" spacing="2">
      <Text textStyle="normalBody">{t('ASKA_OFFER_RISK_TITLE')}</Text>
      <UnorderedList pl="4">
        {group.parties?.map((party) => (
          <ListItem key={party.id}>
            {getPartyName(party as Partial<Party>)}{' '}
            <Text as="span" textStyle="extraSmallBody" color="gray.500">
              ({party?.birth && format(parseISO(party.birth), 'dd/MM/yyyy')})
            </Text>
          </ListItem>
        ))}
      </UnorderedList>
    </VStack>
  );
};

export default OfferPaymentPartyGroup;
