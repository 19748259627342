import React from 'react';
import IllustrationFamilySingle from '../../../assets/illustrations-rounded/IllustrationFamilySingle';
import IllustrationFamilyWithChildren from '../../../assets/illustrations-rounded/IllustrationFamilyWithChildren';
import IllustrationFamilySingleWithChildren from '../../../assets/illustrations-rounded/IllustrationFamilySingleWithChildren';
import IllustrationFurniture from '../../../assets/illustrations-rounded/IllustrationFurniture';
import IllustrationPlaceholder from '../../../assets/illustrations-rounded/IllustrationPlaceholder';
import IllustrationFamilyCouple from '../../../assets/illustrations-rounded/IllustrationFamilyCouple';
import IllustrationFamilyCar from '../../../assets/illustrations-rounded/IllustrationFamilyCar';
import IllustrationOldtimer from '../../../assets/illustrations-rounded/IllustrationOldtimer';
import IllustrationMotorcycle from '../../../assets/illustrations-rounded/IllustrationMotorcycle';
import IllustrationBoat from '../../../assets/illustrations-rounded/IllustrationBoat';
import IllustrationCamper from '../../../assets/illustrations-rounded/IllustrationCamper';
import IllustrationCompanyCar from '../../../assets/illustrations-rounded/IllustrationCompanyCar';
import IllustrationMoped from '../../../assets/illustrations-rounded/IllustrationMoped';
import IllustrationElectricMoped from '../../../assets/illustrations-rounded/IllustrationElectricMoped';
import IllustrationElectricBike from '../../../assets/illustrations-rounded/IllustrationElectricBicycle';
import IllustrationPublicTransport from '../../../assets/illustrations-rounded/IllustrationPublicTransport';
import IllustrationPersonalTransport from '../../../assets/illustrations-rounded/IllustrationPersonalTransport';
import IllustrationBicycle from '../../../assets/illustrations-rounded/IllustrationBicycle';
import IllustrationDog from '../../../assets/illustrations-rounded/IllustrationDog';
import IllustrationCat from '../../../assets/illustrations-rounded/IllustrationCat';
import IllustrationOther from '../../../assets/illustrations-rounded/IllustrationOther';
import IllustrationHome from '../../../assets/illustrations-rounded/IllustrationHome';
import IllustrationMountainBike from '../../../assets/illustrations-rounded/IllustrationMountainBike';
import IllustrationRacingBike from '../../../assets/illustrations-rounded/IllustrationRacingBike';
import IllustrationMotorboat from '../../../assets/illustrations-rounded/IllustrationMotorboat';
import IllustrationSailingboat from '../../../assets/illustrations-rounded/IllustrationSailingboat';
import IllustrationCatamaran from '../../../assets/illustrations-rounded/IllustrationCatamaran';
import IllustrationBargeBoat from '../../../assets/illustrations-rounded/IllustrationBargeBoat';
import IllustrationYacht from '../../../assets/illustrations-rounded/IllustrationYacht';
import IllustrationSloopBoat from '../../../assets/illustrations-rounded/IllustrationSloopBoat';
import IllustrationRubberBoat from '../../../assets/illustrations-rounded/IllustrationRubberBoat';
import IllustrationTerritoryNlCoast from '../../../assets/illustrations-rounded/IllustrationTerritoryNlCoast';
import IllustrationTerritoryNlShallowWaters from '../../../assets/illustrations-rounded/IllustrationTerritoryNlShallowWaters';
import IllustrationTerritoryEuCoast from '../../../assets/illustrations-rounded/IllustrationTerritoryEuCoast';
import IllustrationTerritoryEuShallowWaters from '../../../assets/illustrations-rounded/IllustrationTerritoryEuShallowWaters';
import IllustrationResidenceAppartment from '../../../assets/illustrations-rounded/IllustrationResidenceAppartment';
import IllustrationResidenceVilla from '../../../assets/illustrations-rounded/IllustrationResidenceVilla';
import IllustrationResidenceTownHouse from '../../../assets/illustrations-rounded/IllustrationResidenceTownHouse';
import IllustrationResidenceFarmHouse from '../../../assets/illustrations-rounded/IllustrationResidenceFarmHouse';
import IllustrationResidenceCountryHouse from '../../../assets/illustrations-rounded/IllustrationResidenceCountryHouse';
import IllustrationRoofTiles from '../../../assets/illustrations-rounded/IllustrationRoofTiles';
import IllustrationRoofFlat from '../../../assets/illustrations-rounded/IllustrationRoofFlat';
import IllustrationRoofThatchedTraditional from '../../../assets/illustrations-rounded/IllustrationRoofThatchedTraditional';
import IllustrationRoofThatchedFireproof from '../../../assets/illustrations-rounded/IllustrationRoofThatchedFireproof';
import IllustrationRoofThatchedScrewed from '../../../assets/illustrations-rounded/IllustrationRoofThatchedScrewed';
import IllustrationPaintings from '../../../assets/illustrations-rounded/IllustrationPaintings';
import IllustrationSculptures from '../../../assets/illustrations-rounded/IllustrationSculptures';
import IllustrationJewellery from '../../../assets/illustrations-rounded/IllustrationJewellery';
import IllustrationDiamonds from '../../../assets/illustrations-rounded/IllustrationDiamonds';
import IllustrationSilverware from '../../../assets/illustrations-rounded/IllustrationSilverware';
import IllustrationGoldware from '../../../assets/illustrations-rounded/IllustrationGoldware';
import IllustrationCarpets from '../../../assets/illustrations-rounded/IllustrationCarpets';
import IllustrationSmokeDetectors from '../../../assets/illustrations-rounded/IllustrationSmokeDetectors';
import IllustrationFireExtinguishers from '../../../assets/illustrations-rounded/IllustrationFireExtinguishers';
import IllustrationFireBlankets from '../../../assets/illustrations-rounded/IllustrationFireBlankets';
import IllustrationInsuranceCarWA from '../../../assets/illustrations-rounded/IllustrationInsuranceCarWA';
import IllustrationInsuranceCarCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceCarCasco';
import IllustrationInsuranceCarFullCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceCarFullCasco';
import IllustrationInsuranceCarSVI from '../../../assets/illustrations-rounded/IllustrationInsuranceCarSVI';
import IllustrationInsuranceOldtimerWA from '../../../assets/illustrations-rounded/IllustrationInsuranceOldtimerWA';
import IllustrationInsuranceOldtimerCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceOldtimerCasco';
import IllustrationInsuranceOldtimerFullCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceOldtimerFullCasco';
import IllustrationInsuranceOldtimerSVI from '../../../assets/illustrations-rounded/IllustrationInsuranceOldtimerSVI';
import IllustrationInsuranceMotoWA from '../../../assets/illustrations-rounded/IllustrationInsuranceMotoWA';
import IllustrationInsuranceMotoCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceMotoCasco';
import IllustrationInsuranceMotoFullCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceMotoFullCasco';
import IllustrationInsuranceMotoSVI from '../../../assets/illustrations-rounded/IllustrationInsuranceMotoSVI';
import IllustrationInsuranceMopedWA from '../../../assets/illustrations-rounded/IllustrationInsuranceMopedWA';
import IllustrationInsuranceMopedFullCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceMopedFullCasco';
import IllustrationInsuranceMopedCasco from '../../../assets/illustrations-rounded/IllustrationInsuranceMopedCasco';
import IllustrationInsuranceHomeContent from '../../../assets/illustrations-rounded/IllustrationInsuranceHomeContent';
import IllustrationInsuranceValuables from '../../../assets/illustrations-rounded/IllustrationInsuranceValuables';
import IllustrationInsuranceArt from '../../../assets/illustrations-rounded/IllustrationInsuranceArt';
import IllustrationInsuranceLiability from '../../../assets/illustrations-rounded/IllustrationInsuranceLiability';
import IllustrationInsuranceTravel from '../../../assets/illustrations-rounded/IllustrationInsuranceTravel';
import IllustrationInsuranceLegalAssistance from '../../../assets/illustrations-rounded/IllustrationInsuranceLegalAssistance';
import IllustrationInsuranceFuneral from '../../../assets/illustrations-rounded/IllustrationInsuranceFuneral';
import IllustrationInsuranceCyber from '../../../assets/illustrations-rounded/IllustrationInsuranceCyber';
import IllustrationPets from '../../../assets/illustrations-rounded/IllustrationPets';
import IllustrationDigitalSecurity from '../../../assets/illustrations-rounded/IllustrationDigitalSecurity';
import IllustrationMovingHome from '../../../assets/illustrations-rounded/IllustrationMovingHome';
import IllustrationRenovations from '../../../assets/illustrations-rounded/IllustrationRenovations';
import IllustrationMarage from '../../../assets/illustrations-rounded/IllustrationMarage';
import IllustrationKids from '../../../assets/illustrations-rounded/IllustrationKids';
import IllustrationAntiques from '../../../assets/illustrations-rounded/IllustrationAntiques';
import IllustrationCollections from '../../../assets/illustrations-rounded/IllustrationCollections';
import IllustrationSecurities from '../../../assets/illustrations-rounded/IllustrationSecurities';
import IllustrationHolderTypeCoTenant from '../../../assets/illustrations-rounded/IllustrationHolderTypeCoTenant';
import IllustrationHolderTypeLandlord from '../../../assets/illustrations-rounded/IllustrationHolderTypeLandlord';
import IllustrationHolderTypeTenant from '../../../assets/illustrations-rounded/IllustrationHolderTypeTenant';
import IllustrationHolderTypeOwner from '../../../assets/illustrations-rounded/IllustrationHolderTypeOwner';
import IllustrationTravelsAYearOnce from '../../../assets/illustrations-rounded/IllustrationTravelsAYearOnce';
import IllustrationTravelsAYearTwice from '../../../assets/illustrations-rounded/IllustrationTravelsAYearTwice';
import IllustrationTravelsAYearMore from '../../../assets/illustrations-rounded/IllustrationTravelsAYearMore';
import IllustrationResidenceHalfOpen from '../../../assets/illustrations-rounded/IllustrationResidenceHalfOpen';
import IllustrationResidenceClosed from '../../../assets/illustrations-rounded/IllustrationResidenceClosed';
import IllustrationTerritoryWorld from '../../../assets/illustrations-rounded/IllustrationTerritoryWorld';
import IllustrationTerritoryCaribbean from '../../../assets/illustrations-rounded/IllustrationTerritoryCaribbean';
import IllustrationTerritoryAsia from '../../../assets/illustrations-rounded/IllustrationTerritoryAsia';
import IllustrationTerritoryMediterraneanSea from '../../../assets/illustrations-rounded/IllustrationTerritoryMediterraneanSea';
import IllustrationTerritoryExtraBigSquare from '../../../assets/illustrations-rounded/IllustrationTerritoryExtraBigSquare';
import IllustrationTerritoryBigSquare from '../../../assets/illustrations-rounded/IllustrationTerritoryBigSquare';
import IllustrationMail from '../../../assets/illustrations-rounded/IllustrationMail';
import IllustrationGyropode from '../../../assets/illustrations-rounded/IllustrationGyropode';
import IllustrationHoverBoard from '../../../assets/illustrations-rounded/IllustrationHoverBoard';
import IllustrationElectricSkateboard from '../../../assets/illustrations-rounded/IllustrationElectricSkateboard';
import IllustrationEStep from '../../../assets/illustrations-rounded/IllustrationEStep';
import IllustrationMonowheel from '../../../assets/illustrations-rounded/IllustrationMonowheel';
import IllustrationSegway from '../../../assets/illustrations-rounded/IllustrationSegway';
import IllustrationBicycleAllowance from '../../../assets/illustrations-rounded/IllustrationBicycleAllowance';
import IllustrationMobilityBudget from '../../../assets/illustrations-rounded/IllustrationMobilityBudget';
import IllustrationMobilityAllowance from '../../../assets/illustrations-rounded/IllustrationMobilityAllowance';
import IllustrationTelephoneSubscription from '../../../assets/illustrations-rounded/IllustrationTelephoneSubscription';
import IllustrationInternetSubscription from '../../../assets/illustrations-rounded/IllustrationInternetSubscription';
import IllustrationTablet from '../../../assets/illustrations-rounded/IllustrationTablet';
import IllustrationLaptop from '../../../assets/illustrations-rounded/IllustrationLaptop';
import IllustrationSmartphone from '../../../assets/illustrations-rounded/IllustrationSmartphone';
import IllustrationEcoVouchers from '../../../assets/illustrations-rounded/IllustrationEcoVouchers';
import IllustrationMealVouchers from '../../../assets/illustrations-rounded/IllustrationMealVouchers';
import IllustrationHospitalisationInsurance from '../../../assets/illustrations-rounded/IllustrationHospitalisationInsurance';
import IllustrationGroupInsurance from '../../../assets/illustrations-rounded/IllustrationGroupInsurance';
import IllustrationResidenceSemiDetached from '../../../assets/illustrations-rounded/IllustrationResidenceSemiDetached';
import IllustrationDivorce from '../../../assets/illustrations-rounded/IllustrationDivorce';
import IllustrationRenovateSellProperties from '../../../assets/illustrations-rounded/IllustrationRenovateSellProperties';
import IllustrationRentalProperties from '../../../assets/illustrations-rounded/IllustrationRentalProperties';
import IllustrationOwnerSecondProperty from '../../../assets/illustrations-rounded/IllustrationOwnerSecondProperty';
import IllustrationRentMultipleProperties from '../../../assets/illustrations-rounded/IllustrationRentMultipleProperties';
import IllustrationBicycleCargo from '../../../assets/illustrations-rounded/IllustrationBicycleCargo';
import IllustrationBicycleHybrid from '../../../assets/illustrations-rounded/IllustrationBicycleHybrid';
import IllustrationBicycleTandem from '../../../assets/illustrations-rounded/IllustrationBicycleTandem';
import IllustrationBicycleTrekking from '../../../assets/illustrations-rounded/IllustrationBicycleTrekking';
import IllustrationBicycleFolding from '../../../assets/illustrations-rounded/IllustrationBicycleFolding';
import IllustrationBicycleRecumbent from '../../../assets/illustrations-rounded/IllustrationBicycleRecumbent';
import IllustrationBicycleAllTerrain from '../../../assets/illustrations-rounded/IllustrationBicycleAllTerrain';
import IllustrationBicycleChildren from '../../../assets/illustrations-rounded/IllustrationBicycleChildren';
import IllustrationBicycleElectricMountain from '../../../assets/illustrations-rounded/IllustrationBicycleElectricMountain';
import IllustrationBicycleElectricCargo from '../../../assets/illustrations-rounded/IllustrationBicycleElectricCargo';
import IllustrationBicycleElectricRacing from '../../../assets/illustrations-rounded/IllustrationBicycleElectricRacing';
import IllustrationBicycleSpeedPedelec from '../../../assets/illustrations-rounded/IllustrationBicycleSpeedPedelec';
import IllustrationHolderTypeOwnerResident from '../../../assets/illustrations-rounded/IllustrationHolderTypeOwnerResident';
import IllustrationHolderTypeCoOwner from '../../../assets/illustrations-rounded/IllustrationHolderTypeCoOwner';
import IllustrationHolderTypeSubTenant from '../../../assets/illustrations-rounded/IllustrationHolderTypeSubTenant';
import IllustrationHolderTypeLivingWithParents from '../../../assets/illustrations-rounded/IllustrationHolderTypeLivingWithParents';
import IllustrationElevator from '../../../assets/illustrations-rounded/IllustrationElevator';
import IllustrationSwimmingPool from '../../../assets/illustrations-rounded/IllustrationSwimmingPool';
import IllustrationHomeBusiness from '../../../assets/illustrations-rounded/IllustrationHomeBusiness';
import IllustrationBuildingListed from '../../../assets/illustrations-rounded/IllustrationBuildingListed';
import IllustrationBus from '../../../assets/illustrations-rounded/IllustrationBus';
import IllustrationRentalCar from '../../../assets/illustrations-rounded/IllustrationRentalCar';
import IllustrationCaravan from '../../../assets/illustrations-rounded/IllustrationCaravan';
import IllustrationRecreationalBoat from '../../../assets/illustrations-rounded/IllustrationRecreationalBoat';
import IllustrationSport from '../../../assets/illustrations-rounded/IllustrationSport';
import IllustrationMotorcycles from '../../../assets/illustrations-rounded/IllustrationMotorcycles';
import IllustrationManageSportAssociation from '../../../assets/illustrations-rounded/IllustrationManageSportAssociation';
import IllustrationHorse from '../../../assets/illustrations-rounded/IllustrationHorse';
import IllustrationDrones from '../../../assets/illustrations-rounded/IllustrationDrones';
import IllustrationInvestments from '../../../assets/illustrations-rounded/IllustrationInvestments';
import IllustrationRealEstate from '../../../assets/illustrations-rounded/IllustrationRealEstate';
import IllustrationMarrying from '../../../assets/illustrations-rounded/IllustrationMarrying';
import IllustrationSavings from '../../../assets/illustrations-rounded/IllustrationSavings';
import IllustrationChildren from '../../../assets/illustrations-rounded/IllustrationChildren';
import IllustrationResidentialCareCenter from '../../../assets/illustrations-rounded/IllustrationResidentialCareCenter';
import IllustrationServiceFlat from '../../../assets/illustrations-rounded/IllustrationServiceFlat';
import IllustrationHobbies from '../../../assets/illustrations-rounded/IllustrationHobbies';
import IllustrationEmigrate from '../../../assets/illustrations-rounded/IllustrationEmigrate';
import IllustrationSecondResidence from '../../../assets/illustrations-rounded/IllustrationSecondResidence';
import IllustrationHomeTheft from '../../../assets/illustrations-rounded/IllustrationHomeTheft';
import IllustrationDebtBalance from '../../../assets/illustrations-rounded/IllustrationDebtBalance';
import IllustrationPlane from '../../../assets/illustrations-rounded/IllustrationPlane';
import IllustrationFunds from '../../../assets/illustrations-rounded/IllustrationFunds';
import IllustrationBonds from '../../../assets/illustrations-rounded/IllustrationBonds';
import IllustrationCommodities from '../../../assets/illustrations-rounded/IllustrationCommodities';
import IllustrationCrypto from '../../../assets/illustrations-rounded/IllustrationCrypto';
import IllustrationStocks from '../../../assets/illustrations-rounded/IllustrationStocks';
import IllustrationCompanies from '../../../assets/illustrations-rounded/IllustrationCompanies';
import IllustrationBuildingSavings from '../../../assets/illustrations-rounded/IllustrationBuildingSavings';
import IllustrationCash from '../../../assets/illustrations-rounded/IllustrationCash';
import IllustrationChild from '../../../assets/illustrations-rounded/IllustrationChild';
import IllustrationBankAccount from '../../../assets/illustrations-rounded/IllustrationBankAccount';
import IllustrationPension from '../../../assets/illustrations-rounded/IllustrationPension';
import IllustrationFire from '../../../assets/illustrations-rounded/IllustrationFire';
import IllustrationSmallCattle from '../../../assets/illustrations-rounded/IllustrationSmallCattle';
import IllustrationProfessionalBreeder from '../../../assets/illustrations-rounded/IllustrationProfessionalBreeder';
import IllustrationTemporaryCaretaker from '../../../assets/illustrations-rounded/IllustrationTemporaryCaretaker';
import IllustrationOwnerOfPets from '../../../assets/illustrations-rounded/IllustrationOwnerOfPets';
import IllustrationTrain from '../../../assets/illustrations-rounded/IllustrationTrain';
import IllustrationHouselhold from '../../../assets/illustrations-rounded/IllustrationHouselhold';
import IllustrationGardener from '../../../assets/illustrations-rounded/IllustrationGardener';
import IllustrationBabysit from '../../../assets/illustrations-rounded/IllustrationBabysit';
import IllustrationCarBA from '../../../assets/illustrations-rounded/IllustrationCarBA';
import IllustrationCarOmnium from '../../../assets/illustrations-rounded/IllustrationCarOmnium';
import IllustrationCarMiniOmnium from '../../../assets/illustrations-rounded/IllustrationCarMiniOmnium';
import IllustrationClassicYacht from '../../../assets/illustrations-rounded/IllustrationClassicYacht';
import IllustrationJetski from '../../../assets/illustrations-rounded/IllustrationJetski';
import IllustrationAgility from '../../../assets/illustrations-rounded/IllustrationAgility';
import IllustrationGymnastic from '../../../assets/illustrations-rounded/IllustrationGymnastic';
import IllustrationAthletic from '../../../assets/illustrations-rounded/IllustrationAthletic';
import IllustrationRecreational from '../../../assets/illustrations-rounded/IllustrationRecreational';
import IllustrationExtreme from '../../../assets/illustrations-rounded/IllustrationExtreme';
import IllustrationWinter from '../../../assets/illustrations-rounded/IllustrationWinter';
import IllustrationWater from '../../../assets/illustrations-rounded/IllustrationWater';
import IllustrationRacing from '../../../assets/illustrations-rounded/IllustrationRacing';
import IllustrationKarting from '../../../assets/illustrations-rounded/IllustrationKarting';
import IllustrationCompetition from '../../../assets/illustrations-rounded/IllustrationCompetition';
import IllustrationCarTinkerer from '../../../assets/illustrations-rounded/IllustrationCarTinkerer';
import IllustrationCarCollector from '../../../assets/illustrations-rounded/IllustrationCarCollector';
import IllustrationMotorcross from '../../../assets/illustrations-rounded/IllustrationMotorcross';
import IllustrationClubMember from '../../../assets/illustrations-rounded/IllustrationClubMember';
import IllustrationSportsCar from '../../../assets/illustrations-rounded/IllustrationSportsCar';
import IllustrationMotorCollector from '../../../assets/illustrations-rounded/IllustrationMotorCollector';
import IllustrationMotoSpareTime from '../../../assets/illustrations-rounded/IllustrationMotoSpareTime';
import IllustrationAccident from '../../../assets/illustrations-rounded/IllustrationAccident';
import IllustrationWork from '../../../assets/illustrations-rounded/IllustrationWork';
import IllustrationHunting from '../../../assets/illustrations-rounded/IllustrationHunting';
import IllustrationConnections from '../../../assets/illustrations-rounded/IllustrationConnections';
import IllustrationFirePlace from '../../../assets/illustrations-rounded/IllustrationFirePlace';
import IllustrationUsufruct from '../../../assets/illustrations-rounded/IllustrationUsufruct';
import IllustrationFuneralInsurance from '../../../assets/illustrations-rounded/IllustrationFuneralInsurance';
import IllustrationInsuranceLegalTaxAssistance from '../../../assets/illustrations-rounded/IllustrationInsuranceLegalTaxAssistance';
import IllustrationRoomSizeSmall from '../../../assets/illustrations-rounded/IllustrationRoomSizeSmall';
import IllustrationRoomSizeMedium from '../../../assets/illustrations-rounded/IllustrationRoomSizeMedium';
import IllustrationRoomSizeLarge from '../../../assets/illustrations-rounded/IllustrationRoomSizeLarge';
import IllustrationCashAtHome from '../../../assets/illustrations-rounded/IllustrationCashAtHome';
import IllustrationFireProofDoor from '../../../assets/illustrations-rounded/IllustrationFireProofDoor';
import IllustrationDoubleGlass from '../../../assets/illustrations-rounded/IllustrationDoubleGlass';
import IllustrationShutters from '../../../assets/illustrations-rounded/IllustrationShutters';
import IllustrationBoatBA from '../../../assets/illustrations-rounded/IllustrationBoatBA';
import IllustrationBoatBACasco from '../../../assets/illustrations-rounded/IllustrationBoatBACasco';
import IllustrationBoatBAExtendedCasco from '../../../assets/illustrations-rounded/IllustrationBoatBAExtendedCasco';
import IllustrationCertificate from '../../../assets/illustrations-rounded/IllustrationCertificate';
import IllustrationMusicalInstruments from '../../../assets/illustrations-rounded/IllustrationMusicalInstruments';
import IllustrationAlarm from '../../../assets/illustrations-rounded/IllustrationAlarm';
import IllustrationAmountOfChildren from '../../../assets/illustrations-rounded/IllustrationAmountOfChildren';
import IllustrationTerritoryCuracao from '../../../assets/illustrations-rounded/IllustrationTerritoryCuracao';
import IllustrationTerritoryBonaire from '../../../assets/illustrations-rounded/IllustrationTerritoryBonaire';
import IllustrationTerritorySintMaarten from '../../../assets/illustrations-rounded/IllustrationTerritorySintMaarten';
import IllustrationTerritoryAruba from '../../../assets/illustrations-rounded/IllustrationTerritoryAruba';

interface Props {
  type?: IllustrationRoundedTypes | string;
  isActive?: boolean;
}

export enum IllustrationRoundedTypes {
  // Room sizes
  ROOM_SIZE_SMALL = 'ROOM_SIZE_SMALL',
  ROOM_SIZE_MEDIUM = 'ROOM_SIZE_MEDIUM',
  ROOM_SIZE_LARGE = 'ROOM_SIZE_LARGE',

  // Boat coverages
  BOAT_BA = 'BOAT_BA',
  BOAT_BA_CASCO = 'BOAT_BA_CASCO',
  BOAT_BA_EXTENDED_CASCO = 'BOAT_BA_EXTENDED_CASCO',

  CONNECTIONS = 'CONNECTIONS',
  BOAT = 'BOAT',
  CAMPER = 'CAMPER',
  COMPANY_CAR = 'COMPANY_CAR',
  CAR = 'CAR',
  ELECTRIC_MOPED = 'ELECTRIC_MOPED',
  MOPED = 'MOPED',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  PERSONAL_TRANSPORT = 'PERSONAL_TRANSPORT',
  MOTORCYCLE = 'MOTORCYCLE',
  OLDTIMER = 'OLDTIMER',
  MOBILE_ELECTRONICS = 'MOBILE_ELECTRONICS',
  CARAVAN = 'CARAVAN',
  TRAIN = 'TRAIN',
  BUS = 'BUS',
  RENTAL_CAR = 'RENTAL_CAR',
  AIRPLANE = 'AIRPLANE',
  MOBILHOME = 'MOBILHOME',
  MOTORHOME = 'MOTORHOME',
  FAMILY_CAR = 'FAMILY_CAR',
  LIFE = 'LIFE',
  FAMILY_SITUATION_COHABITING_WITHOUT_CHILDREN = 'FAMILY_SITUATION_COHABITING_WITHOUT_CHILDREN',
  FAMILY_SITUATION_COHABITING_WITH_CHILDREN = 'FAMILY_SITUATION_COHABITING_WITH_CHILDREN',
  FAMILY_SITUATION_SINGLE_WITH_CHILDREN = 'FAMILY_SITUATION_SINGLE_WITH_CHILDREN',
  FAMILY_SITUATION_SINGLE_WITHOUT_CHILDREN = 'FAMILY_SITUATION_SINGLE_WITHOUT_CHILDREN',
  FAMILY_SITUATION_COUPLE = 'FAMILY_SITUATION_COUPLE',
  FAMILY_SITUATION_COUPLE_WITH_CHILDREN = 'FAMILY_SITUATION_COUPLE_WITH_CHILDREN',
  FAMILY_SITUATION_WITH_CHILDREN = 'FAMILY_SITUATION_WITH_CHILDREN',
  FURNITURE = 'FURNITURE',
  CAT = 'CAT',
  DOG = 'DOG',
  OTHER = 'OTHER',
  // Personal transport
  GYROPODE = 'GYROPODE',
  HOVERBOARD = 'HOVERBOARD',
  ELECTRIC_SKATEBOARD = 'ELECTRIC_SKATEBOARD',
  ESTEP = 'ESTEP',
  MONOWHEEL = 'MONOWHEEL',
  SEGWAY = 'SEGWAY',
  // Bicycles
  BICYCLE = 'BICYCLE',
  BICYCLE_TYPE_ELECTRIC = 'BICYCLE_TYPE_ELECTRIC',
  BICYCLE_TYPE_EBIKE = 'BICYCLE_TYPE_EBIKE',
  BICYCLE_TYPE_EBIKE_25KMH = 'BICYCLE_TYPE_EBIKE_25KMH',
  BICYCLE_TYPE_EBIKE_45KMH = 'BICYCLE_TYPE_EBIKE_45KMH',
  BICYCLE_TYPE_ELECTRIC_LESS_THAN_45KMH = 'BICYCLE_TYPE_ELECTRIC_LESS_THAN_45KMH',
  BICYCLE_TYPE_ELECTRIC_LESS_THAN_25KMH = 'BICYCLE_TYPE_ELECTRIC_LESS_THAN_25KMH',
  BICYCLE_TYPE_REGULAR = 'BICYCLE_TYPE_REGULAR',
  BICYCLE_TYPE_MOUNTAIN = 'BICYCLE_TYPE_MOUNTAIN',
  BICYCLE_TYPE_OTHER = 'BICYCLE_TYPE_OTHER',
  BICYCLE_TYPE_RACING = 'BICYCLE_TYPE_RACING',

  BICYCLE_TYPE_ALL_TERRAIN = 'BICYCLE_TYPE_ALL_TERRAIN',
  BICYCLE_TYPE_RECUMBENT = 'BICYCLE_TYPE_RECUMBENT',
  BICYCLE_TYPE_FOLDING = 'BICYCLE_TYPE_FOLDING',
  BICYCLE_TYPE_TREKKING = 'BICYCLE_TYPE_TREKKING',
  BICYCLE_TYPE_TANDEM = 'BICYCLE_TYPE_TANDEM',
  BICYCLE_TYPE_CHILDREN = 'BICYCLE_TYPE_CHILDREN',
  BICYCLE_TYPE_HYBRID = 'BICYCLE_TYPE_HYBRID',
  BICYCLE_TYPE_CARGO = 'BICYCLE_TYPE_CARGO',
  BICYCLE_TYPE_CITY = 'BICYCLE_TYPE_CITY',

  BICYCLE_TYPE_SPEED_PEDELEC = 'BICYCLE_TYPE_SPEED_PEDELEC',
  BICYCLE_TYPE_ELECTRIC_CITY = 'BICYCLE_TYPE_ELECTRIC_CITY',
  BICYCLE_TYPE_ELECTRIC_CARGO = 'BICYCLE_TYPE_ELECTRIC_CARGO',
  BICYCLE_TYPE_ELECTRIC_RACING = 'BICYCLE_TYPE_ELECTRIC_RACING',
  BICYCLE_TYPE_ELECTRIC_MOUNTAIN = 'BICYCLE_TYPE_ELECTRIC_MOUNTAIN',
  // Boats
  CATEGORY_MOTORBOAT = 'CATEGORY_MOTORBOAT',
  MOTORBOAT = 'MOTORBOAT',
  CATEGORY_SAILING = 'CATEGORY_SAILING',
  SAILING = 'SAILING',
  CATEGORY_CATAMARAN = 'CATEGORY_CATAMARAN',
  CATAMARAN = 'CATAMARAN',
  CATEGORY_BARGE = 'CATEGORY_BARGE',
  BARGE = 'BARGE',
  CATEGORY_CLASSIC_YACHT = 'CATEGORY_CLASSIC_YACHT',
  YACHT = 'YACHT',
  JETSKI = 'JETSKI',
  CATEGORY_SLOOP = 'CATEGORY_SLOOP',
  SLOOP = 'SLOOP',
  CATEGORY_RUBBER = 'CATEGORY_RUBBER',
  RUBBER = 'RUBBER',
  // Territories
  TERRITORY_NL_COAST = 'TERRITORY_NL_COAST',
  TERRITORY_NL_SHALLOW_WATERS = 'TERRITORY_NL_SHALLOW_WATERS',
  TERRITORY_EU_COAST = 'TERRITORY_EU_COAST',
  TERRITORY_EU_SHALLOW_WATERS = 'TERRITORY_EU_SHALLOW_WATERS',
  TERRITORY_BIG_SQUARE = 'TERRITORY_BIG_SQUARE',
  TERRITORY_EXTRA_BIG_SQUARE = 'TERRITORY_EXTRA_BIG_SQUARE',
  TERRITORY_MEDITERRANEAN_SEA = 'TERRITORY_MEDITERRANEAN_SEA',
  TERRITORY_ASIA = 'TERRITORY_ASIA',
  TERRITORY_CARIBBEAN = 'TERRITORY_CARIBBEAN',
  TERRITORY_WORLD = 'TERRITORY_WORLD',
  TERRITORY_CURACAO = 'TERRITORY_CURACAO',
  TERRITORY_SINT_MAARTEN = 'TERRITORY_SINT_MAARTEN',
  TERRITORY_ARUBA = 'TERRITORY_ARUBA',
  TERRITORY_BONAIRE = 'TERRITORY_BONAIRE',
  // Residences
  RESIDENCE = 'RESIDENCE',
  RESIDENCE_TYPE_RESIDENCE_OPEN = 'RESIDENCE_TYPE_RESIDENCE_OPEN',
  RESIDENCE_TYPE_RESIDENCE_HALFOPEN = 'RESIDENCE_TYPE_RESIDENCE_HALFOPEN',
  RESIDENCE_TYPE_RESIDENCE_CLOSED = 'RESIDENCE_TYPE_RESIDENCE_CLOSED',
  RESIDENCE_TYPE_RESIDENCE = 'RESIDENCE_TYPE_RESIDENCE',
  RESIDENCE_TYPE_APARTMENT = 'RESIDENCE_TYPE_APARTMENT',
  RESIDENCE_TYPE_VILLA = 'RESIDENCE_TYPE_VILLA',
  RESIDENCE_TYPE_COUNTRY_HOUSE = 'RESIDENCE_TYPE_COUNTRY_HOUSE',
  RESIDENCE_TYPE_FARMHOUSE = 'RESIDENCE_TYPE_FARMHOUSE',
  RESIDENCE_TYPE_TOWN_HOUSE = 'RESIDENCE_TYPE_TOWN_HOUSE',
  RESIDENCE_TYPE_SEMI_DETACHED = 'RESIDENCE_TYPE_SEMI_DETACHED',
  RESIDENCE_TYPE_RESIDENCE_SEMI_DETACHED = 'RESIDENCE_TYPE_RESIDENCE_SEMI_DETACHED',
  ATTACHMENT_OPEN = 'ATTACHMENT_OPEN',
  ATTACHMENT_HALFOPEN = 'ATTACHMENT_HALFOPEN',
  ATTACHMENT_CLOSED = 'ATTACHMENT_CLOSED',
  ATTACHMENT_RESIDENCE = 'ATTACHMENT_RESIDENCE',
  ATTACHMENT_APARTMENT = 'ATTACHMENT_APARTMENT',
  ATTACHMENT_VILLA = 'ATTACHMENT_VILLA',
  ATTACHMENT_COUNTRY_HOUSE = 'ATTACHMENT_COUNTRY_HOUSE',
  ATTACHMENT_FARMHOUSE = 'ATTACHMENT_FARMHOUSE',
  ATTACHMENT_TOWN_HOUSE = 'ATTACHMENT_TOWN_HOUSE',
  ATTACHMENT_SEMI_DETACHED = 'ATTACHMENT_SEMI_DETACHED',
  // Roof types
  ROOF_CONSTRUCTION_TYPE_TILES = 'ROOF_CONSTRUCTION_TYPE_TILES',
  ROOF_CONSTRUCTION_TYPE_FLAT = 'ROOF_CONSTRUCTION_TYPE_FLAT',
  ROOF_CONSTRUCTION_TYPE_THATCHED_TRADITIONAL = 'ROOF_CONSTRUCTION_TYPE_THATCHED_TRADITIONAL',
  ROOF_CONSTRUCTION_TYPE_THATCHED_FIREPROOF = 'ROOF_CONSTRUCTION_TYPE_THATCHED_FIREPROOF',
  ROOF_CONSTRUCTION_TYPE_THATCHED_SCREWED = 'ROOF_CONSTRUCTION_TYPE_THATCHED_SCREWED',
  // Valuable items
  PAINTINGS = 'PAINTINGS',
  SCULPTURES = 'SCULPTURES',
  JEWELLERY = 'JEWELLERY',
  DIAMONDS = 'DIAMONDS',
  SILVERWARE = 'SILVERWARE',
  GOLDWARE = 'GOLDWARE',
  CARPETS = 'CARPETS',
  TAPESTRY = 'TAPESTRY',
  ANTIQUES = 'ANTIQUES',
  SECURITIES = 'SECURITIES',
  COLLECTIONS = 'COLLECTIONS',
  JEWELRY = 'JEWELRY',
  // Fire
  SMOKE_DETECTORS = 'SMOKE_DETECTORS',
  SMOKE_DETECTORS_WITH_TRANSMISSION = 'SMOKE_DETECTORS_WITH_TRANSMISSION',
  FIRE_EXTINGUISHERS = 'FIRE_EXTINGUISHERS',
  FIRE_BLANKETS = 'FIRE_BLANKETS',
  // Insurances
  CAR_WA = 'CAR_WA',
  CAR_CASCO = 'CAR_CASCO',
  CAR_FULL_CASCO = 'CAR_FULL_CASCO',
  CAR_SVI = 'CAR_SVI',
  OLDTIMER_WA = 'OLDTIMER_WA',
  OLDTIMER_CASCO = 'OLDTIMER_CASCO',
  OLDTIMER_FULL_CASCO = 'OLDTIMER_FULL_CASCO',
  OLDTIMER_SVI = 'OLDTIMER_SVI',
  MOTO_WA = 'MOTO_WA',
  MOTO_CASCO = 'MOTO_CASCO',
  MOTO_FULL_CASCO = 'MOTO_FULL_CASCO',
  MOTO_SVI = 'MOTO_SVI',
  MOPED_WA = 'MOPED_WA',
  MOPED_CASCO = 'MOPED_CASCO',
  MOPED_FULL_CASCO = 'MOPED_FULL_CASCO',
  HOME_BUILDING = 'HOME_BUILDING',
  HOME_CONTENT = 'HOME_CONTENT',
  THEFT = 'THEFT',
  VALUABLES = 'VALUABLES',
  MUSICAL_INSTRUMENTS = 'MUSICAL_INSTRUMENTS',
  ART = 'ART',
  LIABILITY = 'LIABILITY',
  TRAVEL = 'TRAVEL',
  LEGAL_ASSISTANCE = 'LEGAL_ASSISTANCE',
  LEGAL_TAX_DEDUCTIBLE = 'LEGAL_TAX_DEDUCTIBLE',
  LEGAL = 'LEGAL',
  FUNERAL = 'FUNERAL',
  ACCIDENT = 'ACCIDENT',
  CYBER = 'CYBER',
  HOME_THEFT = 'HOME_THEFT',
  DEBT_BALANCE = 'DEBT_BALANCE',
  HOSPITALISATION = 'HOSPITALISATION',
  CONTENT = 'CONTENT',
  FIRE = 'FIRE',
  FAMILY_BA = 'FAMILY_BA',
  CAR_OMNIUM = 'CAR_OMNIUM',
  CAR_MINI_OMNIUM = 'CAR_MINI_OMNIUM',
  CAR_BA = 'CAR_BA',
  // Others
  PETS = 'PETS',
  FAMILY = 'FAMILY',
  DIGITAL_SECURITY = 'DIGITAL_SECURITY',
  MAIL = 'MAIL',
  // Happenings
  MOVING_HOUSE = 'MOVING_HOUSE',
  RENOVATION = 'RENOVATION',
  MARRIAGE = 'MARRIAGE',
  KIDS = 'KIDS',
  DIVORCE = 'DIVORCE',
  // Owner types
  HOLDER_TYPE_OWNER = 'HOLDER_TYPE_OWNER',
  HOLDER_TYPE_TENANT = 'HOLDER_TYPE_TENANT',
  HOLDER_TYPE_LANDLORD = 'HOLDER_TYPE_LANDLORD',
  HOLDER_TYPE_CO_TENANT = 'HOLDER_TYPE_CO_TENANT',
  HOLDER_TYPE_LIVING_WITH_PARENTS = 'HOLDER_TYPE_LIVING_WITH_PARENTS',
  HOLDER_TYPE_CO_OWNER = 'HOLDER_TYPE_CO_OWNER',
  HOLDER_TYPE_SUB_TENANT = 'HOLDER_TYPE_SUB_TENANT',
  HOLDER_TYPE_OWNER_RESIDENT = 'HOLDER_TYPE_OWNER_RESIDENT',
  HOLDER_TYPE_USUFRUCT = 'HOLDER_TYPE_USUFRUCT',
  HOLDER_STATE_OWNER = 'HOLDER_STATE_OWNER',
  HOLDER_STATE_TENANT = 'HOLDER_STATE_TENANT',
  HOLDER_STATE_LANDLORD = 'HOLDER_STATE_LANDLORD',
  HOLDER_STATE_CO_TENANT = 'HOLDER_STATE_CO_TENANT',
  HOLDER_STATE_LIVING_WITH_PARENTS = 'HOLDER_STATE_LIVING_WITH_PARENTS',
  HOLDER_STATE_CO_OWNER = 'HOLDER_STATE_CO_OWNER',
  HOLDER_STATE_SUB_TENANT = 'HOLDER_STATE_SUB_TENANT',
  HOLDER_STATE_OWNER_RESIDENT = 'HOLDER_STATE_OWNER_RESIDENT',
  HOLDER_STATE_USUFRUCT = 'HOLDER_STATE_USUFRUCT',
  // Travel
  TRAVELS_A_YEAR_NEVER = 'TRAVELS_A_YEAR_NEVER',
  TRAVELS_A_YEAR_ONCE_A_YEAR = 'TRAVELS_A_YEAR_ONCE_A_YEAR',
  TRAVELS_A_YEAR_TWICE_A_YEAR = 'TRAVELS_A_YEAR_TWICE_A_YEAR',
  TRAVELS_A_YEAR_MORE_THAN_TWICE_A_YEAR = 'TRAVELS_A_YEAR_MORE_THAN_TWICE_A_YEAR',
  // Legal benefits
  BICYCLE_ALLOWANCE = 'BICYCLE_ALLOWANCE',
  MOBILITY_BUDGET = 'MOBILITY_BUDGET',
  MOBILITY_ALLOWANCE = 'MOBILITY_ALLOWANCE',
  TELEPHONE_SUBSCRIPTION = 'TELEPHONE_SUBSCRIPTION',
  INTERNET_SUBSCRIPTION = 'INTERNET_SUBSCRIPTION',
  TABLET = 'TABLET',
  LAPTOP = 'LAPTOP',
  SMARTPHONE = 'SMARTPHONE',
  ECO_VOUCHERS = 'ECO_VOUCHERS',
  MEAL_VOUCHERS = 'MEAL_VOUCHERS',
  HOSPITALISATION_INSURANCE = 'HOSPITALISATION_INSURANCE',
  GROUP_INSURANCE = 'GROUP_INSURANCE',
  //
  RENT_MULTIPLE_PROPERTIES = 'RENT_MULTIPLE_PROPERTIES',
  OWNER_SECOND_PROPERTY = 'OWNER_SECOND_PROPERTY',
  RENTAL_PROPERTIES = 'RENTAL_PROPERTIES',
  RENOVATE_SELL_PROPERTIES = 'RENOVATE_SELL_PROPERTIES',
  //
  ELEVATOR = 'ELEVATOR',
  FIREPLACE = 'FIREPLACE',
  SWIMMING_POOL = 'SWIMMING_POOL',
  HOME_BUSINESS = 'HOME_BUSINESS',
  BUILDING_LISTED = 'BUILDING_LISTED',
  CASH_AT_HOME = 'CASH_AT_HOME',
  FIRE_PROOF_DOOR = 'FIRE_PROOF_DOOR',
  DOUBLE_GLASS = 'DOUBLE_GLASS',
  SHUTTERS = 'SHUTTERS',
  // Hobbies
  RECREATIONAL_BOATS = 'RECREATIONAL_BOATS',
  SPORT = 'SPORT',
  CARS = 'CARS',
  MOTORCYCLES = 'MOTORCYCLES',
  MANAGE_SPORT_ASSOCIATION_OR_CLUB = 'MANAGE_SPORT_ASSOCIATION_OR_CLUB',
  HORSES = 'HORSES',
  DRONES = 'DRONES',
  HUNTING = 'HUNTING',
  // Life & future
  INVESTMENTS = 'INVESTMENTS',
  SAVINGS = 'SAVINGS',
  HOME = 'HOME',
  REAL_ESTATE = 'REAL_ESTATE',
  MARRYING = 'MARRYING',
  CHILDREN = 'CHILDREN',
  AMOUNT_OF_CHILDREN = 'AMOUNT_OF_CHILDREN',
  //
  RESIDENTIAL_CARE_CENTER = 'RESIDENTIAL_CARE_CENTER',
  SERVICE_FLAT = 'SERVICE_FLAT',
  HOBBYS = 'HOBBYS',
  FOCUS_ON_CHILDREN = 'FOCUS_ON_CHILDREN',
  EMIGRATE = 'EMIGRATE',
  WORK = 'WORK',
  SECOND_RESIDENCE = 'SECOND_RESIDENCE',
  // interests
  FUNDS = 'FUNDS',
  BONDS = 'BONDS',
  COMMODITIES = 'COMMODITIES',
  COMPANIES = 'COMPANIES',
  CRYPTO = 'CRYPTO',
  STOCKS = 'STOCKS',
  // savings
  BUILDING_SAVINGS = 'BUILDING_SAVINGS',
  CASH = 'CASH',
  FUND = 'FUND',
  CHILD = 'CHILD',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  PENSION = 'PENSION',

  // Pet surgery
  SMALL_CATTLE = 'SMALL_CATTLE',
  PROFESSIONAL_BREEDER = 'PROFESSIONAL_BREEDER',
  TEMPORARY_CARETAKER = 'TEMPORARY_CARETAKER',
  OWNER_OF_PETS = 'OWNER_OF_PETS',

  // House hold
  HOUSEHOLD = 'HOUSEHOLD',
  GARDENER = 'GARDENER',
  BABYSIT = 'BABYSIT',

  // sport types
  AGILITY = 'AGILITY',
  GYMNASTIC = 'GYMNASTIC',
  ATHLETIC = 'ATHLETIC',
  RECREATIONAL = 'RECREATIONAL',
  EXTREME = 'EXTREME',
  WINTER = 'WINTER',
  WATER = 'WATER',

  // Car interests
  RACING = 'RACING',
  SPORTSCAR = 'SPORTSCAR',
  KARTING = 'KARTING',
  COMPETITION = 'COMPETITION',
  CAR_TINKERER = 'CAR_TINKERER',
  CAR_COLLECTOR = 'CAR_COLLECTOR',

  // Motor interests
  MOTOCROSS = 'MOTOCROSS',
  MOTORCYCLE_TINKERER = 'MOTORCYCLE_TINKERER',
  SPARE_TIME = 'SPARE_TIME',
  CLUB_MEMBER = 'CLUB_MEMBER',
  MOTOR_COLLECTOR = 'MOTOR_COLLECTOR',
  MOTOR_SPORT = 'MOTOR_SPORT',

  // Security measures
  SAFE = 'SAFE',
  SECURITY_CERTIFICATE = 'SECURITY_CERTIFICATE',
  SECURITY_ALARM_WITH_TRANSMISSION = 'SECURITY_ALARM_WITH_TRANSMISSION',
}

const IllustrationRounded: React.FC<React.PropsWithChildren<Props>> = ({
  type,
  isActive,
}) => {
  switch (type) {
    // Room sizes
    case IllustrationRoundedTypes.ROOM_SIZE_SMALL:
      return <IllustrationRoomSizeSmall isActive={isActive} />;
    case IllustrationRoundedTypes.ROOM_SIZE_MEDIUM:
      return <IllustrationRoomSizeMedium isActive={isActive} />;
    case IllustrationRoundedTypes.ROOM_SIZE_LARGE:
      return <IllustrationRoomSizeLarge isActive={isActive} />;

    // Boat coverages
    case IllustrationRoundedTypes.BOAT_BA:
      return <IllustrationBoatBA isActive={isActive} />;
    case IllustrationRoundedTypes.BOAT_BA_CASCO:
      return <IllustrationBoatBACasco isActive={isActive} />;
    case IllustrationRoundedTypes.BOAT_BA_EXTENDED_CASCO:
      return <IllustrationBoatBAExtendedCasco isActive={isActive} />;

    // Motor interests
    case IllustrationRoundedTypes.SPARE_TIME:
      return <IllustrationMotoSpareTime isActive={isActive} />;
    case IllustrationRoundedTypes.MOTOR_COLLECTOR:
      return <IllustrationMotorCollector isActive={isActive} />;
    case IllustrationRoundedTypes.MOTOCROSS:
      return <IllustrationMotorcross isActive={isActive} />;
    case IllustrationRoundedTypes.CLUB_MEMBER:
      return <IllustrationClubMember isActive={isActive} />;

    // Car interests
    case IllustrationRoundedTypes.RACING:
      return <IllustrationRacing isActive={isActive} />;
    case IllustrationRoundedTypes.KARTING:
      return <IllustrationKarting isActive={isActive} />;
    case IllustrationRoundedTypes.MOTOR_SPORT:
    case IllustrationRoundedTypes.COMPETITION:
      return <IllustrationCompetition isActive={isActive} />;
    case IllustrationRoundedTypes.SPORTSCAR:
      return <IllustrationSportsCar isActive={isActive} />;
    case IllustrationRoundedTypes.MOTORCYCLE_TINKERER:
    case IllustrationRoundedTypes.CAR_TINKERER:
      return <IllustrationCarTinkerer isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_COLLECTOR:
      return <IllustrationCarCollector isActive={isActive} />;

    // Sport types
    case IllustrationRoundedTypes.AGILITY:
      return <IllustrationAgility isActive={isActive} />;
    case IllustrationRoundedTypes.GYMNASTIC:
      return <IllustrationGymnastic isActive={isActive} />;
    case IllustrationRoundedTypes.ATHLETIC:
      return <IllustrationAthletic isActive={isActive} />;
    case IllustrationRoundedTypes.RECREATIONAL:
      return <IllustrationRecreational isActive={isActive} />;
    case IllustrationRoundedTypes.EXTREME:
      return <IllustrationExtreme isActive={isActive} />;
    case IllustrationRoundedTypes.WINTER:
      return <IllustrationWinter isActive={isActive} />;
    case IllustrationRoundedTypes.WATER:
      return <IllustrationWater isActive={isActive} />;

    // House hold
    case IllustrationRoundedTypes.HOUSEHOLD:
      return <IllustrationHouselhold isActive={isActive} />;
    case IllustrationRoundedTypes.GARDENER:
      return <IllustrationGardener isActive={isActive} />;
    case IllustrationRoundedTypes.BABYSIT:
      return <IllustrationBabysit isActive={isActive} />;

    // Pet surgery
    case IllustrationRoundedTypes.SMALL_CATTLE:
      return <IllustrationSmallCattle isActive={isActive} />;
    case IllustrationRoundedTypes.PROFESSIONAL_BREEDER:
      return <IllustrationProfessionalBreeder isActive={isActive} />;
    case IllustrationRoundedTypes.TEMPORARY_CARETAKER:
      return <IllustrationTemporaryCaretaker isActive={isActive} />;
    case IllustrationRoundedTypes.OWNER_OF_PETS:
      return <IllustrationOwnerOfPets isActive={isActive} />;

    // savings
    case IllustrationRoundedTypes.BUILDING_SAVINGS:
      return <IllustrationBuildingSavings isActive={isActive} />;
    case IllustrationRoundedTypes.CASH:
      return <IllustrationCash isActive={isActive} />;
    case IllustrationRoundedTypes.FUND:
      return <IllustrationFunds isActive={isActive} />;
    case IllustrationRoundedTypes.CHILD:
      return <IllustrationChild isActive={isActive} />;
    case IllustrationRoundedTypes.BANK_ACCOUNT:
      return <IllustrationBankAccount isActive={isActive} />;
    case IllustrationRoundedTypes.PENSION:
      return <IllustrationPension isActive={isActive} />;

    // Interests
    case IllustrationRoundedTypes.FUNDS:
      return <IllustrationFunds isActive={isActive} />;
    case IllustrationRoundedTypes.BONDS:
      return <IllustrationBonds isActive={isActive} />;
    case IllustrationRoundedTypes.COMMODITIES:
      return <IllustrationCommodities isActive={isActive} />;
    case IllustrationRoundedTypes.COMPANIES:
      return <IllustrationCompanies isActive={isActive} />;
    case IllustrationRoundedTypes.CRYPTO:
      return <IllustrationCrypto isActive={isActive} />;
    case IllustrationRoundedTypes.STOCKS:
      return <IllustrationStocks isActive={isActive} />;

    // Life & future
    case IllustrationRoundedTypes.RESIDENTIAL_CARE_CENTER:
      return <IllustrationResidentialCareCenter isActive={isActive} />;
    case IllustrationRoundedTypes.SERVICE_FLAT:
      return <IllustrationServiceFlat isActive={isActive} />;
    case IllustrationRoundedTypes.HOBBYS:
      return <IllustrationHobbies isActive={isActive} />;
    case IllustrationRoundedTypes.EMIGRATE:
      return <IllustrationEmigrate isActive={isActive} />;
    case IllustrationRoundedTypes.WORK:
      return <IllustrationWork isActive={isActive} />;
    case IllustrationRoundedTypes.SECOND_RESIDENCE:
      return <IllustrationSecondResidence isActive={isActive} />;

    //--
    case IllustrationRoundedTypes.INVESTMENTS:
      return <IllustrationInvestments isActive={isActive} />;
    case IllustrationRoundedTypes.SAVINGS:
      return <IllustrationSavings isActive={isActive} />;
    case IllustrationRoundedTypes.HOME:
      return <IllustrationHome isActive={isActive} />;
    case IllustrationRoundedTypes.REAL_ESTATE:
      return <IllustrationRealEstate isActive={isActive} />;
    case IllustrationRoundedTypes.MARRYING:
      return <IllustrationMarrying isActive={isActive} />;
    case IllustrationRoundedTypes.FOCUS_ON_CHILDREN:
    case IllustrationRoundedTypes.CHILDREN:
      return <IllustrationChildren isActive={isActive} />;

    // Hobbies
    case IllustrationRoundedTypes.RECREATIONAL_BOATS:
      return <IllustrationRecreationalBoat isActive={isActive} />;
    case IllustrationRoundedTypes.SPORT:
      return <IllustrationSport isActive={isActive} />;
    case IllustrationRoundedTypes.CARS:
      return <IllustrationFamilyCar isActive={isActive} />;
    case IllustrationRoundedTypes.MOTORCYCLES:
      return <IllustrationMotorcycles isActive={isActive} />;
    case IllustrationRoundedTypes.MANAGE_SPORT_ASSOCIATION_OR_CLUB:
      return <IllustrationManageSportAssociation isActive={isActive} />;
    case IllustrationRoundedTypes.HORSES:
      return <IllustrationHorse isActive={isActive} />;
    case IllustrationRoundedTypes.DRONES:
      return <IllustrationDrones isActive={isActive} />;
    case IllustrationRoundedTypes.HUNTING:
      return <IllustrationHunting isActive={isActive} />;

    //
    case IllustrationRoundedTypes.BUILDING_LISTED:
      return <IllustrationBuildingListed isActive={isActive} />;
    case IllustrationRoundedTypes.HOME_BUSINESS:
      return <IllustrationHomeBusiness isActive={isActive} />;
    case IllustrationRoundedTypes.SWIMMING_POOL:
      return <IllustrationSwimmingPool isActive={isActive} />;
    case IllustrationRoundedTypes.ELEVATOR:
      return <IllustrationElevator isActive={isActive} />;
    case IllustrationRoundedTypes.FIREPLACE:
      return <IllustrationFirePlace isActive={isActive} />;
    case IllustrationRoundedTypes.CASH_AT_HOME:
      return <IllustrationCashAtHome isActive={isActive} />;
    case IllustrationRoundedTypes.FIRE_PROOF_DOOR:
      return <IllustrationFireProofDoor isActive={isActive} />;
    case IllustrationRoundedTypes.DOUBLE_GLASS:
      return <IllustrationDoubleGlass isActive={isActive} />;
    case IllustrationRoundedTypes.SHUTTERS:
      return <IllustrationShutters isActive={isActive} />;

    //
    case IllustrationRoundedTypes.RENOVATE_SELL_PROPERTIES:
      return <IllustrationRenovateSellProperties isActive={isActive} />;
    case IllustrationRoundedTypes.RENTAL_PROPERTIES:
      return <IllustrationRentalProperties isActive={isActive} />;
    case IllustrationRoundedTypes.OWNER_SECOND_PROPERTY:
      return <IllustrationOwnerSecondProperty isActive={isActive} />;
    case IllustrationRoundedTypes.RENT_MULTIPLE_PROPERTIES:
      return <IllustrationRentMultipleProperties isActive={isActive} />;
    // Legal benefits
    case IllustrationRoundedTypes.BICYCLE_ALLOWANCE:
      return <IllustrationBicycleAllowance isActive={isActive} />;
    case IllustrationRoundedTypes.MOBILITY_BUDGET:
      return <IllustrationMobilityBudget isActive={isActive} />;
    case IllustrationRoundedTypes.MOBILITY_ALLOWANCE:
      return <IllustrationMobilityAllowance isActive={isActive} />;
    case IllustrationRoundedTypes.TELEPHONE_SUBSCRIPTION:
      return <IllustrationTelephoneSubscription isActive={isActive} />;
    case IllustrationRoundedTypes.INTERNET_SUBSCRIPTION:
      return <IllustrationInternetSubscription isActive={isActive} />;
    case IllustrationRoundedTypes.TABLET:
      return <IllustrationTablet isActive={isActive} />;
    case IllustrationRoundedTypes.LAPTOP:
      return <IllustrationLaptop isActive={isActive} />;
    case IllustrationRoundedTypes.SMARTPHONE:
    case IllustrationRoundedTypes.MOBILE_ELECTRONICS:
      return <IllustrationSmartphone isActive={isActive} />;
    case IllustrationRoundedTypes.ECO_VOUCHERS:
      return <IllustrationEcoVouchers isActive={isActive} />;
    case IllustrationRoundedTypes.MEAL_VOUCHERS:
      return <IllustrationMealVouchers isActive={isActive} />;
    case IllustrationRoundedTypes.HOSPITALISATION:
    case IllustrationRoundedTypes.HOSPITALISATION_INSURANCE:
      return <IllustrationHospitalisationInsurance isActive={isActive} />;
    case IllustrationRoundedTypes.FIRE:
      return <IllustrationFire isActive={isActive} />;
    case IllustrationRoundedTypes.GROUP_INSURANCE:
      return <IllustrationGroupInsurance isActive={isActive} />;

    // Mobility types
    case IllustrationRoundedTypes.SEGWAY:
      return <IllustrationSegway isActive={isActive} />;
    case IllustrationRoundedTypes.MONOWHEEL:
      return <IllustrationMonowheel isActive={isActive} />;
    case IllustrationRoundedTypes.ESTEP:
      return <IllustrationEStep isActive={isActive} />;
    case IllustrationRoundedTypes.ELECTRIC_SKATEBOARD:
      return <IllustrationElectricSkateboard isActive={isActive} />;
    case IllustrationRoundedTypes.HOVERBOARD:
      return <IllustrationHoverBoard isActive={isActive} />;
    case IllustrationRoundedTypes.GYROPODE:
      return <IllustrationGyropode isActive={isActive} />;
    // Travels a year
    case IllustrationRoundedTypes.TRAVELS_A_YEAR_MORE_THAN_TWICE_A_YEAR:
      return <IllustrationTravelsAYearMore isActive={isActive} />;
    case IllustrationRoundedTypes.TRAVELS_A_YEAR_TWICE_A_YEAR:
      return <IllustrationTravelsAYearTwice isActive={isActive} />;
    case IllustrationRoundedTypes.TRAVELS_A_YEAR_ONCE_A_YEAR:
      return <IllustrationTravelsAYearOnce isActive={isActive} />;
    case IllustrationRoundedTypes.TRAVELS_A_YEAR_NEVER:
      return <IllustrationInsuranceHomeContent isActive={isActive} />;
    // Owner types
    case IllustrationRoundedTypes.HOLDER_TYPE_CO_TENANT:
    case IllustrationRoundedTypes.HOLDER_STATE_CO_TENANT:
      return <IllustrationHolderTypeCoTenant isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_LANDLORD:
    case IllustrationRoundedTypes.HOLDER_STATE_LANDLORD:
      return <IllustrationHolderTypeLandlord isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_TENANT:
    case IllustrationRoundedTypes.HOLDER_STATE_TENANT:
      return <IllustrationHolderTypeTenant isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_OWNER:
    case IllustrationRoundedTypes.HOLDER_STATE_OWNER:
      return <IllustrationHolderTypeOwner isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_OWNER_RESIDENT:
    case IllustrationRoundedTypes.HOLDER_STATE_OWNER_RESIDENT:
      return <IllustrationHolderTypeOwnerResident isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_CO_OWNER:
    case IllustrationRoundedTypes.HOLDER_STATE_CO_OWNER:
      return <IllustrationHolderTypeCoOwner isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_SUB_TENANT:
    case IllustrationRoundedTypes.HOLDER_STATE_SUB_TENANT:
      return <IllustrationHolderTypeSubTenant isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_LIVING_WITH_PARENTS:
    case IllustrationRoundedTypes.HOLDER_STATE_LIVING_WITH_PARENTS:
      return <IllustrationHolderTypeLivingWithParents isActive={isActive} />;
    case IllustrationRoundedTypes.HOLDER_TYPE_USUFRUCT:
    case IllustrationRoundedTypes.HOLDER_STATE_USUFRUCT:
      return <IllustrationUsufruct isActive={isActive} />;
    // Happenings
    case IllustrationRoundedTypes.DIVORCE:
      return <IllustrationDivorce isActive={isActive} />;
    case IllustrationRoundedTypes.KIDS:
      return <IllustrationKids isActive={isActive} />;
    case IllustrationRoundedTypes.MARRIAGE:
      return <IllustrationMarage isActive={isActive} />;
    case IllustrationRoundedTypes.RENOVATION:
      return <IllustrationRenovations isActive={isActive} />;
    case IllustrationRoundedTypes.MOVING_HOUSE:
      return <IllustrationMovingHome isActive={isActive} />;
    // Insurances
    case IllustrationRoundedTypes.THEFT:
    case IllustrationRoundedTypes.HOME_THEFT:
      return <IllustrationHomeTheft isActive={isActive} />;
    case IllustrationRoundedTypes.DEBT_BALANCE:
      return <IllustrationDebtBalance isActive={isActive} />;
    case IllustrationRoundedTypes.CYBER:
      return <IllustrationInsuranceCyber isActive={isActive} />;
    case IllustrationRoundedTypes.ACCIDENT:
      return <IllustrationAccident isActive={isActive} />;
    case IllustrationRoundedTypes.FUNERAL:
      return <IllustrationInsuranceFuneral isActive={isActive} />;
    case IllustrationRoundedTypes.LIFE:
      return <IllustrationFuneralInsurance isActive={isActive} />;
    case IllustrationRoundedTypes.LEGAL:
    case IllustrationRoundedTypes.LEGAL_ASSISTANCE:
      return <IllustrationInsuranceLegalAssistance isActive={isActive} />;
    case IllustrationRoundedTypes.LEGAL_TAX_DEDUCTIBLE:
      return <IllustrationInsuranceLegalTaxAssistance isActive={isActive} />;
    case IllustrationRoundedTypes.TRAVEL:
      return <IllustrationInsuranceTravel isActive={isActive} />;
    case IllustrationRoundedTypes.FAMILY_BA:
    case IllustrationRoundedTypes.LIABILITY:
      return <IllustrationInsuranceLiability isActive={isActive} />;
    case IllustrationRoundedTypes.ART:
      return <IllustrationInsuranceArt isActive={isActive} />;
    case IllustrationRoundedTypes.SAFE:
    case IllustrationRoundedTypes.VALUABLES:
      return <IllustrationInsuranceValuables isActive={isActive} />;
    case IllustrationRoundedTypes.CONTENT:
    case IllustrationRoundedTypes.HOME_CONTENT:
      return <IllustrationInsuranceHomeContent isActive={isActive} />;
    case IllustrationRoundedTypes.MOPED_CASCO:
      return <IllustrationInsuranceMopedCasco isActive={isActive} />;
    case IllustrationRoundedTypes.MOPED_FULL_CASCO:
      return <IllustrationInsuranceMopedFullCasco isActive={isActive} />;
    case IllustrationRoundedTypes.MOPED_WA:
      return <IllustrationInsuranceMopedWA isActive={isActive} />;
    case IllustrationRoundedTypes.MOTO_SVI:
      return <IllustrationInsuranceMotoSVI isActive={isActive} />;
    case IllustrationRoundedTypes.MOTO_FULL_CASCO:
      return <IllustrationInsuranceMotoFullCasco isActive={isActive} />;
    case IllustrationRoundedTypes.MOTO_CASCO:
      return <IllustrationInsuranceMotoCasco isActive={isActive} />;
    case IllustrationRoundedTypes.MOTO_WA:
      return <IllustrationInsuranceMotoWA isActive={isActive} />;
    case IllustrationRoundedTypes.OLDTIMER_SVI:
      return <IllustrationInsuranceOldtimerSVI isActive={isActive} />;
    case IllustrationRoundedTypes.OLDTIMER_FULL_CASCO:
      return <IllustrationInsuranceOldtimerFullCasco isActive={isActive} />;
    case IllustrationRoundedTypes.OLDTIMER_CASCO:
      return <IllustrationInsuranceOldtimerCasco isActive={isActive} />;
    case IllustrationRoundedTypes.OLDTIMER_WA:
      return <IllustrationInsuranceOldtimerWA isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_SVI:
      return <IllustrationInsuranceCarSVI isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_FULL_CASCO:
      return <IllustrationInsuranceCarFullCasco isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_CASCO:
      return <IllustrationInsuranceCarCasco isActive={isActive} />;
    case IllustrationRoundedTypes.PETS:
      return <IllustrationPets isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_WA:
      return <IllustrationInsuranceCarWA isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_BA:
      return <IllustrationCarBA isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_OMNIUM:
      return <IllustrationCarOmnium isActive={isActive} />;
    case IllustrationRoundedTypes.CAR_MINI_OMNIUM:
      return <IllustrationCarMiniOmnium isActive={isActive} />;

    // fire
    case IllustrationRoundedTypes.FIRE_BLANKETS:
      return <IllustrationFireBlankets isActive={isActive} />;
    case IllustrationRoundedTypes.FIRE_EXTINGUISHERS:
      return <IllustrationFireExtinguishers isActive={isActive} />;
    case IllustrationRoundedTypes.SMOKE_DETECTORS_WITH_TRANSMISSION:
    case IllustrationRoundedTypes.SMOKE_DETECTORS:
      return <IllustrationSmokeDetectors isActive={isActive} />;
    // Valuable items
    case IllustrationRoundedTypes.TAPESTRY:
    case IllustrationRoundedTypes.CARPETS:
      return <IllustrationCarpets isActive={isActive} />;
    case IllustrationRoundedTypes.SECURITIES:
      return <IllustrationSecurities isActive={isActive} />;
    case IllustrationRoundedTypes.ANTIQUES:
      return <IllustrationAntiques isActive={isActive} />;
    case IllustrationRoundedTypes.COLLECTIONS:
      return <IllustrationCollections isActive={isActive} />;
    case IllustrationRoundedTypes.GOLDWARE:
      return <IllustrationGoldware isActive={isActive} />;
    case IllustrationRoundedTypes.SILVERWARE:
      return <IllustrationSilverware isActive={isActive} />;
    case IllustrationRoundedTypes.DIAMONDS:
      return <IllustrationDiamonds isActive={isActive} />;
    case IllustrationRoundedTypes.JEWELRY:
    case IllustrationRoundedTypes.JEWELLERY:
      return <IllustrationJewellery isActive={isActive} />;
    case IllustrationRoundedTypes.SCULPTURES:
      return <IllustrationSculptures isActive={isActive} />;
    case IllustrationRoundedTypes.PAINTINGS:
      return <IllustrationPaintings isActive={isActive} />;
    case IllustrationRoundedTypes.DIGITAL_SECURITY:
      return <IllustrationDigitalSecurity isActive={isActive} />;
    // Roof types
    case IllustrationRoundedTypes.ROOF_CONSTRUCTION_TYPE_THATCHED_SCREWED:
      return <IllustrationRoofThatchedScrewed isActive={isActive} />;
    case IllustrationRoundedTypes.ROOF_CONSTRUCTION_TYPE_THATCHED_FIREPROOF:
      return <IllustrationRoofThatchedFireproof isActive={isActive} />;
    case IllustrationRoundedTypes.ROOF_CONSTRUCTION_TYPE_THATCHED_TRADITIONAL:
      return <IllustrationRoofThatchedTraditional isActive={isActive} />;
    case IllustrationRoundedTypes.ROOF_CONSTRUCTION_TYPE_FLAT:
      return <IllustrationRoofFlat isActive={isActive} />;
    case IllustrationRoundedTypes.ROOF_CONSTRUCTION_TYPE_TILES:
      return <IllustrationRoofTiles isActive={isActive} />;
    // Residences
    case IllustrationRoundedTypes.RESIDENCE_TYPE_SEMI_DETACHED:
    case IllustrationRoundedTypes.RESIDENCE_TYPE_RESIDENCE_SEMI_DETACHED:
    case IllustrationRoundedTypes.ATTACHMENT_SEMI_DETACHED:
      return <IllustrationResidenceSemiDetached isActive={isActive} />;
    case IllustrationRoundedTypes.RESIDENCE_TYPE_RESIDENCE_CLOSED:
    case IllustrationRoundedTypes.ATTACHMENT_CLOSED:
      return <IllustrationResidenceClosed isActive={isActive} />;
    case IllustrationRoundedTypes.RESIDENCE_TYPE_RESIDENCE_HALFOPEN:
    case IllustrationRoundedTypes.ATTACHMENT_HALFOPEN:
      return <IllustrationResidenceHalfOpen isActive={isActive} />;
    case IllustrationRoundedTypes.RESIDENCE_TYPE_TOWN_HOUSE:
    case IllustrationRoundedTypes.ATTACHMENT_TOWN_HOUSE:
      return <IllustrationResidenceTownHouse isActive={isActive} />;
    case IllustrationRoundedTypes.RESIDENCE_TYPE_FARMHOUSE:
    case IllustrationRoundedTypes.ATTACHMENT_FARMHOUSE:
      return <IllustrationResidenceFarmHouse isActive={isActive} />;
    case IllustrationRoundedTypes.RESIDENCE_TYPE_COUNTRY_HOUSE:
    case IllustrationRoundedTypes.ATTACHMENT_COUNTRY_HOUSE:
      return <IllustrationResidenceCountryHouse isActive={isActive} />;
    case IllustrationRoundedTypes.RESIDENCE_TYPE_VILLA:
    case IllustrationRoundedTypes.ATTACHMENT_VILLA:
      return <IllustrationResidenceVilla isActive={isActive} />;
    case IllustrationRoundedTypes.RESIDENCE_TYPE_APARTMENT:
    case IllustrationRoundedTypes.ATTACHMENT_APARTMENT:
      return <IllustrationResidenceAppartment isActive={isActive} />;
    case IllustrationRoundedTypes.HOME_BUILDING:
    case IllustrationRoundedTypes.RESIDENCE:
    case IllustrationRoundedTypes.RESIDENCE_TYPE_RESIDENCE:
    case IllustrationRoundedTypes.RESIDENCE_TYPE_RESIDENCE_OPEN:
    case IllustrationRoundedTypes.ATTACHMENT_RESIDENCE:
    case IllustrationRoundedTypes.ATTACHMENT_OPEN:
      return <IllustrationHome isActive={isActive} />;
    // Territories
    case IllustrationRoundedTypes.TERRITORY_BIG_SQUARE:
      return <IllustrationTerritoryBigSquare isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_EXTRA_BIG_SQUARE:
      return <IllustrationTerritoryExtraBigSquare isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_MEDITERRANEAN_SEA:
      return <IllustrationTerritoryMediterraneanSea isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_ASIA:
      return <IllustrationTerritoryAsia isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_CARIBBEAN:
      return <IllustrationTerritoryCaribbean isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_WORLD:
      return <IllustrationTerritoryWorld isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_EU_SHALLOW_WATERS:
      return <IllustrationTerritoryEuShallowWaters isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_EU_COAST:
      return <IllustrationTerritoryEuCoast isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_CURACAO:
      return <IllustrationTerritoryCuracao isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_SINT_MAARTEN:
      return <IllustrationTerritorySintMaarten isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_ARUBA:
      return <IllustrationTerritoryAruba isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_BONAIRE:
      return <IllustrationTerritoryBonaire isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_NL_SHALLOW_WATERS:
      return <IllustrationTerritoryNlShallowWaters isActive={isActive} />;
    case IllustrationRoundedTypes.TERRITORY_NL_COAST:
      return <IllustrationTerritoryNlCoast isActive={isActive} />;
    // Boats
    case IllustrationRoundedTypes.CATEGORY_BARGE:
    case IllustrationRoundedTypes.BARGE:
      return <IllustrationBargeBoat isActive={isActive} />;
    case IllustrationRoundedTypes.CATEGORY_RUBBER:
    case IllustrationRoundedTypes.RUBBER:
      return <IllustrationRubberBoat isActive={isActive} />;
    case IllustrationRoundedTypes.CATEGORY_SLOOP:
    case IllustrationRoundedTypes.SLOOP:
      return <IllustrationSloopBoat isActive={isActive} />;
    case IllustrationRoundedTypes.CATEGORY_CLASSIC_YACHT:
      return <IllustrationClassicYacht isActive={isActive} />;
    case IllustrationRoundedTypes.YACHT:
      return <IllustrationYacht isActive={isActive} />;
    case IllustrationRoundedTypes.JETSKI:
      return <IllustrationJetski isActive={isActive} />;
    case IllustrationRoundedTypes.CATEGORY_CATAMARAN:
    case IllustrationRoundedTypes.CATAMARAN:
      return <IllustrationCatamaran isActive={isActive} />;
    case IllustrationRoundedTypes.CATEGORY_SAILING:
    case IllustrationRoundedTypes.SAILING:
      return <IllustrationSailingboat isActive={isActive} />;
    case IllustrationRoundedTypes.CATEGORY_MOTORBOAT:
    case IllustrationRoundedTypes.MOTORBOAT:
      return <IllustrationMotorboat isActive={isActive} />;
    // Animals
    case IllustrationRoundedTypes.DOG:
      return <IllustrationDog isActive={isActive} />;
    case IllustrationRoundedTypes.CAT:
      return <IllustrationCat isActive={isActive} />;
    // ----
    case IllustrationRoundedTypes.MAIL:
      return <IllustrationMail isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_OTHER:
    case IllustrationRoundedTypes.OTHER:
      return <IllustrationOther isActive={isActive} />;
    case IllustrationRoundedTypes.PERSONAL_TRANSPORT:
      return <IllustrationPersonalTransport isActive={isActive} />;
    case IllustrationRoundedTypes.PUBLIC_TRANSPORT:
      return <IllustrationPublicTransport isActive={isActive} />;
    // Bicycles
    case IllustrationRoundedTypes.BICYCLE_TYPE_RACING:
      return <IllustrationRacingBike isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_MOUNTAIN:
      return <IllustrationMountainBike isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_ELECTRIC_CITY:
    case IllustrationRoundedTypes.BICYCLE_TYPE_ELECTRIC:
    case IllustrationRoundedTypes.BICYCLE_TYPE_EBIKE:
    case IllustrationRoundedTypes.BICYCLE_TYPE_EBIKE_25KMH:
    case IllustrationRoundedTypes.BICYCLE_TYPE_EBIKE_45KMH:
    case IllustrationRoundedTypes.BICYCLE_TYPE_ELECTRIC_LESS_THAN_25KMH:
    case IllustrationRoundedTypes.BICYCLE_TYPE_ELECTRIC_LESS_THAN_45KMH:
      return <IllustrationElectricBike isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_ELECTRIC_RACING:
      return <IllustrationBicycleElectricRacing isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_SPEED_PEDELEC:
      return <IllustrationBicycleSpeedPedelec isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_ELECTRIC_CARGO:
      return <IllustrationBicycleElectricCargo isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_ELECTRIC_MOUNTAIN:
      return <IllustrationBicycleElectricMountain isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_CHILDREN:
      return <IllustrationBicycleChildren isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_ALL_TERRAIN:
      return <IllustrationBicycleAllTerrain isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_RECUMBENT:
      return <IllustrationBicycleRecumbent isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_FOLDING:
      return <IllustrationBicycleFolding isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_TREKKING:
      return <IllustrationBicycleTrekking isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_TANDEM:
      return <IllustrationBicycleTandem isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_HYBRID:
      return <IllustrationBicycleHybrid isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_CARGO:
      return <IllustrationBicycleCargo isActive={isActive} />;
    case IllustrationRoundedTypes.BICYCLE_TYPE_CITY:
    case IllustrationRoundedTypes.BICYCLE:
    case IllustrationRoundedTypes.BICYCLE_TYPE_REGULAR:
      return <IllustrationBicycle isActive={isActive} />;
    case IllustrationRoundedTypes.ELECTRIC_MOPED:
      return <IllustrationElectricMoped isActive={isActive} />;
    case IllustrationRoundedTypes.MOPED:
      return <IllustrationMoped isActive={isActive} />;
    case IllustrationRoundedTypes.COMPANY_CAR:
      return <IllustrationCompanyCar isActive={isActive} />;
    case IllustrationRoundedTypes.MOTORHOME:
    case IllustrationRoundedTypes.MOBILHOME:
    case IllustrationRoundedTypes.CAMPER:
      return <IllustrationCamper isActive={isActive} />;
    case IllustrationRoundedTypes.BOAT:
      return <IllustrationBoat isActive={isActive} />;
    case IllustrationRoundedTypes.MOTORCYCLE:
      return <IllustrationMotorcycle isActive={isActive} />;
    case IllustrationRoundedTypes.OLDTIMER:
      return <IllustrationOldtimer isActive={isActive} />;
    case IllustrationRoundedTypes.FAMILY_CAR:
    case IllustrationRoundedTypes.CAR:
      return <IllustrationFamilyCar isActive={isActive} />;

    case IllustrationRoundedTypes.CARAVAN:
      return <IllustrationCaravan isActive={isActive} />;
    case IllustrationRoundedTypes.AIRPLANE:
      return <IllustrationPlane isActive={isActive} />;
    case IllustrationRoundedTypes.TRAIN:
      return <IllustrationTrain isActive={isActive} />;
    case IllustrationRoundedTypes.BUS:
      return <IllustrationBus isActive={isActive} />;
    case IllustrationRoundedTypes.RENTAL_CAR:
      return <IllustrationRentalCar isActive={isActive} />;
    case IllustrationRoundedTypes.FAMILY_SITUATION_COUPLE_WITH_CHILDREN:
    case IllustrationRoundedTypes.FAMILY_SITUATION_COHABITING_WITH_CHILDREN:
    case IllustrationRoundedTypes.FAMILY:
      return <IllustrationFamilyWithChildren isActive={isActive} />;
    case IllustrationRoundedTypes.FAMILY_SITUATION_COUPLE:
    case IllustrationRoundedTypes.FAMILY_SITUATION_COHABITING_WITHOUT_CHILDREN:
      return <IllustrationFamilyCouple isActive={isActive} />;
    case IllustrationRoundedTypes.FAMILY_SITUATION_WITH_CHILDREN:
      return <IllustrationFamilySingleWithChildren isActive={isActive} />;
    case IllustrationRoundedTypes.FAMILY_SITUATION_SINGLE_WITHOUT_CHILDREN:
      return <IllustrationFamilySingle isActive={isActive} />;
    case IllustrationRoundedTypes.FURNITURE:
      return <IllustrationFurniture isActive={isActive} />;
    case IllustrationRoundedTypes.CONNECTIONS:
      return <IllustrationConnections isActive={isActive} />;
    case IllustrationRoundedTypes.SECURITY_CERTIFICATE:
      return <IllustrationCertificate isActive={isActive} />;
    case IllustrationRoundedTypes.MUSICAL_INSTRUMENTS:
      return <IllustrationMusicalInstruments isActive={isActive} />;
    case IllustrationRoundedTypes.SECURITY_ALARM_WITH_TRANSMISSION:
      return <IllustrationAlarm isActive={isActive} />;
    case IllustrationRoundedTypes.AMOUNT_OF_CHILDREN:
      return <IllustrationAmountOfChildren isActive={isActive} />;
    default:
      return <IllustrationPlaceholder isActive={isActive} />;
  }
};

export default IllustrationRounded;
