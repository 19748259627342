const immothekerOrange = {
  primary: {
    main: '#ED8B00',
    50: '#FFEDD4',
    100: '#FFE2BA',
    150: '#FFD8A1',
    200: '#FFCD87',
    300: '#FFB854',
    400: '#FFA321',
    500: '#ED8B00',
    600: '#B56A00',
    700: '#7D4900',
    750: '#613900',
    800: '#452800',
    850: '#291800',
    900: '#0D0700',
  },
  secondary: {
    main: '#453635',
    50: '#D8CDCC',
    100: '#CABBBA',
    150: '#BBA8A7',
    200: '#AD9694',
    300: '#90716F',
    400: '#6A5352',
    500: '#453635',
    600: '#281F1F',
    700: '#281F1F',
    750: '#281F1F',
    800: '#221B1A',
    850: '#453635',
    900: '#392D2C',
  },
};

export default immothekerOrange;
