import React, { ChangeEvent } from 'react';
import { Select } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange?: (amount: number) => void;
  value?: number;
  customOptions?: { value: number; label?: string }[];
}

const ResultsPerPageSelect: React.FC<Props> = ({
  onChange = () => null,
  customOptions: options = [{ value: 10 }, { value: 25 }, { value: 50 }],
  value,
}) => {
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(Number(e.currentTarget.value));
  };

  return (
    <Select
      width="auto"
      onChange={handleChange}
      value={value}
      _focus={{
        outline: 'none',
        borderColor: 'gray.200',
        boxShadow: 'none',
      }}
    >
      {options.map(({ value, label }) => (
        <option key={value?.toString()} value={value}>
          {label || t('RESULTS-AMOUNT', { amount: value })}
        </option>
      ))}
    </Select>
  );
};

export default ResultsPerPageSelect;
