import React, { useEffect, useState } from 'react';
import { VStack } from '@wegroup/design-system';
import { ConvoHistoryOffer } from '../../../../../types/ConversationHistory';
import OfferPaymentListItem from './OfferPaymentListItem';
import useSetQueryParam from '../../../../common/src/hooks/query-params/useAddQueryParam';

interface Props {
  offerRevisions: ConvoHistoryOffer[];
  shouldUpdate?: boolean;
}

const OfferPaymentList: React.FC<Props> = ({
  offerRevisions,
  shouldUpdate,
}) => {
  const setQueryParam = useSetQueryParam();
  const [paidOffers, setPaidOffers] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const allPaid =
      !!Object.values(paidOffers).length &&
      Object.values(paidOffers).every((isPaid) => isPaid);

    if (allPaid) {
      setQueryParam({ status: undefined });
    }
  }, [paidOffers]);

  const handleStatusChange = (id: string, isPaid: boolean) => {
    setPaidOffers((prev) => ({
      ...prev,
      [id]: isPaid,
    }));
  };

  if (!offerRevisions.length) return <p>No offers to pay</p>;

  return (
    <VStack spacing="6" align="stretch" w="100%">
      {offerRevisions.map((offer) => (
        <OfferPaymentListItem
          key={offer.id}
          offer={offer}
          shouldUpdate={shouldUpdate}
          updateStatus={(isPaid: boolean) =>
            handleStatusChange(offer.id, isPaid)
          }
        />
      ))}
    </VStack>
  );
};

export default OfferPaymentList;
