import { format } from 'date-fns';
import { toString } from 'lodash';
import React from 'react';
import i18n, { handleResponseLanguage } from '../../../../i18n';
import { Address } from '../../../../types/Common';
import { QuoteQuestionAnswer } from '../../../../types/QuoteQuestion';
import { formatAddress } from '../../../common/src/utils/commonUtils';
import { useFlowsSelector } from '../../../common/src/utils/globalVariables';
import { toPercentage } from '../../../sales-conversation/src/utils/stringUtils';
import { getTargetQuestion } from '../components/input/company-scan-inputs/index/hooks/useTargetQuestion';
import MultipleItemsPopover from '../components/input/company-scan-inputs/table/components/MultipleItemsPopover';
import { Inputs } from '../types/Enums';
import { FlowsQuestion, isNewEnumFormat } from '../types/FlowsTypes';
import { parseStringForAnswers } from '../utils/stringUtils';
import useAnswerForQuestion from './useAnswerForQuestion';
import useFlowQuestions from './useFlowQuestions';

/**
 * Returns a textual representation for the answer of a question.
 */
const useTextualRepresentation = (): ((
  questionId: string,
  index?: number,
) => string | React.ReactElement) => {
  const answers = useFlowsSelector((state) => state.flows.answers);
  const getAnswerForQuestion = useAnswerForQuestion();
  const questions = useFlowQuestions();

  return (questionId: string, index?: number) => {
    const question = questions.find(({ id }) => id === questionId);
    const answer = getAnswerForQuestion(questionId, index)?.answer;

    if (question)
      return getTextualRepresenation({ question, answer, questions, answers });

    return '';
  };
};

interface TextualRepresentationProps {
  question: FlowsQuestion;
  answer: unknown;
  questions?: FlowsQuestion[];
  answers?: QuoteQuestionAnswer[];
}

export const getTextualRepresenation = ({
  question,
  answer,
  questions,
  answers,
}: TextualRepresentationProps): string | React.ReactElement => {
  if (answer == null) return '';

  switch (question?.rules.input_type) {
    case Inputs.ADDRESS:
      return formatAddress(answer as Address);
    case Inputs.SELECT: {
      if (isNewEnumFormat(question.rules.enum)) {
        return handleResponseLanguage(
          (question.rules.enum || []).find(({ value }) => value === answer)
            ?.label,
        );
      }
      return '';
    }
    case Inputs.DATE:
      return format(new Date(answer as string), 'dd-MM-yyyy');
    case Inputs.RADIO:
      return answer === true ? i18n.t('YES') : i18n.t('NO');
    case Inputs.PERCENTAGE:
      return toPercentage(answer as number);
    case Inputs.INDEX_MULTISELECT: {
      const answerIndices = answer as number[];
      const targetQuestion = getTargetQuestion(question, questions!);
      return (
        <MultipleItemsPopover
          title={handleResponseLanguage(
            targetQuestion?.rules?.table?.entity_name_plural,
          )}
          items={answerIndices.map((answerIndex, arrayIndex) =>
            targetQuestion?.rules?.table?.entity_formatted_str
              ? parseStringForAnswers({
                  answers: answers!,
                  question: targetQuestion?.rules?.table?.entity_formatted_str,
                  index: answerIndex,
                  toDisplayIndex: arrayIndex,
                  questions,
                })
              : '',
          )}
        />
      );
    }
    default:
      return toString(answer);
  }
};

export default useTextualRepresentation;
