import { wgApi } from '../../../app/src/features/api/wgApi';
import { MinimalOffer } from '../../../../types/Offer';
import { useQuery, UseQueryOptions } from 'react-query';
import { useLocale } from '../../../../i18n';

interface Params {
  offerId: string;
  revision?: number;
  lang?: string;
}

export const getMinimalOfferById = async ({
  offerId,
  revision = 0,
  lang,
}: Params) => {
  const path = `/v2/api/offers/${offerId}/revisions/${revision}/minimal`;
  const { data, isError } = await wgApi.get<MinimalOffer>(path, {
    query: { lang },
  });
  if (isError) throw new Error();
  return data;
};

export const getMinimalOfferQKey = ({
  offerId,
  revision,
}: Params): (string | number | undefined)[] => [
  'portfolio',
  'minimal-offer',
  offerId,
  revision,
];

export const useMinimalOffer = (
  { offerId, revision }: Params,
  options?: Omit<
    UseQueryOptions<
      unknown,
      unknown,
      MinimalOffer,
      (string | number | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { userLang } = useLocale();
  return useQuery(
    getMinimalOfferQKey({ offerId, revision }),
    () => getMinimalOfferById({ offerId, revision, lang: userLang }),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      ...options,
    },
  );
};
