import { cloneDeep, isEmpty, isEqual } from 'lodash';

export const isObjectEmpty = (obj: unknown = {}): boolean => {
  if (typeof obj === 'object' && obj !== null) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return false;
};

export const removeEmptyFieldsFromObject = (obj: unknown): unknown => {
  const finalObj = {};
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        const nestedObj = removeEmptyFieldsFromObject(obj[key]);
        if (Object.keys(nestedObj as Record<string, unknown>).length) {
          finalObj[key] = nestedObj;
        }
      } else if (
        obj[key] !== '' &&
        obj[key] !== undefined &&
        obj[key] !== null
      ) {
        finalObj[key] = obj[key];
      }
    });
    return finalObj;
  }
  return false;
};

export const isEmptyOrUndefined = (value: unknown): boolean => {
  if (typeof value === 'object') return isEmpty(value);
  return value == null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const removeNulls = (obj: any): any => {
  const copiedObject = cloneDeep(obj);
  if (copiedObject === null) {
    return undefined;
  }
  if (typeof copiedObject === 'object') {
    for (const key in copiedObject) {
      copiedObject[key] = removeNulls(copiedObject[key]);
    }
  }
  return copiedObject;
};

// ! `any` usage here because to much of a hassle otherwise
/**
 * Compares 2 values and returns the difference, based on the `oldValue`
 * @param oldValue The old vlaue
 * @param newValue The new value
 * @returns Difference between of the new value compared to the old one
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const objectDiff = (oldValue: any, newValue: any): any => {
  const mappedOldValue = removeNulls(oldValue);
  const mappedNewValue = removeNulls(newValue);

  const diff = {};

  Object.entries(mappedOldValue).forEach(([key, value]) => {
    if (!isEqual(value, mappedNewValue[key])) diff[key] = mappedNewValue[key];
  });

  return diff;
};
