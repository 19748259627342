import {
  flowIdCompanyScanBE,
  flowIdCompanyScanNL,
  flowIdGoudseCompanyScan,
} from '../constants/flowIds';

export const hasSubGroups = (flowId?: string): boolean => {
  if (!flowId) return false;
  return [
    flowIdCompanyScanBE,
    flowIdCompanyScanNL,
    flowIdGoudseCompanyScan,
  ].includes(flowId);
};
