import { chakraCustomScrollBar } from '../../../../common/src/utils/styledComponentsUtils';

const mantineCalendarChakraStyling = {
  '.mantine-Popover-dropdown': {
    zIndex: '99999',
  },
  '.mantine-DateTimePicker-input, .mantine-DatePickerInput-input': {
    height: '10',
    borderRadius: 'md',
    fontSize: 'md',
    bg: 'white',
    borderColor: 'gray.100',
    _focus: {
      borderColor: 'primary.500',
      boxShadow: 'none',
    },
    _placeholder: {
      color: 'gray.200',
    },
    _invalid: {
      border: '1px solid danger.main',
      boxShadow: 'none',
    },
  },
  '.mantine-DatePicker-monthCell, .mantine-DateTimePicker-monthCell, .mantine-DatePickerInput-monthCell':
    {
      '&[data-with-spacing]': {
        px: 0,
        py: '0.5',
      },
    },
  '.mantine-DatePicker-weekday, .mantine-DateTimePicker-weekday, mantine-DatePickerInput-weekday':
    {
      fontWeight: 'semibold',
      color: 'black',
    },
  '.mantine-DatePicker-day, .mantine-DateTimePicker-day, .mantine-DatePickerInput-day':
    {
      borderRadius: '9999px',
      '&[data-weekend]': {
        color: 'black',
      },
      '&[data-in-range]': {
        color: 'primary.500',
        bg: 'primary.50',
        borderRadius: 'none',
        '&:hover': {
          bg: 'primary.100',
        },
        '&[data-today]': {
          borderRadius: 'none',
        },
      },
      '&[data-selected]': {
        borderRadius: '9999px',
        position: 'relative',
        bg: 'primary.500',
        color: 'white',
        fontWeight: 'semibold',
        '&:hover': {
          bg: 'primary.400',
        },
        '&[data-outside]': {
          bg: 'primary.500 !important',
        },
      },
      '&[data-today]': {
        fontWeight: 'semibold',
        borderRadius: '9999px',
        bg: 'primary.50',
        color: 'primary.500',
      },
      '&[data-first-in-range]': {
        '&::before': {
          content: `''`,
          position: 'absolute',
          right: 0,
          top: 0,
          w: '50%',
          h: '100%',
          bg: 'primary.50',
          zIndex: -1,
        },
        '&[data-outside]::before': {
          display: 'none',
        },
      },
      '&[data-last-in-range]': {
        '&::before': {
          content: `''`,
          position: 'absolute',
          left: 0,
          top: 0,
          w: '50%',
          h: '100%',
          bg: 'primary.50',
          zIndex: -1,
        },
        '&[data-outside]::before': {
          display: 'none',
        },
      },
    },
};

const mantineTipTapChakraStyling = {
  '.mantine-RichTextEditor-root': {
    fontSize: { base: 'sm', laptop: 'md' },
    lineHeight: '120%',
    borderColor: 'gray.50',
    borderRadius: 'md',
    fontFamily: 'Circular',
    'h1,h2,h3,h4,h5,h6': {
      lineHeight: '125%',
      fontFamily: 'Circular',
      marginBottom: '2px',
    },
    em: {
      fontStyle: 'italic',
    },
    p: {
      paddingBottom: '4px',
    },
    ul: {
      marginTop: 0,
      paddingLeft: '15px',
      li: {
        listStyleType: 'disc',
      },
    },
    ol: {
      marginTop: 0,
      paddingLeft: '15px',
      li: {
        listStyleType: 'decimal',
      },
    },
    a: {
      color: 'primary.500',
      ':hover': {
        textDecoration: 'underline',
      },
    },

    '.ql-action:before': {
      backgroundColor: 'primary.500',
    },
    '.ql-active': {
      backgroundColor: 'primary.50',
      color: 'primary.500',
      borderColor: 'primary.200',
      ':hover': {
        backgroundColor: 'primary.100',
      },
    },
  },
  '.mantine-RichTextEditor-content': {
    minHeight: '28',
    overflow: 'auto',
  },
};

const styles = {
  global: {
    // We need to override these defaults by Chakra
    // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts#L5
    body: {
      fontFamily: "'Circular','Helvetica','Myriad',sans-serif",
      lineHeight: '120%',
    },
    input: {
      color: 'black',
    },
    a: {
      color: 'primary.main',
    },
    '*': chakraCustomScrollBar,
    ...mantineCalendarChakraStyling,
    ...mantineTipTapChakraStyling,
  },
};

export default styles;
