import { createRoot } from 'react-dom/client';
import 'core-js';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import App from './packages/app/src/App';
import initSentry from './packages/app/src/utils/setupSentry';

// ChartJS register
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

// Better to init apps before rendering the app
const initApps = () => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'DEVELOPMENT') {
    initSentry();
  }
};

initApps();

// Please avoid adding any extra code here, put it in `MainProvider` or a better fit location
createRoot(document.getElementById('root')!).render(<App />);
