import {
  PayloadAction,
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { persistState, loadState } from '../reducers/statePersistence';
import { uniq } from 'lodash';

// Types
import { SmtSessionObject } from '../../../../../../types/SMT';
import {
  CancelledInsuranceValues,
  LouiseOffer,
  LouiseQuote,
} from '../../../../../../types/Quotes';
import { InsuranceCompany } from '../../../../../../types/Broker';
import { AcknowledgementPayload } from '../../../../../../types/ProductFactory';
import { RootState } from '../reducers/rootReducer';

/* * * * * * * * * * * * * * *
 * TYPES
 * * * * * * * * * * * * * * */

interface RiskObjectId {
  riskObjectId: string;
  riskObjectRevisionId: string;
}

interface RiskObjectAction {
  type: string;
  riskObjectId: string;
  riskObjectRevisionId: string;
}

interface AcknowledgementAction {
  acknowledgements: AcknowledgementPayload[];
  type: string;
}

interface AcknowledgementAnswerAction {
  type: string;
  acknowledgementKey: string;
  answer: boolean;
}

interface AddQuoteAction {
  riskObject: string;
  id: string;
}

interface AddQuoteCompareAction {
  riskObject: string;
  offer: LouiseOffer;
}

interface AcknowledgementsObject {
  [x: string]: AcknowledgementPayload[];
}

interface AddTerminationInfoAction {
  riskObject: string;
  data: CancelledInsuranceValues | string;
}

interface TextChange {
  quoteId: string;
  text: string;
}

interface OfferGenerationAction {
  quoteId: string;
  offerId: string;
}

interface ChosenRiskObjectsForTarifAction {
  [riskObject: string]: string;
}

interface ShownProductAction {
  pageId: string;
  productIds: string[];
}

interface QuoteSpecificationAction {
  productKey: string;
  quoteId: string;
  value: QuoteSpecifications;
}

interface QuoteVariableSpecificationAction {
  productKey: string;
  quoteId: string;
  value: {
    [x: string]: number | undefined;
  }[];
}

interface OfferCombinationErrorAction {
  quoteId: string;
  value: boolean;
}

interface OfferSelectedGuaranteesAction {
  riskObject: string;
  company: string;
  value: string[];
}

export interface RiskObjectQuotes {
  [x: string]: { [x: string]: LouiseQuote };
}

// Empty string is a small hack to avoid thousands of type errors
export type CarFilter = 'omnium' | 'mini_omnium' | 'civil_liability' | '';

export interface QuoteSpecifications {
  save_quote?: boolean;
  bonus_malus?: number;
  ck?: number;
  vk?: number;
  currency?: string;
  evaluation_car?: string;
  payment_interval?: string;
  insured_value?: number;
  insured_content_value?: number;
  franchise?: {
    type: string;
    value: number;
  };
  evaluation_building?: string;
  modifications?: {
    [x: string]: string;
  }[];
}

export interface OffersInfo {
  insuranceTerminationInfo: {
    [riskObject: string]: CancelledInsuranceValues | string | undefined;
  };
  requiredInformation: {
    [quoteId: string]: string;
  };
  contractStartDate: {
    [quoteId: string]: string;
  };
  customerSheet: string;
  reasonOfContact: string;
  sendMailToProspect?: boolean;
  acknowledgements: AcknowledgementsObject;
  offerCombinationError: {
    [quoteId: string]: boolean;
  };
}

export interface OffersShownProduct {
  hashId: string;
  price?: number;
  quality?: number;
}

export type ShownProductOrder =
  | 'PRICE_ASC'
  | 'PRICE_DESC'
  | 'QUALITY_ASC'
  | 'QUALITY_DESC';

export interface OffersController {
  // the data inside the offerSlice is connected to the cachedSessionId
  cachedSessionId: string | null;
  prospectId: string;
  insuranceCompanies: InsuranceCompany[];
  riskObjectIds: {
    [x: string]: RiskObjectId;
  };
  smtSessionObject: SmtSessionObject;
  selectedOffers: { [x: string]: string[] };
  comparisonMatrixOffers: { [x: string]: LouiseOffer[] };
  offersInfo: OffersInfo;
  preferredOffers: {
    [riskObject: string]: string;
  };
  visitedOverviewPages: string[];
  quoteIdToUrl: {
    [id: string]: string;
  };
  offerSelectedGuarantees: {
    [riskObject: string]: {
      [activeFilter: string]: { [company: string]: string[] };
    };
  };
  generatedOffersForQuotes: {
    [id: string]: string;
  };
  terminationLetterModalType: string;
  shownProductIds: { [pageId: string]: string[] };
  /** loadedProducts is needed to determine if the page loading is complete */
  loadedProductIds: string[];
  /** shownProducts will be used to determine the order via the orderButton */
  shownProducts: OffersShownProduct[];
  /** Sortbutton will change this value, this value will be used to order the products in the overview component */
  shownProductsOrder?: ShownProductOrder;
  activeCarQuoteFilter: CarFilter;
  quoteSpecs: {
    [quoteId: string]: QuoteSpecifications;
  };
  chosenRiskObjectsForTarif: {
    [riskObject: string]: string;
  };
  productKeysToId: {
    [id: string]: string;
  };
}

/* * * * * * * * * * * * * * *
 * SELECTORS
 * * * * * * * * * * * * * * */

export const areAllAcknowledgementsAnsweredSelector = createSelector(
  (state: RootState) => state.offers.offersInfo.acknowledgements,
  (acknowledgements) => {
    const flatAcknowledgements = Object.values(acknowledgements)
      .flat()
      .filter((val) => !!val);

    return (
      flatAcknowledgements.length !== 0 &&
      flatAcknowledgements.every((ack) => ack?.agreed != null)
    );
  },
);

/* * * * * * * * * * * * * * *
 * THUNKS
 * * * * * * * * * * * * * * */

export const persistOffersState = createAsyncThunk(
  'offers/persistOffersState',
  (sessionId: string, { getState }) => {
    return persistState('offers', sessionId, getState);
  },
);

export const loadOffersState = createAsyncThunk(
  'offers/loadOffersState',
  (sessionId: string) => {
    return loadState('offers', sessionId);
  },
);

/* * * * * * * * * * * * * * *
 * SLICES
 * * * * * * * * * * * * * * */

const initialState: OffersController = {
  visitedOverviewPages: [],
  cachedSessionId: null,
  prospectId: '',
  insuranceCompanies: [],
  riskObjectIds: {},
  smtSessionObject: {},
  selectedOffers: {},
  comparisonMatrixOffers: {},
  offersInfo: {
    insuranceTerminationInfo: {},
    requiredInformation: {},
    contractStartDate: {},
    acknowledgements: {},
    customerSheet: '',
    reasonOfContact: '',
    offerCombinationError: {},
  },
  preferredOffers: {},
  quoteIdToUrl: {},
  offerSelectedGuarantees: {},
  generatedOffersForQuotes: {},
  terminationLetterModalType: '',
  shownProductIds: {},
  activeCarQuoteFilter: '',
  shownProducts: [],
  quoteSpecs: {},
  chosenRiskObjectsForTarif: {},
  productKeysToId: {},
  loadedProductIds: [],
};

const offersController = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    addQuoteIdsToUrl(
      state,
      action: PayloadAction<{
        [id: string]: string;
      }>,
    ) {
      state.quoteIdToUrl = { ...state.quoteIdToUrl, ...action.payload };
    },
    setShownProducts(state, action: PayloadAction<OffersShownProduct[]>) {
      state.shownProducts = action.payload;
    },
    setLoadedProductIds(state, action: PayloadAction<string[]>) {
      state.loadedProductIds = action.payload;
    },
    updateLoadedProductIds(state, action: PayloadAction<string>) {
      if (!state.loadedProductIds) {
        state.loadedProductIds = [action.payload];
      } else {
        const unfiltered = [...state.loadedProductIds, action.payload];
        state.loadedProductIds = uniq(unfiltered);
      }
    },
    updateShownProducts(state, action: PayloadAction<OffersShownProduct>) {
      if (
        state.shownProducts.some(
          ({ hashId }) => hashId === action.payload.hashId,
        )
      ) {
        state.shownProducts = [...state.shownProducts].map((prod) =>
          prod.hashId === action.payload.hashId ? action.payload : prod,
        );
      } else {
        state.shownProducts.push(action.payload);
      }
    },
    setShownProductsOrder(
      state,
      action: PayloadAction<ShownProductOrder | undefined>,
    ) {
      state.shownProductsOrder = action.payload;
    },
    addProductKeysToId(
      state,
      action: PayloadAction<{
        [id: string]: string;
      }>,
    ) {
      state.productKeysToId = { ...state.productKeysToId, ...action.payload };
    },
    setInsuranceCompanies(state, action: PayloadAction<InsuranceCompany[]>) {
      state.insuranceCompanies = action.payload;
    },
    setSmtSessionObject(state, action: PayloadAction<SmtSessionObject>) {
      state.smtSessionObject = action.payload;
    },
    setProspectId(state, action: PayloadAction<string>) {
      state.prospectId = action.payload;
    },
    setCachedSessionId(state, action: PayloadAction<string>) {
      state.cachedSessionId = action.payload;
    },
    setRiskObjectIds(state, action: PayloadAction<RiskObjectAction>) {
      const { type, riskObjectId, riskObjectRevisionId } = action.payload;
      state.riskObjectIds[type] = {
        riskObjectId,
        riskObjectRevisionId,
      };
    },
    setAcknowledgmeents(state, action: PayloadAction<AcknowledgementAction>) {
      const { type, acknowledgements } = action.payload;
      state.offersInfo.acknowledgements[type] = acknowledgements;
    },
    answerAcknowledgmeent(
      state,
      action: PayloadAction<AcknowledgementAnswerAction>,
    ) {
      const { type, acknowledgementKey, answer } = action.payload;
      const index = state.offersInfo.acknowledgements[type]
        ?.map(({ key }) => key)
        .indexOf(acknowledgementKey);

      if (state.offersInfo.acknowledgements?.[type]?.[index])
        state.offersInfo.acknowledgements[type][index].agreed = answer;
    },
    removeAcknowledgementByType(state, action: PayloadAction<string>) {
      delete state.offersInfo.acknowledgements[action.payload];
    },
    addToSelectedOffers(state, action: PayloadAction<AddQuoteAction>) {
      const { riskObject, id } = action.payload;

      if (!state.selectedOffers[riskObject])
        state.selectedOffers[riskObject] = [];

      if (state.selectedOffers[riskObject].length === 0)
        // Set the preferred offer when it's the only one being added
        state.preferredOffers[riskObject] = id;
      else if (state.selectedOffers[riskObject].length === 1)
        // Reset if another one get's added
        delete state.preferredOffers[riskObject];

      state.selectedOffers[riskObject] = uniq([
        ...state.selectedOffers[riskObject],
        id,
      ]);
    },
    removeFromSelectedOffers(state, action: PayloadAction<AddQuoteAction>) {
      const { riskObject, id } = action.payload;

      // Remove from selected offers itself
      state.selectedOffers[riskObject] = state.selectedOffers[
        riskObject
      ].filter((quoteId) => quoteId !== id);

      // If only 1 left, automatically set as preferred
      if (state.selectedOffers[riskObject].length === 1)
        state.preferredOffers[riskObject] = state.selectedOffers[riskObject][0];

      // If empty, remove key from selected offers
      if (state.selectedOffers[riskObject].length === 0) {
        delete state.preferredOffers[riskObject];
        delete state.selectedOffers[riskObject];
      }
    },
    addToComparisonMatrix(state, action: PayloadAction<AddQuoteCompareAction>) {
      const { riskObject, offer } = action.payload;

      if (!state.comparisonMatrixOffers[riskObject])
        state.comparisonMatrixOffers[riskObject] = [];

      if (
        !state.comparisonMatrixOffers[riskObject].find(
          ({ id }) => id === offer.id,
        )
      )
        state.comparisonMatrixOffers[riskObject] = [
          ...state.comparisonMatrixOffers[riskObject],
          offer,
        ];
    },
    removeFromComparisonMatrix(state, action: PayloadAction<AddQuoteAction>) {
      const { riskObject, id } = action.payload;

      state.comparisonMatrixOffers[riskObject] = state.comparisonMatrixOffers[
        riskObject
      ].filter(({ id: _id }) => _id !== id);
    },
    clearComparisonMatrix(state) {
      state.comparisonMatrixOffers = {};
    },
    setTerminationInfo(state, action: PayloadAction<AddTerminationInfoAction>) {
      const { riskObject, data } = action.payload;

      state.offersInfo.insuranceTerminationInfo[riskObject] = data;
    },
    clearTerminationInfo(state, action: PayloadAction<{ riskObject: string }>) {
      const { riskObject } = action.payload;
      state.offersInfo.insuranceTerminationInfo[riskObject] = undefined;
    },
    setPreferredOffer(state, action: PayloadAction<AddQuoteAction>) {
      const { riskObject, id } = action.payload;
      state.preferredOffers[riskObject] = id;
    },
    setRequiredInformation(state, action: PayloadAction<TextChange>) {
      const { quoteId, text } = action.payload;
      state.offersInfo.requiredInformation[quoteId] = text;
    },
    setReasonOfContact(state, action: PayloadAction<string>) {
      state.offersInfo.reasonOfContact = action.payload;
    },
    setContractStartDate(state, action: PayloadAction<TextChange>) {
      const { quoteId, text } = action.payload;
      state.offersInfo.contractStartDate[quoteId] = text;
    },
    addVisitedOverviewPage(state, action: PayloadAction<string>) {
      if (!state.visitedOverviewPages.includes(action.payload)) {
        state.visitedOverviewPages.push(action.payload);
      }
    },
    setOfferIdForQuote(state, action: PayloadAction<OfferGenerationAction>) {
      const { quoteId, offerId } = action.payload;
      state.generatedOffersForQuotes[quoteId] = offerId;
    },
    setCustomerSheet(state, action: PayloadAction<string>) {
      state.offersInfo.customerSheet = action.payload;
    },
    setSendMailToProspect(state, action: PayloadAction<boolean>) {
      state.offersInfo.sendMailToProspect = action.payload;
    },
    setTerminationLetterModal(state, action: PayloadAction<string>) {
      state.terminationLetterModalType = action.payload;
    },
    setShownProductsIds(state, action: PayloadAction<ShownProductAction>) {
      const { pageId: riskObject, productIds } = action.payload;
      state.shownProductIds[riskObject] = productIds;
    },
    resetShownProductsIds(state) {
      state.shownProductIds = {};
    },
    setActiveCarQuoteFilter(state, action: PayloadAction<CarFilter>) {
      state.activeCarQuoteFilter = action.payload;
    },
    addToQuoteSpecifications(
      state,
      action: PayloadAction<QuoteSpecificationAction>,
    ) {
      const { quoteId, productKey, value } = action.payload;
      state.quoteSpecs[quoteId] = {
        ...state.quoteSpecs[quoteId],
        ...value,
        save_quote: true,
      };
      state.quoteSpecs[productKey] = {
        ...state.quoteSpecs[productKey],
        ...value,
        save_quote: true,
      };
    },
    addVariableSpecsToQuoteSpecifications(
      state,
      action: PayloadAction<QuoteVariableSpecificationAction>,
    ) {
      const { quoteId, productKey, value } = action.payload;
      state.quoteSpecs[quoteId] = {
        ...state.quoteSpecs[quoteId],
        ...value,
        save_quote: true,
      };
      state.quoteSpecs[productKey] = {
        ...state.quoteSpecs[productKey],
        ...value,
        save_quote: true,
      };
    },
    setOfferCombinationError(
      state,
      action: PayloadAction<OfferCombinationErrorAction>,
    ) {
      const { quoteId, value } = action.payload;
      state.offersInfo.offerCombinationError[quoteId] = value;
    },
    setOfferSelectedGuarantees(
      state,
      action: PayloadAction<OfferSelectedGuaranteesAction>,
    ) {
      const { company, value, riskObject } = action.payload;
      if (!state.offerSelectedGuarantees[riskObject])
        state.offerSelectedGuarantees[riskObject] = {};
      if (
        !state.offerSelectedGuarantees[riskObject][state.activeCarQuoteFilter]
      )
        state.offerSelectedGuarantees[riskObject][state.activeCarQuoteFilter] =
          {};
      state.offerSelectedGuarantees[riskObject][state.activeCarQuoteFilter][
        company
      ] = value;
    },
    setChosenRiskObjectsForTarif(
      state,
      action: PayloadAction<ChosenRiskObjectsForTarifAction>,
    ) {
      state.chosenRiskObjectsForTarif = {
        ...state.chosenRiskObjectsForTarif,
        ...action.payload,
      };
    },
    resetOffers() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadOffersState?.fulfilled, (state, action) => {
      if (action?.payload)
        Object.entries(action?.payload as OffersController).forEach(
          ([key, data]) => (state[key] = data),
        );
    });
  },
});

export const {
  addQuoteIdsToUrl,
  setInsuranceCompanies,
  setSmtSessionObject,
  setProspectId,
  setRiskObjectIds,
  addToSelectedOffers,
  removeFromSelectedOffers,
  addToComparisonMatrix,
  addVariableSpecsToQuoteSpecifications,
  removeFromComparisonMatrix,
  setTerminationInfo,
  clearTerminationInfo,
  clearComparisonMatrix,
  setPreferredOffer,
  setRequiredInformation,
  setReasonOfContact,
  setContractStartDate,
  setAcknowledgmeents,
  answerAcknowledgmeent,
  removeAcknowledgementByType,
  setCachedSessionId,
  addVisitedOverviewPage,
  setOfferIdForQuote,
  setCustomerSheet,
  setSendMailToProspect,
  setTerminationLetterModal,
  setShownProductsIds,
  resetShownProductsIds,
  setActiveCarQuoteFilter,
  addToQuoteSpecifications,
  setOfferCombinationError,
  setOfferSelectedGuarantees,
  resetOffers,
  setChosenRiskObjectsForTarif,
  addProductKeysToId,
  setShownProducts,
  setShownProductsOrder,
  updateShownProducts,
  updateLoadedProductIds,
  setLoadedProductIds,
} = offersController.actions;

export default offersController.reducer;
