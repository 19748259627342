import {
  Icon,
  SearchSelect,
  HStack,
  InputGroup,
  Select,
  SelectProps,
} from '../../../../index';
import React, { ChangeEvent } from 'react';
import COUNTRY_LIST from './constants/countrylist.json';
import '../../../lib/flag-icons.css';
import Flag from './Flag';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string | null;
  onChange?: (country: string | null) => void;
  lang: string;
  onlyFlag?: boolean;
  isPrefilled?: boolean;
  /** Array of language codes that will show in the countrypicker */
  supportedCountries?: string[];
  /** Determines a different styling if it is attached */
  isAttached?: boolean;
}

const CountrySelectWithFlag: React.FC<
  Props & Omit<SelectProps, 'onChange'>
> = ({
  lang,
  value,
  onlyFlag,
  isPrefilled,
  onChange = () => null,
  supportedCountries,
  isAttached,
  ...props
}) => {
  const { t } = useTranslation();
  const countryOptions = Object.entries(COUNTRY_LIST.countries[lang])
    .map(([value, label]) => ({ label: label as string, value }))
    .filter(({ value }) =>
      supportedCountries?.length
        ? supportedCountries.includes(value.toUpperCase())
        : true,
    );

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    onChange(value);
  };

  /** Small input (only flag) */
  if (onlyFlag) {
    return (
      <InputGroup w="unset" position="relative" display="inline-block">
        <Select
          data-test-id="DESIGN-SYSTEM_COUNTRY-SELECT-WITH-FLAG"
          value={value}
          cursor="pointer"
          onChange={handleChange}
          w="16"
          _focusVisible={{
            boxShadow: '0',
          }}
          color="white"
          css={{
            option: {
              color: 'black',
            },
          }}
          {...props}
          {...(value != null &&
            isPrefilled && {
              // prefilledInputComponentStyling -> flows package
              sx: {
                borderColor: '#FFBC0F',
                borderRightColor: '#FFF9EA',
                backgroundColor: '#FFF9EA',
                _hover: {},
              },
            })}
          {...(isAttached && {
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
            borderRightColor: 'white',
          })}
        >
          <option value="">{t('CHOOSE_OPTION')}</option>
          {countryOptions.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
        <HStack
          position="absolute"
          pointerEvents="none"
          top="0"
          left="0"
          px="2"
          w="100%"
          h="100%"
          justify="space-between"
          spacing="0.5"
        >
          <Flag country={value} />
          <Icon name="GeneralActionsCaretDown" color="gray.300" />
        </HStack>
      </InputGroup>
    );
  }

  return (
    <SearchSelect
      leftSection={<Flag country={value} />}
      data={countryOptions}
      comboboxProps={{ withinPortal: true }}
      onChange={onChange}
      value={value}
      prefilled={isPrefilled}
      clearable
      isDisabled={props.isDisabled}
    />
  );
};

export default CountrySelectWithFlag;
