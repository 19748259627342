import { useMutation } from 'react-query';
import { IconName } from '@wegroup/design-system';
import { wgApi } from '../../../../../app/src/features/api/wgApi';

interface InsuranceTermsBotChatResponse {
  id: string;
  message: string;
  company: string;
  subject: string;
  created_at: string;
  resources?: InsuranceTermsBotResource[];
}

export interface InsuranceTermsBotChunk {
  text: string;
  start_char_idx: number;
  end_char_idx: number;
  response_sent: string;
  score: number;
}
export interface InsuranceTermsBotResourceSection {
  title: string | null;
  reference: string | null;
  page: number;
  text: string;
  chunks: InsuranceTermsBotChunk[];
}

export interface InsuranceTermsBotResource {
  created_at: string;
  filename: string;
  language: string;
  ref: number;
  version: string;
  link: string;
  name: string;
  type: string;
  icon_key: IconName;
  section: InsuranceTermsBotResourceSection;
}

interface Params {
  insuranceCompany: string;
  insuranceSubject: string;
  message: string;
}

const postInsuranceTermsBotChat = async (payload: Params) => {
  const { data, isError } = await wgApi.post<InsuranceTermsBotChatResponse>(
    `/v1/api/insurance-terms-bot/chat`,
    {
      message: payload.message,
      insurance_company: payload.insuranceCompany,
      insurance_subject: payload.insuranceSubject,
    },
  );
  if (isError) throw new Error();
  return data;
};

export const usePostInsuranceTermsBotChat = () => {
  return useMutation(postInsuranceTermsBotChat);
};
