import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersMachinery: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.75 11.175a2 2 0 012-2h8.333a2 2 0 011.79 1.106l6.25 12.5a2 2 0 01.21.895v2.166h2.25V11.176a2 2 0 014 0v18.833h4.25a2 2 0 110 4h-6.25a2 2 0 01-2-2v-2.167h-2.25v6.334a6.167 6.167 0 01-12 2H16.25a6.166 6.166 0 01-12-2v-12.5a2 2 0 012-2h2.167v-6.334a2 2 0 012-2h6.333v-2.167zm0 6.167h-4.333v4.334h4.333v-4.334zm4 4.334v-8.5h5.097l4.25 8.5H20.75zm-12.5 4v4.726a6.167 6.167 0 018 3.773h7.083a6.167 6.167 0 018-3.773v-4.727H8.25zm23.083 10.5a2.166 2.166 0 10-4.332 0 2.166 2.166 0 004.332 0zm-23.083 0a2.167 2.167 0 104.333 0 2.167 2.167 0 00-4.333 0z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersMachinery;
