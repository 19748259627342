/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { mq } from '../utils/styledComponentsUtils';

// Components
import { H1, Text } from 'wg-fe-ui';
import LargeButton from './LargeButton';
import ErrorPageSVG from '../../../common/src/assets/images/ErrorPageSvg';
import BrokerInfoModal from '../../../flows/src/components/ui/advisory-flow/components/broker-info/BrokerInfoModal';
import { Text as ChakraText } from '@wegroup/design-system';
import { isSocialLoginUrl } from '../../../app/src/features/authentication/constants/SocialAuth';

interface Props {
  error?: string;
}

const ErrorPage: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  return (
    <Container data-test-id="errorpage-component">
      <BrokerInfoModal setShowModal={setShowModal} showModal={showModal} />
      <ErrorPageSVG />
      <StyledH1>{t('Looks like something went wrong')}</StyledH1>
      {error ? (
        <ChakraText
          dangerouslySetInnerHTML={{ __html: error }}
          color="gray.500"
          textStyle="h5Headline"
        />
      ) : (
        <StyledText color="#8990a3">
          {isSocialLoginUrl
            ? t(
                'We are sorry, it seems like something went wrong on our side You can find the information for contacting your broker by clicking on the button below',
              )
            : t(
                'We are sorry, it seems like something went wrong on our side Please contact customer support',
              )}
        </StyledText>
      )}

      {isSocialLoginUrl && (
        <ContactBrokerButton
          level="secondary"
          onClick={() => setShowModal(true)}
          wide
        >
          {t('Contact your broker')}
        </ContactBrokerButton>
      )}
    </Container>
  );
};

const StyledText = styled(Text)`
  margin-bottom: 3.2rem;
`;

const StyledH1 = styled(H1)`
  margin-bottom: 1.6rem;
`;

const ContactBrokerButton = styled(LargeButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  margin-top: 3.2rem;
  ${mq.laptop} {
    margin-top: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2.4rem;

  overflow: auto;
  text-align: center;

  > p {
    max-width: 70rem;
  }

  > svg {
    min-height: 10rem;
    max-width: 30rem;
  }
`;

export default ErrorPage;
