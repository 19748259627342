import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { mq } from '../utils/styledComponentsUtils';

import { LoadingSpinner } from 'wg-fe-ui';

type levelTypes = 'primary' | 'secondary';

interface Props {
  loading?: boolean;
  level?: levelTypes;
  disabled?: boolean;
  wide?: boolean;
  fullwidth?: boolean;
  onClick: (arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDisabledClick?: (
    arg0: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  children: React.ReactNode;
  Icon?: React.ReactElement;
  iconPosition?: 'left' | 'right';
  dataTestId?: string;
}

const LargeButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      loading,
      level = 'primary',
      onClick,
      onDisabledClick,
      disabled,
      fullwidth = false,
      children,
      Icon = null,
      wide,
      iconPosition = 'right',
      dataTestId = 'ProdFac_LargeButton',
      ...rest
    },
    ref,
  ) => {
    const handleOnClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      if (loading) return;
      if (disabled) onDisabledClick && onDisabledClick(e);
      else onClick(e);
    };

    return (
      <Button
        onClick={handleOnClick}
        level={level}
        disable={disabled || loading}
        fullwidth={fullwidth}
        wide={wide}
        data-test-id={!disabled ? dataTestId : undefined}
        ref={ref}
        {...rest}
      >
        {loading ? (
          <Spinner>
            <LoadingSpinner />
          </Spinner>
        ) : (
          <>
            {iconPosition === 'left' && <LeftIcon>{Icon}</LeftIcon>}
            {children}
            {iconPosition === 'right' && <RightIcon>{Icon}</RightIcon>}
          </>
        )}
      </Button>
    );
  },
);

const Spinner = styled.div`
  width: 2.4rem;
  height: 2rem;
  border-radius: 50%;
  margin: auto;

  svg {
    width: 100%;
    height: 100%;
    min-width: initial;
    min-height: initial;
    transform-origin: initial;

    circle {
      stroke-width: 7px;
      stroke: white;
    }
  }
`;

const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
`;

const LeftIcon = styled(IconContainer)`
  left: 1.5rem;
`;

const RightIcon = styled(IconContainer)`
  right: 1.5rem;
`;

const Button = styled.button<{
  level?: levelTypes;
  disable?: boolean;
  fullwidth?: boolean;
  wide?: boolean;
}>`
  position: relative;
  background-color: ${({ theme, level }) =>
    level === 'primary' ? theme.primary[500] : theme.primary[50]};
  border: none;
  border-radius: 5rem;
  color: ${({ theme, level }) =>
    level === 'primary' ? '#fff' : theme.primary[500]};
  font-weight: 500;
  font-size: 1.6rem;
  min-height: 4.4rem;
  line-height: 2rem;
  text-align: center;
  min-width: ${({ fullwidth, wide }) =>
    fullwidth ? '100%' : wide ? '28.8rem' : '14.4rem'};
  width: ${({ fullwidth }) => (fullwidth ? '100%' : 'auto')};
  padding: 4px 4rem;
  cursor: pointer;
  text-transform: ${({ theme }) => (theme['isImmo'] ? 'uppercase' : 'none')};
  &:focus {
    border: none;
    outline: none;
  }

  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme, level }) =>
      level === 'primary' ? theme.primary[400] : theme.primary[100]};
  }

  ${mq.mobileM} {
    min-width: ${({ fullwidth, wide }) =>
      fullwidth ? '100%' : wide ? '28.8rem' : '18.4rem'};
  }

  ${mq.mobileL} {
    height: 5.4rem;
  }

  ${({ disable }) =>
    disable &&
    css`
      background-color: #999999;
      opacity: 0.8;
      cursor: not-allowed;

      &:hover {
        background-color: #999999;
      }
    `}
`;

export default LargeButton;
