import { IconName } from '@wegroup/design-system';
import { camelCase, startCase } from 'lodash';

const convertKeyToIconName = (
  name: string,
  // Insurance type should be removed once BE sends icon keys
  insuranceType?: string,
): IconName => {
  let iconName: IconName;
  if (/mini_omnium.*/.test(name) && insuranceType !== 'boat') {
    iconName = 'IconBAPlusFilled';
  } else if (/omnium.*/.test(name) && insuranceType !== 'boat') {
    iconName = 'IconBAPlusPlusFilled';
  } else {
    switch (name) {
      case 'civil_liability_continuous':
        iconName = 'RiskObjectsFlightMissed';
        break;
      case 'civil_liability_short_term':
        iconName = 'RiskObjectsPropertyBaggageLuggage';
        break;
      case 'upload':
        iconName = startCase(name) as IconName;
        break;
      case 'residence':
      case 'home':
        iconName = 'IconPropertyFilled';
        break;
      case 'civil_liability_asr':
      case 'civil_liability_ex_amlin':
      case 'civil_liability_unigarant':
      case 'omnium':
      case 'civil_liability':
        if (insuranceType === 'family') {
          iconName = 'IconFamilyFilled';
        } else if (insuranceType === 'boat') {
          iconName = 'GeneralRiskObjectsBoat';
        } else {
          iconName = 'IconCarFilled';
        }
        break;

      case 'vehicle_at_rest':
      case 'vehicle':
      case 'car':
      case 'oldtimer':
      case 'assistance_tyre':
        iconName = 'IconCarFilled';
        break;
      case 'legal_plus':
        iconName = 'IconLegalFilled';
        break;
      case 'notification':
        iconName = 'IconNotificationBellFilled';
        break;
      case 'mechanic_breakdown':
        iconName = 'IconMechanicalBreakdownFilled';
        break;
      case 'nature':
        iconName = 'IconNaturalDisasterFilled';
        break;
      case 'road_user':
      case 'assistance_vehicle':
        iconName = 'IconAssistanceCarFilled';
        break;
      case 'secondhand_car':
        iconName = 'IconSecondHandCarFilled';
        break;
      case 'liability_building':
        iconName = 'IconLiabilitybuildingFilled';
        break;
      case 'action_dropdown':
        iconName = 'IconActionDropDown';
        break;
      case 'action_left':
        iconName = 'IconActionChevronLeft';
        break;
      case 'action_right':
        iconName = 'IconActionChevronRight';
        break;
      case 'action_unread':
        iconName = 'IconActionUnRead';
        break;
      case 'active_pv':
        iconName = 'IconActivePVFilled';
        break;
      case 'fiscal':
      case 'premium':
        iconName = 'IconQualityFilled';
        break;
      case 'content':
        iconName = 'IconFurnitureFilled';
        break;
      case 'full_assistance':
      case 'ba_plus_plus':
        iconName = 'IconBAPlusPlusFilled';
        break;
      case 'ba_plus':
        iconName = 'IconBAPlusFilled';
        break;
      case 'passengers':
        iconName = 'IconPassengerFilled';
        break;
      case 'mini_omnium_xl':
      case 'ba_xl':
        iconName = 'IconBAXL';
        break;
      case 'omnium_xl':
      case 'ba_plus_plus_xl':
        iconName = 'IconBAPlusPlusXL';
        break;
      case 'status':
        iconName = 'StatusIcon';
        break;
      case 'emergency_breaking':
        iconName = 'IconFrontEmergencyBraking';
        break;
      case 'abs':
        iconName = 'IconABS';
        break;
      case 'safety':
        iconName = 'IconSafetySystems';
        break;
      case 'distance':
        iconName = 'IconDistances';
        break;
      case 'help':
        iconName = 'IconSupport';
        break;
      case 'property_plus':
      case 'surround':
        iconName = 'IconPropertyExtraFilled';
        break;
      case 'financial':
        iconName = 'IconSavingFilled';
        break;
      case 'rent_loss':
        iconName = 'GeneralOthersWallet';
        break;
      case 'assistance':
        if (insuranceType === 'funeral') {
          iconName = 'IconPersonalLiabilityFilled';
        } else if (insuranceType === 'boat') {
          iconName = 'GeneralRiskObjectsBoat';
        } else {
          iconName = 'IconLaneControlFilled';
        }
        break;
      case 'bereavement_care':
        iconName = 'IconFamilyFilled';
        break;
      case 'indirect_losses':
        iconName = 'IconTenPercentFilled';
        break;
      case 'business':
        iconName = 'IconBaggageFilled';
        break;
      case 'leisure':
      case 'theft_plus':
      case 'assistance_theft':
        iconName = 'IconTheftFilled';
        break;
      case 'support_after_fire':
        iconName = 'IconFireFilled';
        break;
      case 'liability':
        iconName = 'RiskObjectsPropertyLiabilityBuilding';
        break;
      case 'private_life':
      case 'private_life_bronze':
      case 'private_life_silver':
      case 'private_life_gold':
        iconName = 'IconPedestrian';
        break;
      case 'contracts':
        iconName = 'IconDocumentFilled';
        break;
      case 'traffic':
        iconName = 'IconMobilityFilled';
        break;
      case 'nature_protect':
        iconName = 'IconGardenFilled';
        break;
      case 'road_assistance':
        iconName = 'IconECallFilled';
        break;
      case 'moped':
        iconName = 'IconLightScooter';
        break;
      case 'bicycle':
        iconName = 'IconBikeFilled';
        break;
      case 'motorcycle':
        iconName = 'IconMotor';
        break;
      /** Boats */
      case 'accidents':
      case 'civil_liability_yacht_and_co':
      case 'omnium_all_risk':
      case 'omnium_all_risk_asr':
      case 'omnium_all_risk_ex_amlin':
      case 'omnium_all_risk_unigarant':
      case 'omnium_all_risk_yacht_and_co':
      case 'omnium_asr':
      case 'omnium_ex_amlin':
      case 'omnium_unigarant':
      case 'omnium_yacht_and_co':
      case 'boat':
        iconName = 'GeneralRiskObjectsBoat';
        break;
      case 'camper':
        iconName = 'GeneralRiskObjectsCamper';
        break;

      case 'assistance_accident':
        iconName = 'IconAccidentFilled';
        break;
      case 'assistance_breakdown_puncture':
        iconName = 'IconExtraCarDamageFilled';
        break;
      case 'electric_bicycle':
        iconName = 'RiskObjectsVehicleEbike';
        break;

      case 'two_wheeler':
        iconName = 'IconHeavyScooter';
        break;
      case 'travel':
        iconName = 'GeneralRiskObjectsFlight';
        break;
      case 'action_close':
      case 'action_marker':
      case 'action_phone':
      case 'action_power':
      case 'action_eye':
      case 'action_eye_crossed':
      case 'action_read':
      case 'action_plus':
      case 'action_message':
      case 'status_exclamation':
      case 'status_check':
      case 'student':
      case 'dashboard':
      case 'close_family':
      case 'heavy_work':
      case 'parking_aids':
      case 'pedestrian':
      case 'print':
      case 'stats':
      case 'woman':
      case 'porch':
      case 'electric_bike':
      case 'heavy_scooter':
      case 'light_scooter':
      case 'motor':
      case 'company_car':
      case 'six_wheeler':
      case 'minus':
      case 'chevron_down':
      case 'clipboard':
      case 'hyperlink':
      case 'popup':
      case 'custom_link':
      case 'star':
      case 'car_glass_breakage':
      case 'car_collision':
      case 'print_white':
      case 'compare':
      case 'arrow':
      case 'bread_crumb_chevron_right':
      case 'speed_assistance':
      case 'rear_cross_traffic_warning':
      case 'rear_emergency_breaking':
      case 'lane_centering_assist':
      case 'lane_depart_warning':
      case 'pedestrian_detection':
      case 'performance':
      case 'electronic_stability_control':
      case 'forward_collision_warning':
      case 'city_emergency_braking':
      case 'safety_systems':
      case 'safety_systems_plus':
      case 'thumbs_up':
      case 'thumbs_down':
      case 'measure_plus':
      case 'rooms':
      case 'appartment':
      case 'attached':
      case 'semi_detached':
      case 'full_screen':
      case 'search':
      case 'star_filled':
      case 'star_outline':
      case 'star_half_filled':
      case 'pencil_edit':
      case 'expand_screen':
      case 'chevron_leftRounded':
      case 'funeral':
      case 'writing':
      case 'user':
      case 'user_circle':
      case 'shrink_screen':
      case 'origin_connection':
      case 'calendar':
      case 'celandar_time':
        iconName = `Icon${startCase(camelCase(name)).replace(
          / /g,
          '',
        )}` as IconName;
        break;
      case 'fire':
      case 'theft':
      case 'others':
      case 'tax':
      case 'legal':
      case 'flight':
      case 'consumer':
      case 'oil':
      case 'history':
      case 'income':
      case 'electricity':
      case 'thawing':
      case 'water_damage':
      case 'snow_pressure':
      case 'cracks':
      case 'dry_rot':
      case 'scorch_damage':
      case 'property':
      case 'baggage':
      case 'natural_disaster':
      case 'assistance_person':
      case 'new_car':
      case 'looking_for_car':
      case 'bought_car':
      case 'accident':
      case 'web_world':
      case 'web':
      case 'personal_liability':
      case 'landlord':
      case 'tenant':
      case 'glass_breakage':
      case 'settings':
      case 'underwriting':
      case 'claims':
      case 'toolkit':
      case 'family':
      case 'witness':
      case 'quality':
      case 'sms':
      case 'work_accident':
      case 'id':
      case 'document':
      case 'attic':
      case 'basement':
      case 'passive_house':
      case 'construction':
      case 'stone':
      case 'corn':
      case 'measure':
      case 'jewelry':
      case 'art':
      case 'furniture':
      case 'garage':
      case 'guests':
      case 'property_extra':
      case 'home_office':
      case 'pool':
      case 'shed':
      case 'garden':
      case 'vacation':
      case 'surroundings':
      case 'e_step':
      case 'bike':
      case 'shared_economy':
      case 'solar_panels':
      case 'disability':
      case 'children':
      case 'computer':
      case 'all_risks':
      case 'ten_percent':
      case 'drone':
      case 'emergency_brake':
      case 'cruise_control':
      case 'support':
      case 'blind_spot':
      case 'damage_animals':
      case 'lane_control':
      case 'replacement':
      case 'attention_assistance':
      case 'e_call':
      case 'coverage':
      case 'joker':
      case 'extra_object_damage':
      case 'driver':
      case 'extra_car_damage':
      case 'mirror_breakage':
      case 'parking':
      case 'travelling':
      case 'search_car':
      case 'saving':
      case 'flight_accepted':
      case 'flight_canceled':
      case 'flight_delayed':
      case 'flight_missed':
      case 'cancel_flight':
      case 'mobility':
      case 'certificate':
      case 'injured':
      case 'drag_handler':
      case 'plus':
      case 'pencil':
      case 'cabrio':
      case 'suv':
      case 'engine':
      case 'info':
      case 'passenger':
      case 'health':
      case 'download':
      case 'van':
      case 'jeep':
      case 'shopping_cart':
      case 'shopping_basket':
      case 'bedroom':
      case 'bathroom':
      case 'dressing':
      case 'fireplace':
      case 'kitchen':
      case 'other_rooms':
      case 'playroom':
      case 'private_home_office':
      case 'storage_room':
      case 'washing_room':
      case 'wellness_room':
      case 'reset':
      case 'clock':
      case 'high_speed_emergency_breaking':
      case 'house_plus':
      case 'empty_circle':
        iconName = `Icon${startCase(camelCase(name)).replace(
          / /g,
          '',
        )}Filled` as IconName;
        break;
      default:
        iconName = 'IconOthersFilled';
    }
  }

  return iconName;
};

export default convertKeyToIconName;
