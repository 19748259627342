import { ProspectObject } from '../../../../types/SMT';
import { Party } from '../types';

type PartyParam = ProspectObject | Partial<Party>;

/**
 * Returns the party name based on the fact if it is a CUSTOMER or a COMPANY by the party object.
 * @param party The party object where the name should be extracted from/
 * @returns Returns the name or an empty string if the type is not recognised.
 */
export const getPartyName = (party: PartyParam): string => {
  switch (party.type) {
    case 'COMPANY':
      return party.name ?? '';
    case 'CUSTOMER': {
      const { first_name, infix, last_name } = party;
      if (first_name && last_name) {
        return infix
          ? `${first_name} ${infix} ${last_name}`
          : `${first_name} ${last_name}`;
      }
      return first_name ?? last_name ?? '';
    }
    default:
      return '';
  }
};
