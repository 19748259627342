const citizens = {
  primary: {
    '50': '#ffe9f2',
    '100': '#ffd4e6',
    '150': '#ffd4e6',
    '200': '#ffd4e6',
    '300': '#f680b8',
    '400': '#ee53a1',
    '500': '#e10098',
    '600': '#d30c82',
    '700': '#ca0c7c',
    '750': '#ca0c7c',
    '800': '#bd0b74',
    '850': '#a90a68',
    '900': '#7b084c',
    main: '#e10098',
  },
  secondary: {
    '50': '#eff1f2',
    '100': '#dee2e5',
    '150': '#dee2e5',
    '200': '#c0c7cd',
    '300': '#c0c7cd',
    '400': '#99a1a8',
    '500': '#99a1a8',
    '600': '#45525f',
    '700': '#253746',
    '750': '#1f2e3a',
    '800': '#1c2934',
    '850': '#1c2934',
    '900': '#18242d',
    main: '#99a1a8',
  },
};

export default citizens;
