import React, { ReactNode } from 'react';
import { Text } from '@wegroup/design-system';
import { ComboboxItem, Group, SelectProps } from '@mantine/core';

// Define the props type for the component
interface SearchSelectInputItemProps
  extends Omit<SelectProps['renderOption'], 'option'> {
  option: ComboboxItem & { customElement?: ReactNode; styling?: string };
}

// Correctly implement the functional component
const SearchSelectInputItem: React.FC<SearchSelectInputItemProps> = ({
  option,
  ...props
}) => {
  const customDataTestId = `${props['data-test-id']}_OPTION`;
  return (
    <Group
      flex="1"
      gap="xs"
      data-test-id={
        props['data-test-id'] ? customDataTestId : 'SEARCH-SELECT-INPUT_OPTION'
      }
    >
      {option.customElement ? (
        option.customElement
      ) : (
        <Text
          textDecor={option.styling === 'UNDERLINE' ? 'underline' : undefined}
        >
          {option.label}
        </Text>
      )}
    </Group>
  );
};

export default SearchSelectInputItem;
