import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { merge, cloneDeep } from 'lodash';

// English
import en from './locale/en.json';
// French
import fr from './locale/fr.json';
// Dutch
import nl from './locale/nl.json';
import nlNL from './locale/nl-NL.json';
// German
import de from './locale/de.json';

// Lokalise
// English
import enLokalise from './locale/lokalise/en.json';
// French
import frLokalise from './locale/lokalise/fr.json';
// Dutch
import nlLokalise from './locale/lokalise/nl.json';
import nlNLLokalise from './locale/lokalise/nl_NL.json';
// German
import deLokalise from './locale/lokalise/de.json';
import { getIsAuthorized } from './packages/app/src/features/authentication/hooks/useIsAuthorized';
import { getUserInfoQKey } from './packages/app/src/features/authentication/api/get/getUserInfo';
import { queryClient } from './packages/app/src/providers/queryClient';
import { supportedCountriesWithoutLocale } from './locale/supportedCountriesWithoutLocale';

const resources = {
  // English
  en: { translation: merge(en, enLokalise) },
  // French
  'fr-BE': { translation: merge(fr, frLokalise) },
  'fr-FR': { translation: merge(fr, frLokalise) },
  // Dutch
  'nl-BE': { translation: merge(nl, nlLokalise) },
  'nl-NL': { translation: merge(merge(cloneDeep(nl), nlNL), nlNLLokalise) },
  // German
  de: { translation: merge(de, deLokalise) },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    cleanCode: true, // language will be lowercased EN --> en while leaving full locales like en-US
    fallbackLng: 'en',
    initImmediate: false,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format) {
        if (format === 'uppercase') return value?.toUpperCase();
        return value;
      },
    },
    parseMissingKeyHandler: (key) => {
      if (process.env.NODE_ENV === 'development') {
        console.warn(
          `React-i18next key "${key}" not found in translation files.`,
        );
      }
      return key;
    },
  });

/**
 * This function will try to connect the right country to the language change detected
 * so doing changeLanguage('nl') will be converted to 'nl-NL' if current user's country is NL.
 * If no country is found -> country will default to 'BE'
 */

i18n.on('languageChanged', (newLang) => {
  // Means new language implementation (with country) is correct, skip language handling
  if (newLang.includes('-')) return;
  i18n.changeLanguage(`${newLang.toLowerCase()}-BE`);
});

/**
 * Returns the user's language without country code. nl-BE => nl
 * @returns {string} User's language without country
 */
export const getI18nLanguageCode = () => {
  const fallBack = 'en';

  if (i18n.language) {
    return i18n.language.split('-')[0] || fallBack;
  }

  return fallBack;
};

let userCountryCode = 'BE'; //Default BE

/**
 * Returns the user's country code without language. nl-BE => BE
 * @returns {string} User's country without language
 */
export const getI18nCountryCode = () => userCountryCode;

/**
 * !-- **Don't use in social login, will lead to an infinite loop** --!
 * Changes user's language and automtically appends their respective country
 * @param {string} languageCode Language code such as 'nl' or 'fr'
 */

export const setI18nLangWithCountryCode = (newLang) => {
  const generateOutput = (newLang, country = '') =>
    country
      ? `${newLang.toLowerCase()}-${country?.toUpperCase() || ''}`
      : newLang.toLowerCase();

  let country;
  if (getIsAuthorized()) {
    const data = queryClient.getQueryData(getUserInfoQKey());
    country = data?.country;
  }

  // Sets global country code for user
  if (country) userCountryCode = country;

  // Generate the language output, some countries have no separate locale, so they are defaulted
  // to another locale
  let languageOutput = generateOutput(newLang, country);
  if (!resources[languageOutput])
    languageOutput = generateOutput(
      newLang,
      supportedCountriesWithoutLocale[country],
    );
  // Default to Belgium if country is null or lang-country combo was not found in resources
  if (!country || !resources[languageOutput]) country = 'BE';
  i18n.changeLanguage(languageOutput);
};

export const setCountry = (country) => {
  if (country) userCountryCode = country;
};

/**
 * Finds the right value in a response depending on the user's current language
 * @param {object | string | undefined | null} response 
 * * `object` An object response that has different values with their respesctive language as property name 
 * * `string` If a string is passed, it will just be returned as a string instead
 * @example <caption>Example usage - assuming user's current language is set to English</caption>
 * 
 * 
 * const responseExampleFromBackend = {
    "en": "Welcome, user!",
    "nl": "Welkom, gebruiker!",
    "fr": "Bienvenu, utilisateur!"
};
 *
 * handleLanguageResponse(responseExampleFromBackend) // returns "Welcome, user!"
 * @returns {any} The found value for user's current language
 */

export const handleResponseLanguage = (response, customLang = undefined) => {
  switch (typeof response) {
    case 'null':
    case 'undefined':
      return null;

    case 'string':
      return response; // If reponse is string, return it as a string (no parsing necessary)

    case 'object': {
      const matchedLang =
        customLang || i18n.languages.find((lang) => response?.[lang]);

      if (response?.[matchedLang || 'en'] != null)
        return response?.[matchedLang || 'en'];
      return null;
    }

    default:
      return null;
  }
};

export const useLocale = () => ({
  userLang: getI18nLanguageCode(),
  userCountry: getI18nCountryCode(),
});

export default i18n;
