import store from '../store/redux/store';
import { FlowsQuestionAnswer } from '../types/FlowsTypes';
import { isIndexEqual } from '../utils/flowsQuestionsUtils';

type Response = (
  questionId: string,
  index?: number,
) => FlowsQuestionAnswer | undefined;

const useAnswerForQuestion = (): Response => {
  /**
   * Will give the answer object for the given question if it is found
   * @param questionId Question id to look for
   * @param index Index should ALWAYS be passed to support duplicate questions
   * @returns The answer for the given question. Otherwise it reteurns undefined
   */
  const getAnswerForQuestion = (questionId: string, index?: number) => {
    return store
      .getState()
      .flows.answers.find(
        ({ id, index: answerIndex }) =>
          id === questionId && isIndexEqual(index, answerIndex),
      );
  };
  return getAnswerForQuestion;
};

export default useAnswerForQuestion;
