import React, { ReactElement } from 'react';
import {
  Box,
  Tooltip,
  Icon,
  Text,
  Circle,
  Card,
  HStack,
} from '@wegroup/design-system';

interface Props {
  label: string;
  icon?: ReactElement;
  value?: boolean | null;
  tooltip?: string;
}

const FeatureBoxWithIcon: React.FC<Props> = ({
  value,
  label,
  icon,
  tooltip,
}) => {
  return (
    <Tooltip label={tooltip} isDisabled={!tooltip} shouldWrapChildren>
      <Card boxShadow="none">
        <HStack spacing="4">
          <Circle bg="primary.50" color="primary.500" p="1">
            {icon}
          </Circle>
          <Text flexGrow="1">
            {label}{' '}
            {tooltip && (
              <Box
                display="inline-block"
                sx={{
                  '@media print': {
                    display: 'none',
                  },
                }}
              >
                <Icon
                  name="GeneralStatusInfoOutline"
                  color="gray.500"
                  boxSize="3.5"
                />
              </Box>
            )}
          </Text>
          <FeatureStatus value={value} />
        </HStack>
      </Card>
    </Tooltip>
  );
};

const FeatureStatus: React.FC<{ value?: boolean | null }> = ({ value }) => {
  if (value == null) {
    return <Text>-</Text>;
  }
  if (value) {
    return (
      <Circle bg="success.light" p="0.5">
        <Icon name="IconStatusCheck" color="success.main" />
      </Circle>
    );
  }
  return (
    <Circle bg="danger.light" p="0.5">
      <Icon name="IconActionClose" color="danger.main" />
    </Circle>
  );
};

export default FeatureBoxWithIcon;
