import {
  RichTextEditorProps,
  RichTextEditor as TiptapRichtTextEditor,
  Link,
} from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
import TipTapImage from '@tiptap/extension-image';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { merge } from 'lodash';
import React, { useEffect } from 'react';
import { useDefaultRichTextEditorStyling } from '../useDefaultRichTextEditorStyling';
import { useTheme } from '@wegroup/design-system';
import RichTextEditorImageControl from './RichTextEditorImageControl';
import { Color } from '@tiptap/extension-color';
import useHandlePaste from './handlePaste';
import '@mantine/tiptap/styles.css';

interface Controls {
  image?: boolean;
  colors?: boolean;
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  extraControls?: Controls;
  extraStyling?: Partial<Record<string, string | object>>;
}

// We use `any` here because ReactQuill is not exposed by Mantine
const RichTextEditor = React.forwardRef<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  Props & Partial<Omit<RichTextEditorProps, 'onChange'>>
>(
  (
    {
      value,
      onChange,
      isDisabled,
      extraControls = {
        image: false,
      },
      extraStyling,
      ...otherProps
    },
    ref,
  ) => {
    const { space, colors } = useTheme();
    const handlePaste = useHandlePaste();
    const editor = useEditor({
      extensions: [
        StarterKit,
        Underline,
        Link,
        TipTapImage.configure({ inline: true }),
        TextStyle,
        Superscript,
        SubScript,
        Highlight,
        Color,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
      ],
      content: value,
      onUpdate: ({ editor }) => onChange(editor.getHTML()),
      editorProps: {
        handlePaste: handlePaste,
      },
    });

    useEffect(() => {
      if (editor && value !== editor?.getHTML()) {
        editor.commands.setContent(value);
      }
    }, [value, editor]);

    const defaultStyling = useDefaultRichTextEditorStyling();

    const onAddImage = (url: string) => {
      editor?.chain().focus().setImage({ src: url }).run();
    };

    const styles = merge(
      defaultStyling,
      {
        root: {
          // cursor
          ...(isDisabled && {
            opacity: '0.4',
            cursor: 'not-allowed',
          }),
        },
        control: {
          _active: {
            backgroundColor: colors.primary[50],
            color: colors.primary[500],
            borderColor: colors.primary[200],
            ':hover:hover': {
              backgroundColor: colors.primary[100],
            },
          },
        },
        content: {
          minHeight: space[28],
          overflow: 'auto',
        },
        typographyStylesProvider: {
          a: {
            color: colors.primary[500],
            ':hover': {
              textDecoration: 'underline',
            },
          },
        },
      },
      extraStyling,
    );

    return (
      <>
        <TiptapRichtTextEditor editor={editor} styles={styles} {...otherProps}>
          <TiptapRichtTextEditor.Toolbar sticky top="0" stickyOffset={60}>
            {extraControls.colors && (
              <TiptapRichtTextEditor.ControlsGroup>
                <TiptapRichtTextEditor.ColorPicker
                  popoverProps={{
                    withinPortal: true,
                    zIndex: 9999,
                    withArrow: true,
                  }}
                  colors={[
                    '#25262b',
                    '#868e96',
                    '#fa5252',
                    '#e64980',
                    '#be4bdb',
                    '#7950f2',
                    '#4c6ef5',
                    '#228be6',
                    '#15aabf',
                    '#12b886',
                    '#40c057',
                    '#82c91e',
                    '#fab005',
                    '#fd7e14',
                  ]}
                />
              </TiptapRichtTextEditor.ControlsGroup>
            )}
            <TiptapRichtTextEditor.ControlsGroup>
              <TiptapRichtTextEditor.Bold />
              <TiptapRichtTextEditor.Italic />
              <TiptapRichtTextEditor.Underline />
              <TiptapRichtTextEditor.Strikethrough />
              <TiptapRichtTextEditor.ClearFormatting />
            </TiptapRichtTextEditor.ControlsGroup>

            <TiptapRichtTextEditor.ControlsGroup>
              <TiptapRichtTextEditor.H1 />
              <TiptapRichtTextEditor.H2 />
              <TiptapRichtTextEditor.H3 />
              <TiptapRichtTextEditor.H4 />
            </TiptapRichtTextEditor.ControlsGroup>

            <TiptapRichtTextEditor.ControlsGroup>
              <TiptapRichtTextEditor.BulletList />
              <TiptapRichtTextEditor.OrderedList />
            </TiptapRichtTextEditor.ControlsGroup>

            <TiptapRichtTextEditor.ControlsGroup>
              <TiptapRichtTextEditor.Link />
            </TiptapRichtTextEditor.ControlsGroup>

            <TiptapRichtTextEditor.ControlsGroup>
              <TiptapRichtTextEditor.AlignLeft />
              <TiptapRichtTextEditor.AlignCenter />
              <TiptapRichtTextEditor.AlignRight />
            </TiptapRichtTextEditor.ControlsGroup>
            {extraControls.image && (
              <TiptapRichtTextEditor.ControlsGroup>
                <RichTextEditorImageControl onAddImage={onAddImage} />
              </TiptapRichtTextEditor.ControlsGroup>
            )}
          </TiptapRichtTextEditor.Toolbar>

          <TiptapRichtTextEditor.Content />
        </TiptapRichtTextEditor>
        {/* <MantineRichTextEditor
          ref={ref}
          controls={defaultControls}
          value={value}
          onChange={handleOnChange}
          styles={merge(
            defaultStyling,
            {
              root: {
                // cursor
                ...(isDisabled && {
                  opacity: '0.4',
                  cursor: 'not-allowed',
                }),
                '.ql-toolbar': {
                  ...(isDisabled && {
                    pointerEvents: 'none',
                  }),
                },
                '.ql-tooltip': {
                  top: '0 !important',
                  left: '0 !important',
                },
                '.ql-editor': {
                  minHeight: space[28],
                  overflow: 'auto',
                  ...(isDisabled && {
                    pointerEvents: 'none',
                  }),
                },
              },
            },
            extraStyling,
          )}
          onImageUpload={handleImageUpload}
          {...otherProps}
        /> */}
      </>
    );
  },
);

export default RichTextEditor;
