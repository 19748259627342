import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqWith } from 'lodash';
import { AskLaterQuestionList } from '../../../components/ui/company-scan/components/ask-later-questions/types';
import { hasSubGroups } from '../../../config/flowsStructureConfig';
import {
  flowIdAskaTravelOffer,
  flowIdCitizinsTravel,
  flowIdShortCheckupCompanyBE,
  flowIdShortCheckupCompanyNL,
} from '../../../constants/flowIds';
import {
  DuplicateQuestionMapping,
  FlowsPrefillAction,
  FlowsQuestion,
  FlowsQuestionAnswer,
  FlowsQuestionnaire,
  FlowsResponse,
  SubViewHistory,
} from '../../../types/FlowsTypes';
import {
  buildDuplicateQuestionMapping,
  convertToSubgroupedQuestionnaire,
  drawDuplicateQuestionMapping,
  filterForConditions,
  removeHiddenFromQuestionnaire,
} from '../../../utils/flowsQuestionnaireUtils';
import {
  flattenQuestionaire,
  getGroupAndQuestionIndex,
  getGroupAndQuestionIndexByQuestionId,
  getIndexOfAnswerForQuestionId,
  isIndexEqual,
} from '../../../utils/flowsQuestionsUtils';
import {
  AddUrlsAction,
  ApiStatus,
  FlowInitAction,
  ReplaceAnswerIndicesAction,
  ReplaceAnswersAction,
  SubViewPopAction,
  SubViewPushAction,
  SubViewResetAction,
} from '../types/FlowsSliceTypings';

export interface FlowsController {
  /** Contains the initial response with all the data */
  flowsResponse?: FlowsResponse;
  /** Can be used to trigger a loading state for flows*/
  isLoading: boolean;
  /* This questionnaire is used as a reference to filter the questions. It is the original questionnaire that was received from flows back-end. */
  initialQuestionnaire: FlowsQuestionnaire;
  /** This questionnaire is used for showing the actual questions. It changes very frequently troughout the flow.  */
  questionnaire: FlowsQuestionnaire;
  /** Contains all the answers for the flow */
  answers: FlowsQuestionAnswer[];

  /** TEMPORARY */
  questions: FlowsQuestion[];

  formState: {
    /** A general error message */
    validationErrorMessage?: string;
    /** A general toast error message */
    toastErrorMessage?: string;
    /** A state that blocks the user from submitting */
    canSubmit: boolean;
  };

  /** In view only mode every input is disabled */
  isViewOnlyModeOn: boolean;
  /** This shows only al the marked questions  */
  isPreviewModeOn: boolean;
  /** Questions shown in preview mode */
  askLaterQuestionList: AskLaterQuestionList;

  /** Extra data needed troughout the flow  */
  metaData: {
    /** FlowId of the current flow */
    flowId: string;
    /** URL that the user started the flow from */
    inputUrl?: string;
    /** URL that the user should be pushed to once the flow is finished */
    outputUrl: string;
    /** Tags that have been visited at least once */
    visitedTags: string[];
    /** State used to track the duplicate group keys  */
    duplicateQuestionMapping?: DuplicateQuestionMapping;
    /** The latest API call state */
    apiStatus: ApiStatus;
    /** If the complete call should be done */
    shouldComplete?: boolean;
  };
}

const applyTranformations = (
  flowId: string,
  questionnaire: FlowsQuestionnaire,
) => {
  let nextQuestionnaire = { ...questionnaire };
  // List all possible options that apply tranformations to the questionnaire here
  if (hasSubGroups(flowId))
    nextQuestionnaire = convertToSubgroupedQuestionnaire(nextQuestionnaire);
  else {
    nextQuestionnaire = removeHiddenFromQuestionnaire(nextQuestionnaire);
  }

  return nextQuestionnaire;
};

/**
 * Removes questions for which the index is greater than or equal to 0.
 * Returns the adjusted questionnaire and the answers
 */
const removeDuplicateQuestions = (
  questionnaire: FlowsQuestionnaire,
): [FlowsQuestionnaire, FlowsQuestionAnswer[]] => {
  const answers: FlowsQuestionAnswer[] = [];

  const questionCondition = (question: FlowsQuestion) =>
    question.index != null && question.index > -1;

  questionnaire.groups.forEach((group) =>
    group.questions.forEach((question) => {
      if (questionCondition(question))
        answers.push({
          id: question.id,
          groupId: group.id,
          tag: group.tag?.toLowerCase(),
          answer: question.value,
          index: question.index,
        });
    }),
  );

  const adjustedQuestionnaire = {
    ...questionnaire,
    groups: questionnaire.groups.map((group) => ({
      ...group,
      questions: group.questions.filter(
        (question) => !questionCondition(question),
      ),
    })),
  };

  return [adjustedQuestionnaire, answers];
};

const initialState: FlowsController = {
  isLoading: false,
  initialQuestionnaire: { groups: [] },
  questionnaire: { groups: [] },
  answers: [],
  questions: [],
  formState: {
    canSubmit: true,
  },
  isViewOnlyModeOn: false,
  isPreviewModeOn: false,
  askLaterQuestionList: { questions: [] },
  metaData: {
    flowId: '',
    inputUrl: '',
    outputUrl: '',
    visitedTags: [],
    apiStatus: 'UNKNOWN',
    shouldComplete: true,
  },
};

const flowsSlice = createSlice({
  name: 'flowsSlice',
  initialState: initialState,
  reducers: {
    setFlowsResponse(state, action: PayloadAction<FlowsResponse>) {
      state.flowsResponse = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    initFlowsSession(state, action: PayloadAction<FlowInitAction>) {
      const { response, inputUrl, outputUrl, flowId, shouldComplete } =
        action.payload;
      const { questionnaire, answers: initialAnswers } = response;

      const questions = flattenQuestionaire(questionnaire);
      state.questions = questions;

      // Set the initial answers that were returned from be
      state.answers = initialAnswers.map((answer) => ({
        ...answer,
        groupId: answer.group_id,
        prefilled: true,
      }));

      // Remove answers that are non existant
      const questionIds = questions.map(({ id }) => id);
      state.answers = state.answers.filter(({ id }) =>
        questionIds.includes(id),
      );

      // Remove duplicate questions
      const [adjustedQuestionnaire, answers] =
        removeDuplicateQuestions(questionnaire);
      state.initialQuestionnaire = adjustedQuestionnaire;
      state.answers = uniqWith(
        [...state.answers, ...answers],
        (a, b) => a.id === b.id && a.index === b.index,
      );

      // Build a duplicate question mapping
      state.metaData.duplicateQuestionMapping = buildDuplicateQuestionMapping(
        adjustedQuestionnaire,
      );

      // Immediatly filter for conditions
      state.questionnaire = filterForConditions(
        state.initialQuestionnaire,
        state.answers,
        state.questions,
      );

      state.questionnaire = applyTranformations(flowId, adjustedQuestionnaire);

      state.metaData.inputUrl = inputUrl;
      state.metaData.outputUrl = outputUrl;
      state.metaData.flowId = flowId;
      state.metaData.shouldComplete = shouldComplete;
    },

    /**
     * This can be used to run a check for the questionnaire so that
     * the right questions are still asked
     */
    checkQuestionnaire(state) {
      // Do initial render
      let questionnaire = { ...state.initialQuestionnaire };

      /** When a flow uses the TABLE component, the duplicate questions shouldn't be shown in the flow (but in a table) */
      const noDuplicatedQuestionFlows = [
        flowIdShortCheckupCompanyNL,
        flowIdShortCheckupCompanyBE,
        flowIdAskaTravelOffer,
        flowIdCitizinsTravel,
      ];

      // If there is a duplicate question mapping also draw
      // the duplicate questions.
      if (state.metaData.duplicateQuestionMapping) {
        questionnaire = drawDuplicateQuestionMapping(
          state.metaData.duplicateQuestionMapping,
          questionnaire,
          state.answers,
          state.questions,
          !hasSubGroups(state.metaData.flowId) &&
            !noDuplicatedQuestionFlows.includes(state.metaData.flowId),
        );
      }

      // Duplicate questions were added now so do another filter
      questionnaire = filterForConditions(
        questionnaire,
        state.answers,
        state.questions,
      );

      // Filter for preview mode
      if (state.isPreviewModeOn)
        questionnaire = {
          groups: questionnaire.groups
            .map((group) => ({
              ...group,
              questions: group.questions.filter(({ id }) =>
                state.askLaterQuestionList.questions
                  .map(({ question_id }) => question_id)
                  .includes(id),
              ),
            }))
            .filter(({ questions }) => questions.length > 0),
        };

      // Apply transformations
      state.questionnaire = applyTranformations(
        state.metaData.flowId,
        questionnaire,
      );
    },

    /** Action that updates the initial questionnaire to prefill an answer while in a flow */
    prefillAnswer(state, action: PayloadAction<FlowsPrefillAction>) {
      const { groupId, id, answer, onlyInitialQuestionnaire } = action.payload;

      // INITIAL QUESTIONNAIRE
      const [initialGroupIndex, initialQuestionIndex] = groupId
        ? getGroupAndQuestionIndex(state.initialQuestionnaire, groupId, id)
        : getGroupAndQuestionIndexByQuestionId(state.initialQuestionnaire, id);

      if (initialGroupIndex > -1 && initialQuestionIndex > -1) {
        state.initialQuestionnaire.groups[initialGroupIndex].questions[
          initialQuestionIndex
        ].value = answer;
      }

      if (!onlyInitialQuestionnaire) {
        // QUESTIONNAIRE
        const [groupIndex, questionIndex] = groupId
          ? getGroupAndQuestionIndex(state.questionnaire, groupId, id)
          : getGroupAndQuestionIndexByQuestionId(state.questionnaire, id);

        if (groupIndex > -1 && questionIndex > -1) {
          state.questionnaire.groups[groupIndex].questions[
            questionIndex
          ].value = answer;
        }
      }
    },

    /** Update one or more answers in the state */
    updateAnswers(
      state,
      action: PayloadAction<FlowsQuestionAnswer | FlowsQuestionAnswer[]>,
    ) {
      let answers;
      if (!Array.isArray(action.payload)) {
        answers = [action.payload];
      } else {
        answers = action.payload;
      }

      answers.forEach((answer) => {
        const { id, index } = answer;

        const answerIndex = getIndexOfAnswerForQuestionId(
          state.answers,
          id,
          index,
        );

        // Check if answer should be modified or added
        if (answerIndex > -1) {
          state.answers[answerIndex] = {
            ...answer,
            subViewHistory: state.answers[answerIndex].subViewHistory,
          };
        } else {
          state.answers.push(answer);
        }
      });
    },

    /** Mark a tag as visited for the current flow */
    addVisitedTag(state, action: PayloadAction<string>) {
      if (!state.metaData.visitedTags.includes(action.payload))
        state.metaData.visitedTags.push(action.payload);
    },

    setUrls(state, action: PayloadAction<AddUrlsAction>) {
      const { inputUrl, outputUrl } = action.payload;

      state.metaData.inputUrl = inputUrl;
      state.metaData.outputUrl = outputUrl;
    },

    /** Set a global validation error message */
    setValidationErrorMessage(state, action: PayloadAction<string>) {
      state.formState.validationErrorMessage = action.payload;
    },
    /** Set a global toast error message */
    setToastErrorMessage(state, action: PayloadAction<string>) {
      state.formState.toastErrorMessage = action.payload;
    },
    setCanSubmit(state, action: PayloadAction<boolean>) {
      state.formState.canSubmit = action.payload;
    },
    setApiStatus(state, action: PayloadAction<ApiStatus>) {
      state.metaData.apiStatus = action.payload;
    },

    setViewOnlyMode(state, action: PayloadAction<boolean>) {
      state.isViewOnlyModeOn = action.payload;
    },

    setPreviewMode(state, action: PayloadAction<boolean>) {
      state.isPreviewModeOn = action.payload;
    },

    setAskLaterQuestions(state, action: PayloadAction<AskLaterQuestionList>) {
      state.askLaterQuestionList = action.payload;
    },

    setFlowsState(state, action: PayloadAction<FlowsController>) {
      return action.payload;
    },

    resetFlows() {
      return initialState;
    },

    // TODO: Rework subviews
    /** Simulates a router history for a subview component */
    pushSubView(state, action: PayloadAction<SubViewPushAction>) {
      const subViewHistoryInit = {
        previous: [],
        next: [],
      };
      const { id, subView, position, index, stack } = action.payload;
      const answerIndex = getIndexOfAnswerForQuestionId(
        state.answers,
        id,
        index,
      );

      const question = flattenQuestionaire(state.initialQuestionnaire).find(
        ({ id }) => id?.toLowerCase() === action.payload.id?.toLowerCase(),
      );
      if (answerIndex === -1 && question) {
        state.answers.push({
          id,
          answer: undefined,
          subViewHistory: {
            ...subViewHistoryInit,
            [stack]: [subView],
          },
          groupId: state.questionnaire.groups[position].id,
          index,
        });
      } else if (answerIndex === -1) {
        console.error('The given id is not a question');
      } else {
        const selectedState = state.answers[answerIndex];

        if (!selectedState.subViewHistory) {
          selectedState.subViewHistory = subViewHistoryInit;
        }

        if (selectedState.subViewHistory[stack].includes(subView)) {
          return;
        }

        selectedState.subViewHistory[stack].push(subView);
      }
    },
    popSubView(state, action: PayloadAction<SubViewPopAction>) {
      const { id, index, stack } = action.payload;
      const answerIndex = getIndexOfAnswerForQuestionId(
        state.answers,
        id,
        index,
      );
      if (answerIndex >= 0)
        (state.answers[answerIndex].subViewHistory as SubViewHistory)[
          stack
        ]?.pop();
    },
    resetSubView(state, action: PayloadAction<SubViewResetAction>) {
      const { id, index } = action.payload;
      const answerIndex = getIndexOfAnswerForQuestionId(
        state.answers,
        id,
        index,
      );
      if (answerIndex >= 0) {
        (state.answers[answerIndex].subViewHistory as SubViewHistory).next = [];
        (state.answers[answerIndex].subViewHistory as SubViewHistory).previous =
          [];
      }
    },

    // FOR COMPANY-SCAN ONLY
    replaceAnswers(state, action: PayloadAction<ReplaceAnswersAction>) {
      const { answers, index } = action.payload;
      const answerIds = answers.map(({ id }) => id);

      const filteredAnswers = state.answers.filter(
        ({ id, index: answerIndex }) =>
          !(answerIds.includes(id) && isIndexEqual(index, answerIndex)),
      );
      state.answers = [...filteredAnswers, ...answers];
    },
    replaceAnswerIndices(
      state,
      action: PayloadAction<ReplaceAnswerIndicesAction>,
    ) {
      const { groupId, toIndex, forIndex } = action.payload;

      state.answers.forEach(({ index, groupId: answerGroupId }, arrayIndex) => {
        if (
          index === forIndex &&
          answerGroupId?.toLowerCase() === groupId?.toLowerCase()
        ) {
          state.answers[arrayIndex].index = toIndex;
        }
      });
    },
    removeAnswerForIndex(
      state,
      action: PayloadAction<ReplaceAnswerIndicesAction>,
    ) {
      const { groupId, forIndex } = action.payload;

      state.answers = state.answers.filter(
        ({ index, groupId: answerGroupId }) =>
          !(forIndex === index && answerGroupId === groupId),
      );
    },

    reshuffleAnswerIndices(
      state,
      action: PayloadAction<ReplaceAnswerIndicesAction>,
    ) {
      const { groupId, forIndex } = action.payload;

      state.answers.forEach(
        ({ groupId: answerGroupId, index: answerIndex }, index) => {
          if (
            answerGroupId === groupId &&
            answerIndex != null &&
            answerIndex > forIndex
          ) {
            state.answers[index].index = answerIndex - 1;
          }
        },
      );
    },
    setFlowId(state, action: PayloadAction<string>) {
      state.metaData.flowId = action.payload;
    },
  },
});

export const {
  setFlowsResponse,
  setIsLoading,
  initFlowsSession,
  checkQuestionnaire,
  prefillAnswer,
  updateAnswers,
  addVisitedTag,
  setUrls,
  setValidationErrorMessage,
  setToastErrorMessage,
  setCanSubmit,
  setFlowsState,
  resetFlows,
  setApiStatus,
  setFlowId,
  setViewOnlyMode,
  setPreviewMode,
  setAskLaterQuestions,
  reshuffleAnswerIndices,

  // COMPANY SCAN
  replaceAnswers,
  replaceAnswerIndices,
  removeAnswerForIndex,

  // TODO: Refactor subview logic
  pushSubView,
  popSubView,
  resetSubView,
} = flowsSlice.actions;

export default flowsSlice.reducer;
