import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { wgApi } from '../../../../../../app/src/features/api/wgApi';
import { ConversationDetail } from '../../../overview/types/DetailTypes';

export const getUseConversationQKey = (conversationId: string): string[] => [
  'conversations',
  'detail',
  conversationId,
];

export const getConversationById = async (
  conversationId: string,
): Promise<ConversationDetail> => {
  const path = `v1/api/conversations/${conversationId}`;
  const { data, isError } = await wgApi.get<ConversationDetail>(path);
  if (isError) throw new Error();
  return data;
};

interface UseConversationProps {
  enabled?: boolean;
  refetchOnMount?: boolean;
}

export const useConversation = (
  conversationId?: string,
  props?: UseConversationProps,
): UseQueryResult<ConversationDetail> => {
  let enabled = true;
  if (props?.enabled != null) enabled = props?.enabled;

  return useQuery<ConversationDetail>(
    getUseConversationQKey(conversationId!),
    () => getConversationById(conversationId!),
    {
      refetchOnMount: props?.refetchOnMount,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      enabled: Boolean(conversationId && enabled),
    },
  );
};

export const usePrefetchConversation = () => {
  const queryClient = useQueryClient();

  return async (conversationId: string) => {
    await Promise.all([
      await queryClient.prefetchQuery(
        getUseConversationQKey(conversationId),
        () => getConversationById(conversationId),
        {
          // Valid for 5 minutes
          staleTime: 60 * 1000 * 5,
        },
      ),
    ]);
  };
};
