const Select = {
  variants: {
    outline: {
      field: {
        color: 'none',
        fontWeight: '400',
        bgColor: 'white',
        background: 'white',
        borderColor: 'gray.100',
        _placeholder: { color: 'gray.200' },
        _focus: {
          borderColor: 'primary.500',
        },
        _invalid: {
          border: '1px solid danger.main',
          boxShadow: 'none',
        },
      },
    },
    unstyled: {
      field: {
        px: '2',
      },
    },
  },
};

export default Select;
