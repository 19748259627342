import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { differenceInDays, parse } from 'date-fns';
import { uniq } from 'lodash';
import { AUDIENCE_TYPE, Campaign } from '../../../../../../types/Campaigns';
import {
  CampaignAdvisoryFormValues,
  CampaignConfigurationFormValues,
  CampaignFinalizationFormValues,
  CampaignPersonalisationFormValues,
  CampaignRiskAnalysisFormValues,
  CampaignTargetAudienceFormValues,
  Template,
} from '../../../types/campaignTypes';

export interface CampaignSettings {
  audience: 'BROAD' | 'KNOWN';
  productType: 'ADVISORY' | 'RISK_ANALYSIS';
  personalisation: boolean;
}

export interface CampaignsController {
  // CONVERT THESE TO OBJECTS WITH REQUIRED INFORMATION
  template?: Template;
  targetAudience: CampaignTargetAudienceFormValues;
  products: {
    advisory: CampaignAdvisoryFormValues;
    risk_analysis: CampaignRiskAnalysisFormValues;
  };
  personalisation: CampaignPersonalisationFormValues;
  configuration: CampaignConfigurationFormValues;
  finalization: CampaignFinalizationFormValues;
}

const initialState: CampaignsController = {
  targetAudience: {},
  products: {
    advisory: {},
    risk_analysis: {},
  },
  personalisation: {},
  configuration: {},
  finalization: {
    type: 'LINK',
  },
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: initialState,
  reducers: {
    /** Used to load an existing campaign into the redux state */
    setCampaignData(state, action: PayloadAction<Campaign>) {
      const {
        targets,
        risk_analysis_subjects,
        insurances,
        assistant_avatar,
        assistant_name,
        logo,
        name,
        theme,
        assigned_to,
        end_date,
        language,
        notifications,
        send_reminders,
        display_name,
        google_analytics_tracking_id,
        template,
        specifications,
        show_offers,
        dns_prefix,
        lead_provider_id,
        flat_commission_discount,
      } = action.payload;

      // set template
      state.template = template;

      // Set target audience
      if (template?.audience === AUDIENCE_TYPE.KNOWN_TARGETS) {
        state.targetAudience.partyIds = targets?.map(
          ({ party_id }) => party_id,
        );
        state.targetAudience.prefilledIds = targets?.map(
          ({ party_id }) => party_id,
        );
      }

      // Set chosen products
      state.products.advisory.showOffers = show_offers;
      state.products.risk_analysis.selectedSubjects = risk_analysis_subjects;

      state.products.advisory.selectedInsuranceTypes = uniq(
        insurances?.map(({ insurance_type }) => insurance_type),
      );
      state.products.advisory.selectedInsuranceCompanies = uniq(
        insurances?.map(({ insurance_company }) => insurance_company),
      );

      // Set personalisation
      state.personalisation = {
        partnerName: display_name,
        partnerTheme: theme,
        assistantName: assistant_name,
        partnerLogoSrc:
          typeof logo === 'string'
            ? {
                filename: 'logo',
                location: logo,
                size: 0,
                public_bucket_link: logo,
                public_url: logo,
                document_id: logo,
              }
            : logo,
        avatarSrc:
          typeof assistant_avatar === 'string'
            ? {
                filename: 'Assistant avatar',
                location: assistant_avatar,
                size: 0,
                public_bucket_link: assistant_avatar,
                public_url: assistant_avatar,
                document_id: assistant_avatar,
              }
            : assistant_avatar,
      };

      // Set configuration
      state.configuration = {
        assignee: assigned_to || '',
        google_analytics_tracking_id: google_analytics_tracking_id || '',
        duration: end_date
          ? differenceInDays(
              parse(end_date as string, 'yyyy-MM-dd', new Date()),
              new Date(),
            ).toString()
          : 'UNLIMITED',
        language,
        notifications: notifications ? notifications.length > 0 : undefined,
        notification_receivers: notifications,
        reminder: send_reminders,
        name,
        car_brands: specifications?.car_brand_filters || [],
        isBrandFilterEnabled: !!specifications?.car_brand_filters,
        vk: (insurances?.[0]?.vk_applied || 0) * 100,
        ck: (flat_commission_discount || 0) * 100,
        dns_prefix,
        lead_provider_id,
      };
    },
    /** A template determines how the campaign builder will look */
    setCampaignTemplate(state, action: PayloadAction<Template>) {
      state.template = action.payload;
    },
    addTargetProspect(
      state,
      action: PayloadAction<CampaignTargetAudienceFormValues>,
    ) {
      if (!action.payload.partyIds) return;

      // add all id's together
      const paryIds = [
        ...(state.targetAudience.partyIds || []),
        ...(action.payload.partyIds || []),
      ];

      // Use set to make sure all id's are unique
      state.targetAudience.partyIds = [...new Set(paryIds)];
    },
    removeTargetProspect(
      state,
      action: PayloadAction<CampaignTargetAudienceFormValues>,
    ) {
      state.targetAudience.partyIds = state.targetAudience.partyIds?.filter(
        (id) => !action.payload.partyIds?.includes(id),
      );
    },
    setSubjects(state, action: PayloadAction<CampaignRiskAnalysisFormValues>) {
      state.products.risk_analysis = action.payload;
    },
    setProductData(state, action: PayloadAction<CampaignAdvisoryFormValues>) {
      state.products.advisory = action.payload;
    },
    setConfiguration(
      state,
      action: PayloadAction<CampaignConfigurationFormValues>,
    ) {
      state.configuration = action.payload;
    },
    setPersonalisationValues(
      state,
      action: PayloadAction<CampaignPersonalisationFormValues>,
    ) {
      state.personalisation = action.payload;
    },
    setFinalization(
      state,
      action: PayloadAction<CampaignFinalizationFormValues>,
    ) {
      state.finalization = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export const {
  setCampaignTemplate,
  setCampaignData,
  addTargetProspect,
  removeTargetProspect,
  setPersonalisationValues,
  setSubjects,
  setProductData,
  setConfiguration,
  setFinalization,
  reset,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;
