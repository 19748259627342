import React, { SVGProps } from 'react';

interface Props {
  color: string;
}

const Plant4: React.FC<
  React.PropsWithChildren<Props & SVGProps<SVGSVGElement>>
> = ({ color, ...rest }) => {
  return (
    <svg
      width="162"
      height="177"
      viewBox="0 0 162 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <line
        x1="1.90471"
        y1="131.733"
        x2="67.8498"
        y2="61.2564"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="14.8637"
        y1="118.229"
        x2="59.6738"
        y2="108.447"
        stroke={color}
        strokeWidth="3"
      />
      <line
        x1="35.3985"
        y1="96.1338"
        x2="113.066"
        y2="63.9535"
        stroke={color}
        strokeWidth="3"
      />
      <circle
        cx="82.2686"
        cy="45.5749"
        r="20.3401"
        transform="rotate(23.713 82.2686 45.5749)"
        fill={color}
        stroke={color}
        strokeWidth="4"
      />
      <circle
        cx="132.215"
        cy="55.5436"
        r="20.3401"
        transform="rotate(23.713 132.215 55.5436)"
        fill={color}
        stroke={color}
        strokeWidth="4"
      />
      <circle
        cx="79.5274"
        cy="104.22"
        r="20.3401"
        transform="rotate(23.713 79.5274 104.22)"
        fill={color}
        stroke={color}
        strokeWidth="4"
      />
    </svg>
  );
};

export default Plant4;
