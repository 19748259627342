import { useTheme, useBreakpointValue } from '@wegroup/design-system';

export const useDefaultRichTextEditorStyling = (): Partial<
  Record<string, string | object>
> => {
  const { fontSizes, colors, radii, space } = useTheme();
  const fontSize =
    useBreakpointValue(
      {
        base: fontSizes.sm,
        laptop: fontSizes.md,
      },
      { ssr: false },
    ) || '16px';

  return {
    root: {
      fontSize: fontSize,
      lineHeight: '120%',
      borderColor: colors.gray[50],
      borderRadius: radii.md,
      fontFamily: 'Circular',
      'h1,h2,h3,h4,h5,h6': {
        lineHeight: '125%',
        fontFamily: 'Circular',
        marginBottom: '2px',
      },
      em: {
        fontStyle: 'italic',
      },
      p: {
        paddingBottom: '4px',
      },
      ul: {
        marginTop: 0,
        paddingLeft: '15px',
        li: {
          listStyleType: 'disc',
        },
      },
      ol: {
        marginTop: 0,
        paddingLeft: '15px',
        li: {
          listStyleType: 'decimal',
        },
      },
      a: {
        color: colors.primary[500],
        ':hover': {
          textDecoration: 'underline',
        },
      },

      '.ql-action:before': {
        backgroundColor: colors.primary[500],
      },
      '.ql-active': {
        backgroundColor: colors.primary[50],
        color: colors.primary[500],
        borderColor: colors.primary[200],
        ':hover': {
          backgroundColor: colors.primary[100],
        },
      },
    },
    control: {
      minWidth: space[6.5],
      height: space[6.5],
    },
    toolbar: {
      borderColor: colors.gray[50],
      padding: `${space[2.5]} ${space[4]}`,
    },
    toolbarControl: { transition: '0.2s' },
  };
};
