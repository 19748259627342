import React from 'react';

interface Props {
  color: string;
}

const Plant2: React.FC<React.PropsWithChildren<Props>> = ({
  color,
  ...rest
}) => {
  return (
    <svg
      width="196"
      height="212"
      viewBox="0 0 196 212"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M189.44 154.804C165.739 143.636 82.5674 92.9918 43.9443 69.0654"
        stroke={color}
        strokeWidth="4"
      />
      <path
        d="M80.621 67.6602C82.1148 56.1761 74.6448 47.4189 70.7231 44.4758C67.6917 47.6374 60.7402 52.1606 60.4081 63.3467C60.1272 72.8076 64.5424 79.0439 69.6574 85.0431C71.8412 83.362 79.1273 79.1443 80.621 67.6602Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M119.355 90.5069C120.849 79.0228 113.379 70.2656 109.457 67.3225C106.426 70.4841 99.4746 75.0073 99.1424 86.1934C98.8615 95.6543 103.277 101.891 108.392 107.89C110.576 106.209 117.862 101.991 119.355 90.5069Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M158.09 113.354C159.584 101.869 152.114 93.1123 148.192 90.1692C145.16 93.3308 138.209 97.854 137.877 109.04C137.596 118.501 142.011 124.737 147.126 130.736C149.31 129.055 156.596 124.838 158.09 113.354Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M59.2944 102.612C49.8708 109.439 38.6443 106.98 34.2089 104.897C35.5735 100.685 36.2985 92.3318 46.0045 86.682C54.2136 81.9036 61.7839 82.8494 69.4736 84.5317C69.0165 87.2806 68.7179 95.7859 59.2944 102.612Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M98.0287 125.459C88.6052 132.286 77.3786 129.827 72.9433 127.744C74.3078 123.532 75.0329 115.178 84.7389 109.529C92.948 104.75 100.518 105.696 108.208 107.378C107.751 110.127 107.452 118.633 98.0287 125.459Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M136.762 148.306C127.339 155.132 116.112 152.674 111.677 150.591C113.041 146.379 113.766 138.025 123.472 132.375C131.681 127.597 139.252 128.543 146.941 130.225C146.484 132.974 146.186 141.479 136.762 148.306Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M34.8575 54.0797C26.3188 48.2618 16.3375 50.6777 12.4142 52.6129C13.7088 56.3001 14.5071 63.6564 23.2767 68.4296C30.6939 72.4666 37.4373 71.466 44.2741 69.8129C43.8163 67.3971 43.3961 59.8977 34.8575 54.0797Z"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
    </svg>
  );
};

export default Plant2;
