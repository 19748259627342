import React from 'react';

interface Props {
  color: string;
}

const Plant1: React.FC<React.PropsWithChildren<Props>> = ({
  color,
  ...rest
}) => {
  return (
    <svg
      width="174"
      height="262"
      viewBox="0 0 174 262"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M164.406 237.741C143.548 211.814 76.7227 107.753 45.917 58.9629"
        stroke={color}
        strokeWidth="4"
      />
      <path
        d="M88.5808 77.7441C96.6697 65.4604 93.0071 51.3083 90.1647 45.7677C84.9457 47.6931 74.4935 48.9944 67.8967 61.5834C62.3173 72.2309 63.8928 81.8064 66.3992 91.5001C69.8274 90.7942 80.4918 90.0278 88.5808 77.7441Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M120.114 125.363C128.203 113.08 124.54 98.9274 121.698 93.3868C116.479 95.3122 106.027 96.6135 99.4299 109.203C93.8505 119.85 95.426 129.426 97.9324 139.119C101.361 138.413 112.025 137.647 120.114 125.363Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M151.646 172.982C159.735 160.699 156.073 146.547 153.23 141.006C148.011 142.931 137.559 144.233 130.962 156.822C125.383 167.469 126.958 177.045 129.465 186.738C132.893 186.032 143.557 185.266 151.646 172.982Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M44.7996 105.803C30.2442 108.361 18.791 99.3128 14.8838 94.4692C18.7831 90.4179 24.2543 81.2816 38.4781 80.2249C50.5083 79.3311 58.6273 84.619 66.4733 90.8142C64.4234 93.6991 59.3549 103.246 44.7996 105.803Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M76.3318 153.422C61.7765 155.98 50.3232 146.932 46.416 142.088C50.3154 138.037 55.7866 128.901 70.0103 127.844C82.0405 126.95 90.1595 132.238 98.0055 138.433C95.9557 141.318 90.8871 150.865 76.3318 153.422Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M107.864 201.041C93.3087 203.599 81.8554 194.551 77.9482 189.708C81.8476 185.656 87.3188 176.52 101.543 175.463C113.573 174.569 121.692 179.857 129.538 186.052C127.488 188.937 122.419 198.484 107.864 201.041Z"
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M43.8708 36.7983C37.354 25.4086 24.6133 22.6195 19.0576 22.6487C18.4865 27.5787 15.3092 36.4227 22.6704 46.7478C28.8964 55.4805 37.153 58.0861 45.879 59.9984C46.6989 56.9853 50.3875 48.1879 43.8708 36.7983Z"
        stroke={color}
        strokeWidth="3"
      />
    </svg>
  );
};

export default Plant1;
