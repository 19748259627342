import { Box, RichText } from '@wegroup/design-system';
import React from 'react';

interface Props {
  message?: string;
}

const LouiseSingleChatMessage: React.FC<Props> = ({ message = '' }) => {
  return (
    <Box
      p="3"
      sx={{
        '@media print': {
          fontSize: 'xs',
          lineHeight: 1.2,
        },
      }}
    >
      <RichText value={message} />
    </Box>
  );
};

export default LouiseSingleChatMessage;
