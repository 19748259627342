import { VStack, Text } from '@wegroup/design-system';
import React, { PropsWithChildren } from 'react';

interface Props {
  title: string;
}

const FinalizeTextBlock: React.FC<PropsWithChildren<Props>> = ({
  title,
  children,
}) => {
  return (
    <VStack spacing="6" align="flex-start" w="100%">
      <Text textStyle="h1Headline">{title}</Text>
      <Text color="text.gray" textStyle="largeBody">
        {children}
      </Text>
    </VStack>
  );
};

export default FinalizeTextBlock;
