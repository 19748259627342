import { RichTextEditor } from '@mantine/tiptap';
import { Input, useBoolean, Spinner } from '@wegroup/design-system';
import { IconPhoto } from '@tabler/icons';
import cogoToast from 'cogo-toast';
import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useUpload from '../../../../../../common/src/hooks/useUpload';

interface Props {
  isDisabled?: boolean;
  onAddImage: (url: string) => void;
}

const RichTextEditorImageControl: React.FC<Props> = ({
  isDisabled,
  onAddImage,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useBoolean();
  const uploadInput = useRef<HTMLInputElement>(null);
  const { uploadFile } = useUpload();

  const handleImageUpload = (file: File): Promise<string> => {
    if (!isDisabled) {
      if (file.size <= 2000000) {
        return new Promise((resolve, reject) => {
          uploadFile(file)
            .then(({ public_url }) => {
              resolve(public_url);
            })
            .catch(() => reject(new Error('Upload failed')));
        });
      }
      cogoToast.warn(t('UPLOAD-MAX-FILE-SIZE'));
      return new Promise((_, reject) => reject(new Error('File to big')));
    }
    return new Promise((_, reject) =>
      reject(new Error('Image upload disabled')),
    );
  };

  const handleUploadInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      setIsLoading.on();
      const url = await handleImageUpload(file);
      setIsLoading.off();
      if (url) {
        onAddImage(url);
      }
    }
  };

  const handleClick = () => {
    if (uploadInput?.current) {
      uploadInput?.current.click();
    }
  };

  return (
    <RichTextEditor.Control
      onClick={handleClick}
      aria-label="Upload file"
      title="Embed image"
    >
      <Input
        ref={uploadInput}
        display="none"
        disabled={isDisabled}
        accept="*"
        type="file"
        onChange={handleUploadInput}
      />
      {isLoading ? (
        <Spinner color="gray.300" size="sm" />
      ) : (
        <IconPhoto stroke={1.5} size={16} />
      )}
    </RichTextEditor.Control>
  );
};

export default RichTextEditorImageControl;
